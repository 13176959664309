import { System } from './../../components/tabs/System';
import { Deck } from './../../components/deck/deck';
import { ObjectReference } from "@xo/services";
import { EventEmitter, Injectable } from "@angular/core";
import { ModalDialogInstanceService } from "@preeco-privacy/ngx-modal-dialog";

export interface ModalWindowDescriptor {
    system?: System;
    deck?: Deck;
    component: string;
    resolve?: (any) => any | Promise<void>;
    reject?: (any) => any | Promise<void>;
    oldId?: any;
    allowEmpty?: boolean;
    allowIsNull?: boolean;
    allowNotNull?: boolean;
    disableCancel?: boolean;
    data?: any;
}

export let modalEmitter: EventEmitter<ModalWindowDescriptor> = new EventEmitter<ModalWindowDescriptor>();

@Injectable()
export class ModalService {

    constructor(private modalInstanceService: ModalDialogInstanceService) { }

    closeAnyExistingModalDialog() {
        this.modalInstanceService.closeAnyExistingModalDialog();
    }

    selectValue(system: System, deck: Deck, selectWindowClass: string, oldId: ObjectReference, allowEmpty: boolean, parent: any = null) {
        return new Promise((resolve, reject) => {
            modalEmitter.emit({
                system,
                deck,
                component: selectWindowClass,
                oldId,
                resolve,
                reject,
                data: { parent },
                allowEmpty
            });
        });
    }
}
