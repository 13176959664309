import { TableComponent } from "./table.component";
import { Directive } from "@angular/core";
import { TabComponent } from '../../tab/tab.component';
import { ColumnInfo } from '@xo/services';

function noneFactory(fieldName: string) {
    return function (result, row) {
        return result;
    };
}

function numberSumFactory(fieldName: string) {
    return function (result, row) {
        let value = row[fieldName];
        return value instanceof Array
            ? result + value.reduce((total, current) => total + current, 0)
            : result + (value || 0);
    };
}

function minFactory(fieldName: string) {
    let first = true;
    return function (result, row) {
        if (first) {
            result = Number.MAX_SAFE_INTEGER;
            first = false;
        }
        if (result > row[fieldName]) {
            return row[fieldName];
        }
        return result;
    };
}

function maxFactory(fieldName: string) {
    return function (result, row) {
        if (result < row[fieldName]) {
            return row[fieldName];
        }
        return result;
    };
}

function numberAverageFactory(fieldName: string) {
    let count = 0;
    let total = 0;
    return function (result, row) {
        count++;
        total += row[fieldName] || 0;
        return total / count;
    };
}

function numberSumSelectedFactory(fieldName: string) {
    return function (result, row) {
        if (!!row["selected"]) {
            return result + (row[fieldName] || 0);
        } else {
            return result + 0;
        }
    };
}

let operations = {
    "booleanNone": noneFactory,
    "booleanSelectNone": noneFactory,
    "stringNone": noneFactory,
    "stringLongNone": noneFactory,
    "stringSelectNone": noneFactory,
    "stringLinkNone": noneFactory,
    "FileNone": noneFactory,
    "DateNone": noneFactory,
    "DateMin": minFactory,
    "DateMax": maxFactory,
    "DateSelectNone": noneFactory,
    "numberNone": noneFactory,
    "numberMin": minFactory,
    "numberMax": maxFactory,
    "numberSum": numberSumFactory,
    "numberAverage": numberAverageFactory,
    "numberSelectNone": noneFactory,
    "numberSumSelected": numberSumSelectedFactory
};

@Directive()
export abstract class SummaryTableComponent<TYPE> extends TableComponent<TYPE> {

    summary: any = {};

    constructor(typeName: string, public summaryInfo: ColumnInfo[] = [], protected tab?: TabComponent) {
        super(typeName, summaryInfo, tab);
    }

    createUpdateFunction() {
        return this.updateSummaryInfo.bind(this);
    }


    async refreshList(addPaging: boolean = true) {
        super.refreshList();
        this.updateSummaryInfo();
    }

    updateSummaryInfo() {
        this.summary = this.getSummaryInfo();
    }

    getSummaryInfo() {
        let result = {};
        this.summaryInfo.forEach((info => {
            result[info.fieldName] = this.compute(info);
        }));
        return result;
    }

    compute(info: ColumnInfo) {
        let operationFactory = operations[info.type + info.operation];
        if (!operationFactory) {
            throw new Error("Invalid operationFactory:" + info.type + " " + info.operation);
        }
        let defaultValue: number | string = info.type === "number" ? 0 : "";
        return this.objects.reduce(operationFactory(info.fieldName), defaultValue);
    }
}
