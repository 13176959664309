import { XOModalDialog } from './../components/tab/XOModalDialog';
import { DragService, getDistance, MINIMUM_LINK_DISTANCE } from './drag.service';
import { GraphState } from './graph.state';
import { modalEmitter } from '../window/modal-window/modal.service';
import { Node, getPrimaryChildType, getSecondaryChildType } from '@xo/services';

export class DragCreateService extends DragService {

    placeHolderNode: any;
    hasTarget: boolean;

    constructor(particleSystem: any, canvas: any, arbor: any, graphState: GraphState) {
        super(particleSystem, canvas, arbor, graphState);
    }

    draw(ctx) {
        if (!this.placeHolderNode) {
            return;
        }
        ctx.fillStyle = this.hasTarget ? "black" : "red";
        ctx.beginPath();
        ctx.ellipse(this.placeHolderNode.x, this.placeHolderNode.y, 5, 5, 0, 0, 2 * Math.PI);
        ctx.fill();
    }

    onDrag(event, pixel: any, mouseP: any) {
        if (event.ctrlKey) {
            this.placeHolderNode = mouseP;
            var nearest = this.particleSystem.nearest(this.placeHolderNode);
            if (getDistance(nearest.node.p, pixel) < MINIMUM_LINK_DISTANCE) {
                this.hasTarget = true;
            } else {
                this.hasTarget = false;
            }
            return true;
        }
        return false;
    }

    async onDrop(event, mouseP, pixel, nearest) {
        if (event.ctrlKey) {
            if (!this.graphState.dragged) {
                return;
            }
            let parent = this.graphState.dragged.node.data.node as Node;
            this.graphState.dragged = null;
            this.placeHolderNode = null;
            if (getDistance(nearest.node.p, pixel) < MINIMUM_LINK_DISTANCE) {
                let source = parent as Node;
                let target = nearest.node.data.node as Node;
                let data: any = {
                    actionButtons: [
                        { text: "Mégsem", onAction: () => { return true; } },
                    ],
                };
                data.actionButtons.push({
                    text: "Mentés", onAction: () => {
                        let linkText = (data.componentInstance as XOModalDialog).createChild();
                        (parent.XO_service.addChild(parent, target, linkText)).then((target: Node) => {
                            if (!target) {
                                return;
                            }
                            this.graphState.linkService.createLink(source.XO_type, source.id, target.XO_type, target.id, linkText);
                            this.graphState.createLink(source, target);
                        });

                        return true;
                    }
                });

                modalEmitter.emit({
                    component: "Common/LinkingComponent",
                    system: this.graphState.tab.system,
                    disableCancel: true,
                    data
                })
                return;
            }
            let data: any = {
                actionButtons: [
                    { text: "Mégsem", onAction: () => { return true; } },
                ],
            };
            if (!event.altKey) {
                data.actionButtons.push({
                    text: "Mentés", onAction: () => {
                        this.addChildToElement(parent,
                            getPrimaryChildType(parent).type, (data.componentInstance as XOModalDialog).createChild());
                        return true;
                    }
                });

                modalEmitter.emit({
                    component: getPrimaryChildType(parent).componentCode,
                    system: this.graphState.tab.system,
                    disableCancel: true,
                    data
                });
            } else {
                data.actionButtons.push({
                    text: "Mentés", onAction: () => {
                        this.addChildToElement(parent,
                            getSecondaryChildType(parent).type, (data.componentInstance as XOModalDialog).createChild());
                        return true;
                    }
                });

                modalEmitter.emit({
                    component: getSecondaryChildType(parent).componentCode,
                    system: this.graphState.tab.system,
                    disableCancel: true,
                    allowEmpty: true, //TODO remove
                    data
                });
            }
        }
    }

    async addChildToElement(parent: Node, type, link) {
        let service = this.graphState.tab.getService(type);
        link.object = await service.save(link.object, false);

        link.object = await parent.XO_service.addChild(parent, link.object, link.linkText);
        if (!link.object) {
            return;
        }
        await this.graphState.addElement(link.object);
        this.graphState.linkService.createLink(parent.XO_type, parent.id, link.linkType, link.object.id, link.linkText);
        this.graphState.createLink(parent, link.object);
    }
}
