import { DevMessage } from './DevMessage';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageUrlService } from './dev-message.url.service';
import { DataService, Transformator } from '@xo/services';

@Injectable()
export class MessageTransformator extends Transformator<DevMessage>{

    constructor() {
        super();
    }

    async transformFromServer(rawMessage: any): Promise<DevMessage> {
        let result: any = Object.assign({}, rawMessage);
        return result;
    }

    transformToServer(message: DevMessage): any {
        let result: any = Object.assign({}, message);
        return result;
    }
}

@Injectable()
export class MessageService extends DataService<DevMessage>{

    constructor(http: HttpClient, MessageUrlService: MessageUrlService, messageTransformator: MessageTransformator) {
        super(http, MessageUrlService, messageTransformator, 'id');
    }

    system: string = 'Kazincbarcika'



    sendMessage(payload) {
        payload.domain = this.getCurrentUrl();
        payload.system = this.system;
        return this.save(payload);
    }

    getCurrentUrl() {
        return window.location.href;
    }


}
