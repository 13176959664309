import { Component, Input, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { ComponentService } from '../../services/component.service';
import { Node } from "@xo/services";

@Component({
    selector: "xo-active-element",
    templateUrl: "./active-element.component.html"
})
export class ActiveElementComponent implements OnInit {

    @Input() activeElement: Node;

    @ViewChild('componentContainer', { read: ViewContainerRef, static: true }) componentContainer;

    constructor(private componentService: ComponentService) {
    }


    ngOnInit(): void {
        this.componentService.createComponent(
            this.componentContainer,
            this.activeElement.componentCode,
            { [this.activeElement["XO_type"]]: this.activeElement }
        )
    }
}
