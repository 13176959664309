import { ColumnInfo } from '@xo/services';
import { Input, Component } from "@angular/core";
import { prettyPrint } from '../../../utils';

@Component({
    selector: "[xo-table-summary]",
    templateUrl: "./table-summary.component.html",
    styleUrls: ["./table-summary.component.scss"]
})
export class TableSummaryComponent {

    @Input() summary: any;
    @Input() summaryInfo: ColumnInfo;

    prettyPrint = prettyPrint;

    constructor() {
    }
}
