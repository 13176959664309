import { Component, Input, AfterViewInit, ViewContainerRef, ViewChild, DoCheck } from "@angular/core";
import { ComponentService } from "../../services/component.service";

@Component({
    selector: "xo-include",
    templateUrl: "./include.component.html",
    styleUrls: ["./include.component.scss"]
})
export class IncludeComponent implements AfterViewInit, DoCheck {

    @Input() id: string | number;
    @Input() component: string;
    @Input() title?: string;
    initialId: string | number;

    @ViewChild("componentContainer", { read: ViewContainerRef }) componentContainer;

    constructor(private componentService: ComponentService) {

    }

    async ngAfterViewInit() {
        this.initialId = this.id;
        this.componentContainer.remove();
        await this.componentService.createComponent(
            this.componentContainer,
            this.component,
            { id: this.id }
        );
    }

    async ngDoCheck() {
        if (!!this.initialId && this.initialId != this.id) {
            await this.ngAfterViewInit();
        }
    }
}
