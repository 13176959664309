import { BaseUrlService } from '@xo/services';
import { ConfigService } from './config.service';

export abstract class SystemUrlService extends BaseUrlService {
  constructor(public system: string, protected moduleName: string, protected typeName: string, protected configService: ConfigService) {
    super();
    this.URL = this.configService.config.modules.find(module => module.system == system && module.module == moduleName).url;
    this.baseURL = `${this.URL}${this.moduleName != "" ? "/" : ""}${this.moduleName}${this.typeName != "" ? "/" : ""}${this.typeName}`;
  }
}
