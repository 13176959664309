import { Injectable } from "@angular/core";
import { openTab } from "../components/tabs/tab.control";
import { System } from '../components/tabs/System';
import { Deck } from '../components/deck/deck';

@Injectable()
export class PageService {
    constructor() {
    }

    createOpenObject(
        componentCode: string,
        system: System,
        deck: Deck,
        labelFactory: (object?: any) => string,
        createNewObject?: () => Object,
        saveObject?: (any) => any): (event, object?: any) => void {
        return function (event, object?: any) {
            if (!object) {
                object = createNewObject();
            }
            openTab.emit({ label: labelFactory(object), component: componentCode, system, deck, data: object, saveObject });
            event.stopPropagation();
        };
    }
}
