import { XOFieldTime } from './../components/fields/xo-field-time.component';
import { FlowScreenComponent } from '../flow-screen/flow-screen.component';
import { IncludeComponent } from '../components/include/include.component';
import { XoTileComponent } from '../components/tile/tile.component';
import { ComponentDisplayer } from '../components/component-displayer/component-displayer.component';
import { ExcelComponent } from '../modules/common/excel/excel.component';
import { MessageComponent } from '../modules/common/dev-message/dev-message.component';
import { BranchComponent } from '../hierarchy/branch/branch.component';
import { ActiveElementComponent } from '../hierarchy/element/active-element.component';
import { GraphComponent } from '../hierarchy/graph/graph.component';
import { LinkingComponent } from '../hierarchy/link/linking.component';
import { NodeComponent } from '../hierarchy/node/node.component';
import { TreeComponent } from '../hierarchy/tree/tree.component';
import { ChangePasswordComponent } from '../modules/common/user/change-password.component';
import { MessagesComponent } from '../validation/messages/messages.component';
import { CreateChildComponent } from '../hierarchy/create-child/create-child.component';
import { LanguageSelectorComponent } from '../components/language-selector/language-selector.component';
import { TableRowComponent } from '../components/table/table-row/table-row.component';
import { TableHeaderComponent } from '../components/table/table-header/table.header.component';
import { TableFilterComponent } from '../components/table/table-filter/table-filter.component';
import { ProfileUser } from '../modules/common/user/profile-user.component';
import { XOFieldLink } from '../components/fields/xo-field-link.component';
import { XOFieldNumber } from '../components/fields/xo-field-number.component';
import { XOFieldFraction } from '../components/fields/xo-field-fraction.component';
import { XOFieldDate } from '../components/fields/xo-field-date.component';
import { XOFieldBoolean } from '../components/fields/xo-field-boolean.component';
import { XOFieldBooleanRadio } from '../components/fields/xo-field-boolean-radio.component';
import { XOFieldSelect } from '../components/fields/xo-field-select.component';
import { XOFieldSelectDate } from '../components/fields/xo-field-select-date.component';
import { XOFieldString } from '../components/fields/xo-field-string.component';
import { XOFieldLongString } from '../components/fields/xo-field-longstring.component';
import { TableFilterSelect } from '../components/table/table-filter-select';
import { TableFilterLink } from '../components/table/table-filter-link';
import { TableFilterInput } from '../components/table/table-filter-input';
import { TableData } from '../components/table/table-data';
import { TableDataLink } from '../components/table/table-data-link';
import { TableDataLinkArray } from '../components/table/table-data-link-array';
import { TableDataCustom } from '../components/table/table-data-custom';
import { TableDataDate } from '../components/table/table-data-date';
import { TableDataString } from '../components/table/table-data-string';
import { XOFieldTimestamp } from '../components/fields/xo-field-timestamp.component';
import { TableDataTimestamp } from '../components/table/table-data-timestamp';
import { SpiderComponent } from '../hierarchy/spider/spider.component';
import { ChartComponent } from "../hierarchy/chart/chart.component";

export let pageComponents = {
  FlowScreenComponent,
  IncludeComponent,
  XoTileComponent,
  ComponentDisplayer,
  ExcelComponent,
  MessageComponent,
  BranchComponent,
  ActiveElementComponent,
  GraphComponent,
  LinkingComponent,
  NodeComponent,
  TreeComponent,
  ChangePasswordComponent,
  MessagesComponent,
  CreateChildComponent,
  LanguageSelectorComponent,
  TableRowComponent,
  TableHeaderComponent,
  TableFilterComponent,
  ProfileUser,
  XOFieldLink,
  XOFieldNumber,
  XOFieldFraction,
  XOFieldDate,
  XOFieldTimestamp,
  XOFieldBoolean,
  XOFieldBooleanRadio,
  XOFieldSelect,
  XOFieldSelectDate,
  XOFieldString,
  XOFieldTime,
  XOFieldLongString,
  TableFilterSelect,
  TableFilterLink,
  TableFilterInput,
  TableData,
  TableDataLink,
  TableDataLinkArray,
  TableDataCustom,
  TableDataDate,
  TableDataString,
  TableDataTimestamp,
  SpiderComponent,
  ChartComponent
}
