import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { AuditLogTable } from "./auditLog.table";
import { ListWindow } from './../../../window/list-windows';
import { TabComponent } from './../../../components/tab/tab.component';
import { PageService } from './../../../services/page.service';

@Component({
  selector: "xo-audit-log-list-window",
  templateUrl: "./auditLog.list.html",
  styleUrls: ["./auditLog.list.scss"]
})
export class AuditLogListWindowComponent extends ListWindow {

  @ViewChild("table", { static: false }) table: AuditLogTable;

  clickHandler: Function;

  constructor(changeDetectorRef: ChangeDetectorRef, private tab: TabComponent, public pageService: PageService) {
    super(changeDetectorRef);
  }

  init(filters?) {
    this.clickHandler = this.pageService.createOpenObject(
      "Common/AuditLogPage",
      this.tab.system,
      this.tab.deck,
      (object) => `AuditLog at ID:${object ? object.id : null}`,
      () => ({})
    );
    super.init(filters, this.table);
  }
}
