import { System } from './../../components/tabs/System';
import { IModalDialog, IModalDialogButton, IModalDialogOptions } from "@preeco-privacy/ngx-modal-dialog";
import { ComponentRef, HostListener, Directive } from "@angular/core";
import { ModalService } from "./modal.service";

const ESCAPE = 27;

@Directive()
export class ModalWindow implements IModalDialog {

  constructor(protected modalService: ModalService) { }

  options: any;
  actionButtons: IModalDialogButton[];

  private system: System;

  dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
    this.options = options;
    this.system = options.data.system;
    this.initActionButtons();
  }

  init() { }

  getService(typeName: string) {
    return this.system.services.get(typeName);
  }

  initActionButtons() {
    this.actionButtons = [];
    if (this.options.data.allowEmpty) {
      this.actionButtons.push({ text: "Ürít", onAction: () => { this.options.data.resolve(null); return true; } });
    }
    if (this.options.data.allowIsNull) {
      this.actionButtons.push({ text: "Üres", onAction: () => { this.options.data.resolve("_NULL_"); return true; } })
    }
    if (this.options.data.allowNotNull) {
      this.actionButtons.push({ text: "Bármi", onAction: () => { this.options.data.resolve("_NOT_NULL_"); return true; } })
    }
    if (!this.options.data.disableCancel) {
      this.actionButtons.push({ text: "Mégsem", onAction: () => { this.options.data.resolve(this.options.data.oldId); return true; } });
    }
  }

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === ESCAPE || event.key === "Escape" || event.code === "Escape") {
      if (!!this.options.reject) {
        this.options.reject();
      }
      this.modalService.closeAnyExistingModalDialog();
      event.stopPropagation();
      event.preventDefault();
    }
  }
}
