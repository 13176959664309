import { IModalDialog, IModalDialogOptions } from "@preeco-privacy/ngx-modal-dialog";
import { ComponentRef } from "@angular/core";
import { ModalWindow } from "../modal-window/modal-window";
import { DataService } from "@xo/services";
import { ModalService } from "../modal-window/modal.service";
import { Directive } from "@angular/core";

@Directive()
export abstract class SelectWindow<ObjectType> extends ModalWindow {

  static counter: number = 0;

  id: string = "SelectWindow" + SelectWindow.counter++;

  public dataService: DataService<ObjectType>

  public list: ObjectType[];

  constructor(modalService: ModalService, private serviceName: string, public labelFieldName: string, public idFieldName = "id") {
    super(modalService);
  }

  dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
    super.dialogInit(reference, options);
    this.dataService = this.getService(this.serviceName);
    this.reload();
  }

  formatId(id) {
    return id;
  }

  formatLabel(label) {
    return label;
  }

  createClickHandler() {
    return async (event, object: any) => {
      if (!!object) {
        let result = {};
        result[this.idFieldName] = this.formatId(object[this.idFieldName]);
        result["label"] = this.formatLabel(object[this.labelFieldName]);
        this.options.data.resolve(result);
        this.options.closeDialogSubject.next();
      }
    };
  }

  reload() {
    this.dataService.list({}, this.id).then((list) => {
      this.list = list.page;
    });
  }

}
