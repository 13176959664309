import { Component } from "@angular/core";
import { AuditLog, AuditLogType } from "./AuditLog";
import { SelectWindow } from './../../../window/select-window/select-window';
import { ModalService } from './../../../window/modal-window/modal.service';

@Component({
    selector: "xo-audit-log-select",
    templateUrl: "./auditLog.select.html",
    styleUrls: ["./auditLog.select.scss"]
})
export class AuditLogSelect extends SelectWindow<AuditLog> {

    constructor(modalService: ModalService) {
        super(modalService, AuditLogType, "timestamp");
    }
}
