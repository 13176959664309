import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../services/config.service';

export class FlowDataService {

    constructor(private http: HttpClient, private url: string, private configService: ConfigService) {

    }

    enchanceFormData(component, formData) {
        let newFormData = new FormData();

        for (var key in formData) {
            if (formData.hasOwnProperty(key) && formData[key]) {
                newFormData.set(key, JSON.stringify(formData[key]));
            }
        }

        if (!!component) {
        }
        if (!!component && !!component["currentFiles1"] && component["currentFiles1"].length > 0) {
            let file = component["currentFiles1"][0];
            newFormData.append("file", file, file.name);
        }
        if (!!component && !!component["currentFiles2"] && component["currentFiles2"].length > 0) {
            let file = component["currentFiles2"][0];
            newFormData.append("file2", file, file.name);
        }

        return newFormData;
    }

    sendAction(action, next?, state?, formData?, component?) {
        return this.http.post(this.url,
            this.enchanceFormData(component, { action, next, state, formData }),
            { withCredentials: true }
        ).toPromise();
    }
}