import { Component, OnInit, ComponentFactoryResolver, ViewContainerRef, ViewChild, AfterViewInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { ComponentService } from '../../services/component.service';

@Component({
    selector: 'xo-component-displayer',
    templateUrl: './component-displayer.component.html',
    styleUrls: ['./component-displayer.component.scss']
})
export class ComponentDisplayer implements OnInit, AfterViewInit {

    component: any;

    filtersAvailabe: Promise<any>;
    paramsAvailabe: Promise<any>;

    @ViewChild('viewRef', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef

    constructor(private route: ActivatedRoute, private componentService: ComponentService,
        private componentFactoryResolver: ComponentFactoryResolver) {
    }

    async ngOnInit() {
        await this.subcribe();
    }
    
    async ngAfterViewInit(){
        await this.initComponent();
    }
    
    async subcribe() {
        this.filtersAvailabe = new Promise((resolve)=>{
            this.route.queryParams.subscribe((queryparam) => {
                resolve(Object.assign({},queryparam));
            });
        })
        this.paramsAvailabe = new Promise((resolve)=>{
            this.route.params.subscribe((params) => {
                if (!!params && !!params.componentType) {
                    resolve(params);
                }
            });
        });
    }
    
    async initComponent() {
        let filters = await this.filtersAvailabe;
        let params = await this.paramsAvailabe;
        this.component = this.componentService.getComponent(`${params.componentModule}/${params.componentType}`);
        console.log('param:', params, '\n filter:', filters, '\n component', this.component);
        this.viewContainerRef.remove(0);
        if (!!this.component) {
            await this.createComponent(filters);
        };
    }

    private async createComponent(filters) {
        const factory = this.componentFactoryResolver.resolveComponentFactory(this.component);
        let ref = this.viewContainerRef.createComponent(factory);
        await this.setFilters(ref, filters);
        ref.changeDetectorRef.detectChanges();
    }

    private async setFilters(ref, filters){
        let instance = ref.instance;
        await instance.init(filters);
    }
}