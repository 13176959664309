import { Element } from "@xo/services";
import { ObjectReference } from "@xo/services";

export class UserReference extends ObjectReference {
  constructor(id?: any, label?: string) {
    super(id, label);
  }
}

export class User extends Element {
  public id: number;
  public name: string;
  public username: string;
  public password?: string;
  public registered: Date;
  public email: string; //TODO remove
  public admin: boolean; //TODO remove
  public registration: Date; //TODO remove

  get label() : string {
    return this.name;
  }
  public classes: string;
}
