import { EventEmitter } from '@angular/core';
import { GraphState } from './graph.state';

export function getDistance(point1, point2) {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + (Math.pow(point2.y - point1.y, 2)));
}

export let activeNodeEmitter = new EventEmitter<any>();

export const MINIMUM_LINK_DISTANCE = 0.2;
export const MINUMUM_OPEN_DISTANCE = 0.1;

export abstract class DragService {

    constructor(protected particleSystem: any, protected canvas: any, protected arbor: any, protected graphState: GraphState) {
    }

    createClickListener() {
        return (event) => {
            let mouseP = this.getMousePositionOnArbor(event);
            var nearest = this.particleSystem.nearest(mouseP);
            var pixel = this.particleSystem.fromScreen(mouseP);
            if (getDistance(nearest.node.p, pixel) < MINIMUM_LINK_DISTANCE) {
                this.graphState.dragged = nearest;
            }
            return false;
        };
    }

    activeNodeListener() {
        let timer;
        let activeElementCandidate: Element;

        function clearTimer() {
            if (!!timer) {
                clearTimeout(timer);
            }
            timer = null;
            activeElementCandidate = null;
        }

        return (event) => {
            let mouseP = this.getMousePositionOnArbor(event);
            var nearest = this.particleSystem.nearest(mouseP);
            var pixel = this.particleSystem.fromScreen(mouseP);
            if (getDistance(nearest.node.p, pixel) < MINIMUM_LINK_DISTANCE) {
                if (!!activeElementCandidate && activeElementCandidate !== nearest.node.data.node) {
                    clearTimer();
                } else {
                    if (!timer) {
                        activeElementCandidate = nearest.node.data.node;
                        timer = setTimeout(() => {
                            this.graphState.activeElement = nearest.node.data.node;
                        }, 500);
                    }
                }
            } else {
                this.graphState.activeElement = null;
                clearTimer();
            }
            return false;

        }
    }


    getMousePositionOnArbor(event) {
        return this.arbor.Point(event.pageX - this.canvas.offsetLeft, event.pageY - this.canvas.offsetTop);
    }

    createDragListener() {
        return (event) => {
            if (this.graphState.dragged === null) {
                return;
            }

            let mouseP = this.getMousePositionOnArbor(event);
            var pixel = this.particleSystem.fromScreen(mouseP);

            if (this.onDrag(event, pixel, mouseP)) {
                event.stopPropagation();
            };

            return false;
        };
    }

    abstract onDrag(event, pixel, mouseP): boolean;

    createDropListener() {
        return (event) => {
            if (this.graphState.dragged === null) {
                return;
            }
            var mouseP = this.getMousePositionOnArbor(event);
            var nearest = this.particleSystem.nearest(mouseP);
            var pixel = this.particleSystem.fromScreen(mouseP);

            if (this.onDrop(event, mouseP, pixel, nearest)) {
                event.stopPropagation();
            };

            return false;
        };
    }

    abstract onDrop(event, mouseP, pixel, nearest);

}
