import { EventEmitter } from "@angular/core";
import { TabComponent } from "../tab/tab.component";

export let openTab: EventEmitter<any> = new EventEmitter<any>();
export let reloadActiveTab: EventEmitter<any> = new EventEmitter<any>();
export let closeTab: EventEmitter<any> = new EventEmitter<any>();
export let tabChange: EventEmitter<any> = new EventEmitter<TabComponent>();


export const REFRESH_TAB = "TAB";
export const REFRESH_BUTTON = "BUTTON";
export const REFRESH_NEVER = "NEVER";

export let configOptions = [];
configOptions[REFRESH_TAB] = REFRESH_TAB;
configOptions[REFRESH_BUTTON] = REFRESH_BUTTON;
configOptions[REFRESH_NEVER] = REFRESH_NEVER;
