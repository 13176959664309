import { ObjectReference } from "@xo/services";
import { openTab } from "../tabs/tab.control";
import { Input, Directive } from '@angular/core';
import { ValidationMessage } from '../../validation/model/ValidationMessage';
import { TabComponent } from '../tab/tab.component';

@Directive()
export class ObjectComponent {

    @Input() validationMessages: ValidationMessage[] = [];

    @Input() validate: Function;

    @Input() exists: boolean;

    constructor(public tab) {
    }


    //TODO depcracated
    getBoolean(input) {
        return input.checked;
    }

    //TODO depcracated
    parseBoolean(input) {
        return input.value === "true";
    }
    //TODO depcracated
    convertStringToDate(convertable: any) {
        return new Date(convertable.value);
    }
    //TODO depcracated
    convertDateToString(convertable) {
        return convertable ? new Date(convertable).toISOString().substr(0, 10) : "";
    }
    //TODO depcracated
    convertTimestamp(date) {
        return date ? new Date(date).toISOString().substr(0, 16).replace("T", " ") : "";
    }
    //TODO depcracated
    convertNumber(convertable: any) {
        return parseInt(convertable.value, 10);
    }
    //TODO depcracated
    convertFraction(convertable: any) {
        return parseFloat(convertable.value);
    }

    preventNotNumber(event: any) {
        return (event.charCode === 8 || event.charCode === 0) ? null : event.charCode >= 48 && event.charCode <= 57;
    }

    openTab(field: ObjectReference, component: any) {
        let tab = { label: field.label, component, system: this.tab.system, deck: this.tab?.tab?.deck, data: field };
        openTab.emit(tab);
    }

    openTabWithDateLabel(field: ObjectReference, component: any) {
        let tab = { label: new Date(field.label).toISOString().substring(0, 10), component, system: this.tab.system, deck: this.tab.deck, data: field };
        openTab.emit(tab);
    }

    compareDate(d1, d2) {
        if (!d1 || !d2) {
            return false;
        }
        return d1.getTime() == d2.getTime();
    }

    async reloadTables() {
    }
}
