import { TabComp } from '../components/tab/TabComp';
import { EventEmitter } from '@angular/core';

export abstract class Window implements TabComp {

    parameters: EventEmitter<any> = new EventEmitter<any>();
    currentData: any;

    init(data: any) {
        this.currentData = data;
        this.updateParams(data);
    }

    reload(data?, addPaging?) {
        this.updateParams(data ? data : this.currentData);
    }

    updateParams(data: any) {
        setTimeout(() => {
            this.parameters.emit(data);
        }, 0);
    }
}
