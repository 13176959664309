import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfigService } from "./services/config.service";

@Component({
  selector: 'xo-common-component',
  templateUrl: './xo-common-component.html',
  styleUrls: [],
})
export class XoCommonComponent implements OnInit {

  public isFuture = false;

  constructor(private configService: ConfigService) { }

  ngOnInit() {
    this.isFuture = !!this.configService.config.theme;
  }
}
