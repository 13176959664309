import { Component, Input, OnInit, DoCheck } from '@angular/core';
import { Key } from 'ts-keycode-enum';
import { TreeState, Element } from '@xo/services';

@Component({
  selector: "xo-branch",
  templateUrl: "./branch.component.html",
  styleUrls: ["./branch.component.scss"]
})
export class BranchComponent implements OnInit, DoCheck {

  @Input() state: TreeState;
  @Input() element: Element;

  elementClasses = new Map<string, string>();
  elementClassKeys = [];

  constructor() {
  }

  setCssClasses() {
    let elementClasses = this.element.XO_service.getClasses(this.element);
    for (let field in elementClasses) {
      this.elementClasses.set(field, elementClasses[field]);
    }
    this.elementClassKeys = Array.from(this.elementClasses.keys());
  }

  async ngOnInit() {
    this.setCssClasses();
  }

  setActiveElement(element) {
    this.state.activeElement = element;
  }

  saveLabel(event) {
    if (this.state.activeElementEditing && event.keyCode == Key.Enter) {
      this.state.activeElementEditing = false;
      this.element.label = this.state.editingLabel;
    }
    if (this.state.activeElementEditing && event.keyCode == Key.Escape) {
      this.state.editingLabel = this.element.label;
      this.state.activeElementEditing = false;
    }
    this.stopPropagation(event);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  switchShowElements(element: Element) {
    element.XO_service.updateChildren(element).then(() => {
      element.XO_ShowChildren = !element.XO_ShowChildren;
      element.XO_service.getChildren(element).forEach((child) => {
        child.XO_service.updateChildren(child);
      });
    });
  }

  ngDoCheck(): void {
    let changes = this.element.XO_differ.diff(this.element);

    if (changes) {
      this.setCssClasses();
      this.element.XO_service.changed(this.element, changes);
    }
  }
}
