import { Injectable, ErrorHandler } from '@angular/core';
import * as Rollbar from 'rollbar';
import { ConfigService } from './config.service';


@Injectable()
export class RollbarService {
  rollbar: Rollbar;

  constructor(private configService: ConfigService) {
    let rollbarConfig = this.configService.config.rollbar;
    if (!!rollbarConfig) {
      this.rollbar = new Rollbar(rollbarConfig);
    }
  }

  error(...args) {
    if (this.rollbar) {
      this.rollbar.error(...args);
    } else {
      console.log(...args);
    }
  }
}

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(private rollbarService: RollbarService) { }

  handleError(err: any): void {
    this.rollbarService.error(err ? err.originalError || err : null);
  }
}
