import { HttpClient } from '@angular/common/http';
import { DataService, CachingDataService } from '@xo/services';
import { ConfigService } from './services/config.service';
import { AuditLogTransformator, CachingAuditLogService } from './modules/common/auditlog/auditLog.service';
import { AuditLogUrlService } from './modules/common/auditlog/auditlog.url.service';
import { AuditLog, AuditLogType } from './modules/common/auditlog/AuditLog';

let systemServices = new Map<string, Map<string, DataService<any>>>();

export function createCommonServiceFactory(configService: ConfigService, http: HttpClient) {
  return function (systemName: string): Map<string, DataService<any>> {
    let serviceMap = systemServices.get(systemName);
    if (!!serviceMap) {
      return serviceMap;
    }

    serviceMap = new Map<string, DataService<any>>();

    let auditLogTransformator = new AuditLogTransformator();
    let auditLogUrlService = new AuditLogUrlService(systemName, configService);
    let auditLogService: CachingDataService<AuditLog> = new CachingAuditLogService(http, auditLogUrlService, auditLogTransformator);
    serviceMap.set(AuditLogType, auditLogService);

    systemServices.set(systemName, serviceMap);
    return serviceMap;
  };
}
