import { CachingDataService } from '@xo/services';
import { NgZone } from '@angular/core';

export abstract class UpdaterService<TYPE> {

    lastTimestamp: Date = new Date();

    constructor(protected dataService: CachingDataService<TYPE>, interval, private ngZone: NgZone) {
        if (!!interval)
            this.ngZone.runOutsideAngular(() => {
                setInterval(this.refresh.bind(this), interval);
            }); {
        }
    }

    async refresh() {
        let nextTimestamp = new Date();
        let changedObjects = await this.dataService.getChangedObjects(this.lastTimestamp) as TYPE[];
        this.lastTimestamp = nextTimestamp;
        this.ngZone.run(() => {
            this.dataService.updateObjects(changedObjects);
        });
        return changedObjects;
    }

}
