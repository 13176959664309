import { Component, Input, ViewChild, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'safeHtml'})
export class Safe {
  constructor(private sanitizer:DomSanitizer){}

  transform(style) {
    return this.sanitizer.bypassSecurityTrustHtml(style);
  }
}

@Component({
    selector: "[xo-node]",
    templateUrl: "./node.component.html"
})
export class NodeComponent {

  @Input() node: any;
  
  oldWidth: number;

  @ViewChild('svgRoot') svgRoot: any;
 
  getWidth(node) {
    return node.svgNode.width;
  }

  ngDoCheck(): void {
    let currentWitdh = this.getWidth(this.node);
    if(this.oldWidth !== currentWitdh) {
      setTimeout(()=>{
        let svg = this.svgRoot.nativeElement;
        let xml = new XMLSerializer().serializeToString(svg);
        this.loadCurrentImage(xml);
        this.oldWidth = currentWitdh;
      },0)
    }
  }
  
  loadCurrentImage(xml: string) {
      var img = new Image();
      img.onload = () => {
        this.node.svgImage = img;
      }
      img.onerror = function(...args) {
          console.log(...args);
      }
  
      let svg64 = btoa(xml);
      let b64Start = 'data:image/svg+xml;base64,';
      let image64 = b64Start + svg64;
      img.src = image64;
  }

}