import { Component, ViewChild, ViewContainerRef, OnInit, ChangeDetectorRef } from "@angular/core";
import { ListWindow, PageService, TabComponent } from "@xo/client-common";
import { GroupTable } from "./group.table";

@Component({
  selector: "xodos-group-list-window",
  templateUrl: "./group.list.html",
  styleUrls: ["./group.list.scss"]
})
export class GroupListWindowComponent extends ListWindow implements OnInit {

  @ViewChild("objectWrapper", { read: ViewContainerRef }) objectWrapper;
  @ViewChild("table") table: GroupTable;

  clickHandler: Function;
  system: string;

  constructor(protected tab: TabComponent, public pageService: PageService, changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  //TODO XO_SYSTEM
  async init(filters: any) {
    /* if (!filters || !filters.system) {
      throw new Error("Please provide system to GroupListWindowComponent component in menu");
    }
    this.system = filters.system

    let groupTransformator = new GroupTransformator(this.system);
    let groupUrlService = new GroupUrlService(this.system, this.configService);
    this.table.dataService = new CachingGroupService(this.http, groupUrlService, groupTransformator);
    this.table.groupDataService = this.table.dataService; */
    super.init(filters, this.table);
  }

  reload() {
    console.log("GroupList reload");
    this.table.refreshList();
  }

  ngOnInit() {
    this.clickHandler = this.pageService.createOpenObject(
      "Xodos/GroupPage",
      this.tab.system,
      this.tab["tab"]?.deck,
      (object) => `Group at ID:${object ? object.id : null}`,
      () => { return { system: this.system }; }
    );
    this.table.refreshList();
  }

}
