import { System } from "../tabs/System";

export class MenuItem {
  public system: System;

  constructor(
    public label: string, //label to display
    public component?: string, //component to open
    public items?: MenuItem[], //child menu items
    public data?: any, //data for component
    public action?: Function, //action on click
    public cssClass?: string, //css class for menu item
    public unique: boolean = false, //only a single component can be open at a time
    public openByDefault: boolean = false //auto open on menu creation
  ) { }
}
