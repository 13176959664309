import { Component, ComponentRef, Input } from "@angular/core";
import { IModalDialog, IModalDialogOptions } from "@preeco-privacy/ngx-modal-dialog";
import { UserService } from "./user.service";
import { ChangePassword } from "./ChangePassword";
import { ModalWindow } from "../../../window/modal-window/modal-window";
import { User } from "./User";
import { ModalService } from "../../../window/modal-window/modal.service";
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: "xo-change-pw",
    templateUrl: "change-password.component.html",
    styleUrls: ["./change-password.component.scss"]
})
export class ChangePasswordComponent extends ModalWindow {

    @Input()
    token?: string;

    options: any;

    user: User;

    oldPassword: string;

    newPassword: string;

    confirmNewPassword: string;

    constructor(private userDataService: UserService,
        modalService: ModalService, private toastr: ToastrService) {
        super(modalService);
    }

    dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
        this.options = options;
        this.options.title = "Jelszó megváltoztatása";
        this.user = this.options.data.data.user;
    }

    changePassword() {
        if (this.newPassword !== this.confirmNewPassword) {
            this.toastr.warning("Eltérő jelszavak!");
            return;
        }
        let changePassword = new ChangePassword();
        changePassword.token = this.token;
        changePassword.userId = this.user && this.user.id;
        changePassword.oldPassword = this.oldPassword;
        changePassword.newPassword = this.newPassword;
        this.userDataService.changePassword(changePassword).then((response) => {
            if (!!this.token) {
                this.toastr.info("Jelszava megváltozott. Kérem, lépjen be!");
                // TODO     this.navigationService.goToLogin();
            } else {
                this.options.closeDialogSubject.next();
            }
        });
    }

    cancel() {
        this.options.closeDialogSubject.next();
    }

}
