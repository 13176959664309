import { Component } from "@angular/core";
import { XOArrayField } from './xo-array-field.component';

@Component({
    selector: "xo-field-array-boolean",
    templateUrl: "./xo-field-array-boolean.component.html"
})
export class XOArrayFieldBoolean extends XOArrayField {
    getBoolean(input) {
        return input.checked;
    }
}
