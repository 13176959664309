import { TokenStore } from './TokenStore';

export class ConfigTokenStore implements TokenStore {

  constructor(private token: string) { }

  async set(token: string) {
  }

  get(): Promise<string> {
    return Promise.resolve(this.token);
  }

  async erase(): Promise<void> {
    //TODO
  }
}
