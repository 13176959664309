import { Component, ComponentRef } from '@angular/core';
import { IModalDialog, IModalDialogOptions } from '@preeco-privacy/ngx-modal-dialog';
import { MessageService } from './dev-message.service';
import { ToastrService } from 'ngx-toastr';
import { ModalWindow } from '../../../window/modal-window/modal-window';
import { ModalService } from '../../../window/modal-window/modal.service';

@Component({
    selector: 'xo-message',
    templateUrl: './dev-message.component.html',
    styleUrls: ['./dev-message.component.scss']
})
export class MessageComponent extends ModalWindow {

    message: string;
    module: string;
    type: string;
    id;
    options: any;

    constructor(private dataService: MessageService, modalService: ModalService,
        private toastr: ToastrService) {
        super(modalService);
    }

    dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
        this.options = options;
        this.id = this.options.data.options.id;
        this.type = this.options.data.options.type;
        this.module = this.options.data.options.module;
    }

    sendMessage() {
        if (!this.message) {
            this.toastr.warning('Nincs üzenet kitöltve!');
            return;
        }
        let payload = {
            system: '',
            module: this.module,
            domain: '',
            type: this.type,
            ID: this.id,
            message: this.message
        }
        this.dataService.sendMessage(payload).then(response => {
            this.options.closeDialogSubject.next();
        });

    }

    cancelMessage() {
        this.options.closeDialogSubject.next();
    }


}
