import { Input, Component, AfterViewInit } from "@angular/core";

declare var CanvasJS;

@Component({
    selector: "xo-chart",
    templateUrl: "./chart.component.html"
})
export class ChartComponent implements AfterViewInit {

    private static lastId = 0;

    @Input() chartData: any;

    @Input() fromDate?: Date;
    @Input() toDate?: Date;

    @Input() min: number = 0;
    @Input() max: number;

    public id: number;
    constructor() {
        this.id = ChartComponent.lastId++;
    }

    initialized = false;
    async ngAfterViewInit() {
        this.initialized = true;
        this.ngOnChanges();
    }

    ngOnChanges() {
        if (!this.initialized) {
            return;
        }
        this.setDefaults();
        let chart = new CanvasJS.Chart("chartContainer" + this.id, this.chartData);
        this.chartData.chart = chart;
        chart.render();
    }


    setDefaults() {
        let fromDate = this.fromDate ? this.fromDate : new Date("2020-07-03");
        let toDate = this.toDate ? this.toDate : new Date("2020-07-04");
        this.chartData.axisX.viewportMinimum = fromDate;
        this.chartData.axisX.viewportMaximum = toDate;
        this.chartData.axisY = this.chartData.axisY || {};
        this.chartData.axisY.minimum = this.min;
        this.chartData.axisY.maximum = this.max;
        this.chartData.axisY.includeZero = true;
        if (!this.chartData.data[0] || this.chartData.data[0].name !== "DEFAULT") {
            this.chartData.data.unshift({
                name: "DEFAULT",
                dataPoints: [{ x: fromDate, y: 0 }, { x: toDate, y: 0 }],
                lineColor: "#BOAEAC", color: "#BOAEAC", type: "spline", markerType: "none"
            });
        }

    }
    activatePan() {
        setTimeout(() => {
            let childElement = document.getElementsByTagName("button");
            for (let i = 0; i < childElement.length; i++) {
                if (childElement[i].getAttribute("state") === "pan") {
                    childElement[i].click();
                }
            }
        }, 100);
    }
}
