import { Component } from "@angular/core";
import { ConfigService } from "../../services/config.service";
import { openTab } from "../tabs/tab.control";
import { Deck } from "./deck";
import { DeckContainerService } from "./deck-container.service";
import { DeckService } from "./deck.service";

@Component({
  selector: "deck-container",
  templateUrl: "./deck-container.component.html",
  styleUrls: ["./deck-container.component.scss"],
  providers: [DeckContainerService]
})
export class DeckContainer {

  constructor(public deckContainerService: DeckContainerService, public configService: ConfigService) {
    openTab.subscribe(menuItem => {
      if (!this.configService.config.decks) {
        return;
      }
      let tab = {...menuItem};
      if (!tab.deck) {
        tab.deck = new Deck(new DeckService());
        deckContainerService.decks.forEach(deck=>deck.deckService.deckState = "folded")
        deckContainerService.decks.unshift(tab.deck);
      }
      tab.deck.deckService.tabs.push(tab);
      setTimeout(() => {
        tab.deck.deckService.setActiveTab(tab);
      }, 0);
    });
  }
}
