import { Component, Input, Optional } from "@angular/core";
import { XOArrayField } from './xo-array-field.component';
import { ModalService } from '../../window/modal-window/modal.service';
import { TabComponent } from '../tab/tab.component';
import { DeckTabComponent } from '../deck/deck-tab.component';

@Component({
    selector: "xo-field-array-link",
    templateUrl: "./xo-field-array-link.component.html"
})
export class XOArrayFieldLink extends XOArrayField {
    @Input() selectComponentName: string;
    @Input() linkComponentName: string
    @Input() dateLabel?: boolean = false;
    @Input() onSelect?: (ref: any, index: number) => boolean;

    constructor(public modalService: ModalService, @Optional() private tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
        super();
        this.tab = this.tab || deckTab;
    }

    openElement(reference) {
        if (this.dateLabel) {
            this.parent.openTabWithDateLabel(reference, this.linkComponentName);
        } else {
            this.parent.openTab(reference, this.linkComponentName);
        }
    }

    async selectElement(index: number) {
        let newRef = await this.modalService.selectValue(this.tab.system, this.tab.deck, this.selectComponentName, this.object[this.fieldName][index], true, this.object);
        if (!this.onSelect || this.onSelect(newRef, index)) {
            if (!newRef) {
                if (index >= 0) {
                    this.object[this.fieldName].splice(index, 1);
                }
            } else {
                if (index >= 0) {
                    this.object[this.fieldName][index] = newRef;
                } else {
                    this.object[this.fieldName].push(newRef);
                }
            }
            this.parent.validate(this.object, this.fieldName)
        }
    }
}
