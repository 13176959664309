import { TokenStore } from './TokenStore';

const TOKEN_KEY = "xoaf_login_token";

export class LocalStorageTokenStore implements TokenStore {
  set(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
    return Promise.resolve();
  }

  get(): Promise<string> {
    return Promise.resolve(localStorage.getItem(TOKEN_KEY));
  }

  erase(): Promise<void> {
    return Promise.resolve(localStorage.removeItem(TOKEN_KEY));
  }
}
