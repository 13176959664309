import { Component, Input, OnInit, Optional } from "@angular/core";
import { TabComponent } from '../tab/tab.component';
import { DeckTabComponent } from '../deck/deck-tab.component';
import { XOField } from './xo-field.component';


@Component({
  selector: "xo-field-ai-longstring",
  templateUrl: "./xo-field-ai-longstring.component.html"
})
export class XOFieldAILongString extends XOField implements OnInit {

  @Input()
  typeName: string;
  @Input()
  rows = 10;

  tab: TabComponent | DeckTabComponent;
  value: any;

  constructor(@Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super();
    this.tab = (tab || deckTab);
  }

  ngOnInit(): void {
    this.value = this.object[this.fieldName];
  }

  async accept() {
    console.log("AI Accept", this.object);
    let patchObject = { id: this.object.id }
    patchObject[this.fieldName] = this.value;
    await this.tab.getService(this.typeName).patch(patchObject);
    this.object[this.fieldName] = this.value;
  }

  async refresh() {
    console.log("AI Refresh", this.object, this.fieldName);
    let newValue = await (this.tab.getService(this.typeName).updateField(this.object.id, this.fieldName, this.value));
    console.log("AI Refresh", newValue)
    this.value = newValue;
  }

  getRows() {
    return this.value.split("\n").length + 1;
  }
}
