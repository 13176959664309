import { DataService, Element } from "@xo/services";
import { closeTab } from "../components/tabs/tab.control";
import { ValidationMessage, SEVERITY_ERROR } from '../validation/model/ValidationMessage';
import { ToastrService } from 'ngx-toastr';
import { Validator } from '../validation/model/Validator';
import { Window } from './Window';
import { validationMessages as vM } from "../validation/model/ValidationMessage";
import { ConfigService } from '../services/config.service';
import { ChangeDetectorRef } from '@angular/core';

export abstract class ObjectWindow<TYPE extends Element, SERVICE extends DataService<TYPE>> extends Window {

    public exists: boolean;

    public validationMessages: ValidationMessage[] = [];

    public validate: Function;

    public id: any;

    public object: TYPE;

    constructor(protected dataService: SERVICE, protected toastr: ToastrService, validators: Validator<TYPE>[], protected changeDetector: ChangeDetectorRef) {
        super();
        this.validate = createValidate<TYPE>(validators, this.validationMessages);
    }

    abstract setupNewObject(data);

    async init(data: any) {
        this.exists = !!data && !!data[this.dataService.idFieldName];
        if (this.exists) {
            this.id = data[this.dataService.idFieldName];
            super.init({ [this.dataService.idFieldName]: data[this.dataService.idFieldName] });
            await this.reload();
        } else {
            this.object = this.setupNewObject(data);
            super.init({});
        }
        this.changeDetector.detectChanges();
    }

    public cancel(): void {
        console.log("Form cancelled.");
        closeTab.emit();
    }


    async save(object: TYPE, validate: boolean = true, sendToServer: boolean = true, closeOnSave: boolean = true): Promise<TYPE> {
        let files = stripFileFields(object)
        let result = await this.saveObject(object, validate, sendToServer, false);
        if (!result) {
            return;
        }

        if (sendToServer) {
            await this.saveFiles(object, files);
        }

        this.toastr.info("Saved Succesully");
        if (closeOnSave) {
            closeTab.emit();
        } else {
            setTimeout(async () => {
                if (this.validationMessages.length == 0) {
                    if (!!this['component']) {
                        await this['component'].reloadTables();
                    }
                }
            });
        }
        return object;
    }

    async saveObject(object: TYPE, validate: boolean = true, sendToServer: boolean = true, closeOnSave: boolean = true): Promise<TYPE> {
        if (validate) {
            await this.validate(object);
        }
        if (!checkValidationMessages(this.validationMessages, this.toastr)) {
            return null;
        }

        if (sendToServer) {
            return this.dataService.save(object, this.exists).then((response) => {
                object[this.dataService.idFieldName] = response[this.dataService.idFieldName];
                this.exists = true;
                if (closeOnSave) {
                    closeTab.emit();
                }
                return object;
            });
        }
    }

    async saveFiles(object, files) {
        for (let [key, value] of Object.entries(files)) {
            if (value) {
                let fileName = await this.dataService.saveFile(object[this.dataService.idFieldName], key, value as File);
                object[key + "_name"] = fileName;
                delete object[key];
            } else {
                await this.dataService.deleteFile(object[this.dataService.idFieldName], key);
                object[key + "_name"] = null;
                delete object[key];
            }
        }
    }

    public async delete(type: TYPE) {
        if (confirm("Biztosan törli a bejegyzést?")) {
            await this.dataService.delete(type);
            closeTab.emit();
        }
    }
}

function stripFileFields(object) {
    let files = {};
    Object.entries(object)
        .filter(([key, value]) => isFile(value))
        .map(([key, value]) => {
            files[key] = value;
            delete object[key];
        });
    return files;
}

function isFile(value) {
  return !!value && !!value.name && !!value.size;
}

export function checkValidationMessages(validationMessages: ValidationMessage[], toastr: ToastrService) {
    if (validationMessages.length > 0) {
        console.log("Validation found issues", validationMessages);
        if (validationMessages.some(m => m.severity == SEVERITY_ERROR)) {
            toastr.error(vM[ConfigService.config.currentLanguage].VALIDATION_ERROR_FOUND) //TODO i18n
            return false;
        }
        if (!confirm(vM[ConfigService.config.currentLanguage].VALIDATION_WARNING_FOUND)) {  //TODO i18n
            return false;
        }
    }
    return true;
}

export function createValidate<TYPE>(validators: Validator<TYPE>[], validationMessages: ValidationMessage[]) {
    return function (object: TYPE, fieldName?: string): Promise<void> {
        return new Promise((resolve) => {
            let partialValidation = fieldName !== undefined;
            if (partialValidation) {
                clearMessages(validationMessages, fieldName);
                validators
                    .filter(validator => validator.fieldName == fieldName)
                    .map(validator => {
                        validator.validate(object).forEach((validationMessage) => { //TODO flatmap?
                            validationMessages.push(validationMessage);
                        });
                    });
            } else {
                validationMessages.splice(0, validationMessages.length);
                validators.forEach(validator => {
                    validator.validate(object).forEach((validationMessage) => { //TODO flatmap?
                        validationMessages.push(validationMessage);
                    });
                });
            }
            setTimeout(resolve, 0);
        });
    };
}

function clearMessages(validationMessages, fieldName) {
    let newMessages = validationMessages.filter(validationMessage => validationMessage.fieldName !== fieldName);
    validationMessages.splice(0, validationMessages.length);
    validationMessages.push(...newMessages);
}

