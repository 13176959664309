import { ModalDialogService } from '@preeco-privacy/ngx-modal-dialog';
import { ViewContainerRef } from '@angular/core';
import { modalEmitter } from '../../window/modal-window/modal.service';
import { userChanged } from '../../authentication/user.changed';
import { ComponentService } from '../../services/component.service';
import { ModalTabComponent } from '../tab/modal-tab.component';
import { pageComponents } from '../../services/page-components';

export class XOApp {

    constructor(protected modalService: ModalDialogService,
        protected componentService: ComponentService,
        protected viewRef: ViewContainerRef) {

        this.subscribe();
        this.componentService.registerModule("Common", pageComponents);
    }

    subscribe() {
        modalEmitter.subscribe((obj) => {
            this.modalService.openDialog(this.viewRef, {
                title: '',
                childComponent: ModalTabComponent,
                data: obj,
                settings: {
                    overlayClass: 'modal-backdrop show',
                    modalClass: 'modal ngx-modal'
                },
                placeOnTop: true,
            })
        });
    }
}
