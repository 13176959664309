export const ONE_DAY_IN_MILLIS = 24 * 60 * 60 * 1000;

export class SeriesOptions {
    name: string;
    dataPoints: DataPoint[];
    lineColor?: string;
    type?: string = "spline";
    xValueFormatString?: string;
    yValueFormatString?: string;
    lineDashType: "dash";
    markerType: "circle";
    suffix: "";
}

export class ChartData {

    title: any;
    fontFamily: "arial";

    animationEnabled: boolean = false;
    interactivityEnabled: boolean = true;
    exportEnabled: boolean = false;
    zoomEnabled: boolean = true;
    backgroundColor = "#EEF0F0";

    pastHours: number = 24;
    futureHours: number = 2;

    toolTip = {
        enabled: false,
        shared: true
    };
    axisX = {
        title: "",
        valueFormatString: "MMM DD HH:mm",
        interval: 6,
        intervalType: "hour",
    };

    data: SeriesOptions[] = [];

    rangeChanged: Function;

    render;

    constructor(title: string, xAxisTitle: string) {
        this.title = { text: title };
        this.axisX.title = xAxisTitle;
    }

    addSeries(userOptions, addNewYAxis = false) {
        let options = Object.assign(new SeriesOptions(), userOptions);
        this.data.push(options);
    }

}

export class AxisOptions {

    titleFontColor?: string;
    lineColor?: string;
    tickColor?: string;
    gridColor: "#00f";

    constructor(public title: string, public suffix?: string, color?: string) {
        this.titleFontColor = color;
        this.lineColor = color;
        this.tickColor = color;
        this.suffix = suffix || "";
    }
}

export interface DataPoint {
    x: number;
    y: number;
}

export function connectCharts(...chartDatas: ChartData[]) {
    function syncHandler(e) {
        for (let j = 0; j < chartDatas.length; j++) {
            let chartData = chartDatas[j] as any;

            if (e.trigger === "reset") {
                chartData.axisX.viewportMinimum = chartData.axisX.viewportMaximum = null;
                chartData.chart.render();
            } else if (chartData.chart !== e.chart) {
                chartData.axisX.viewportMinimum = e.axisX[0].viewportMinimum;
                chartData.axisX.viewportMaximum = e.axisX[0].viewportMaximum;
                chartData.chart.render();
            }
        }
    }

    for (let i = 0; i < chartDatas.length; i++) {
        let chartData = chartDatas[i];
        chartData.rangeChanged = syncHandler;
    }
}

export function createDataPoint(xFieldName: string, yFieldName: string, extraFieldName?: string) {
    return function (obj) {
        let result = {
            x: obj[xFieldName],
            y: obj[yFieldName]
        };
        if (extraFieldName) {
            result[extraFieldName] = obj[extraFieldName];
        }
        return result;
    };
}

export function createRangeDataPoint(xFieldName: string, durationFieldName: string, intesityFieldName: string) {
    return function (obj) {
        return {
            x: obj[xFieldName],
            y: obj[intesityFieldName],
            z: obj[durationFieldName]
        };
    };
}

export function setColorByValue(fieldName, fieldValue, value) {
    return function (dataPoint) {
        if (dataPoint[fieldName] === fieldValue) {
            dataPoint.color = value;
        }
        return dataPoint;
    };
}

export function setColorMin(minValue, highColor) {
    return function (dataPoint) {
        if (dataPoint.y < minValue) {
            dataPoint.markerType = "circle";
            dataPoint.markerSize = 8;
            dataPoint.markerColor = highColor;
        }
        return dataPoint;
    };
}

export function setColorMax(maxValue, highColor) {
    return function (dataPoint) {
        if (dataPoint.y > maxValue) {
            dataPoint.markerType = "circle";
            dataPoint.markerSize = 8;
            dataPoint.markerColor = highColor;
        }
        return dataPoint;
    };
}
