import { Component, Input } from "@angular/core";
import { CHILD_CREATION_CHOICE, CHILD_CREATION_BASIC, ChildType, Element } from '@xo/services';
import { modalEmitter } from '../../window/modal-window/modal.service';

@Component({
  selector: "xo-browser",
  styleUrls: ["./browser.component.scss"],
  templateUrl: "./browser.component.html"
})
export class BrowserComponent {

  CHILD_CREATION_BASIC = CHILD_CREATION_BASIC;
  CHILD_CREATION_CHOICE = CHILD_CREATION_CHOICE;

  @Input() public element: Element;

  constructor() {
  }

  async ngOnInit() {
    await this.refresh();
  }

  async refresh() {
    await this.element.XO_service.updateChildren(this.element);
  }

  createChild(childType?: ChildType) {
    let payload;
    if (!childType) {
      payload = {
        component: "Common/CreateChildComponent",
        data: {
          parent: this.element,
          childrenType: this.element.XO_service.getChildrenType(),
          resolve: () => { this.element.XO_service.updateChildren(this.element) }
        }
      }
    } else {
      payload = {
        component: childType.componentCode,
        data: {
          parent: this.element,
          resolve: () => { this.element.XO_service.updateChildren(this.element) }
        }
      };
    }

    modalEmitter.emit(payload);
  }

  async open(child) {
    this.element = child;
    await this.refresh();
  }

  getElementClasses(element: Element) {
    return element.XO_service.getClasses(element);
  }

  getChildren(): Element[] {
    let children = this.element.XO_service.getChildren(this.element) as Element[];
    return children;
  }

  getChildrenType() {
    return this.element.XO_service.getChildrenType();
  }

  getChildCreation() {
    return this.element.XO_service.childCreation;
  }
}
