import { XOModalDialog } from './../../components/tab/XOModalDialog';
import { Component, ComponentRef } from "@angular/core";
import { IModalDialog, IModalDialogOptions } from '@preeco-privacy/ngx-modal-dialog';

@Component({
    selector: "xo-linking",
    templateUrl: "./linking.component.html",
    styleUrls: ["./linking.component.scss"]
})
export class LinkingComponent implements XOModalDialog {
    linkName: string;

    dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
    }

    init() {
    }

    createChild() {
        return this.linkName;
    }
}
