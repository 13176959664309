import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "./config.service";

@Injectable()
export class GoogleDistanceService {

    constructor(private http: HttpClient, private configService: ConfigService) {
    }
    
    
    getDistance(module: string, startingLocation: string, goalLocation: string) {
        if (!startingLocation || !goalLocation) {
            return 0;
        }
        return this.http.get(this.getDistanceUrl(module, startingLocation, goalLocation), {withCredentials: true}).toPromise().then((response) => {
            let distance = Math.round((response as any).rows[0].elements[0].distance.value / 1000);
            return distance;
        });
    }
    
    private getDistanceUrl(module: string, location1: string, location2: string) {
        let googleApiUrl = this.configService.config.baseUrl[module]  + `/Common/Google/distance`;
        return `${googleApiUrl}?start=${location1}&goal=${location2}`;
    }

}
