import { Input, Component } from '@angular/core';

@Component({
    selector: "[xo-table-filter-input]",
    templateUrl: "./table-filter-input.html",
    styleUrls: []
})
export class TableFilterInput {
    
    @Input() fieldName;
    @Input() parent;

}