import { Component, Input, OnInit } from "@angular/core";
import { AuditLog } from "./AuditLog";
import { ObjectComponent } from './../../../components/object-component/object-component';
import { TabComponent } from './../../../components/tab/tab.component';
import { ModalService } from './../../../window/modal-window/modal.service';

@Component({
  selector: "xo-audit-log",
  templateUrl: "./auditLog.component.html",
  styleUrls: ["./auditLog.component.scss"]
})
export class AuditLogComponent extends ObjectComponent implements OnInit {

  @Input() public auditLog: AuditLog;

  constructor(public tab: TabComponent, public modalService: ModalService) {
    super(tab);
  }

  ngOnInit() {
  }

  async reloadTables() {
  }
}
