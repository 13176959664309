import { Component, Input } from "@angular/core";
import { upperFirstChar } from '../../utils';
import { XOField } from './xo-field.component';

@Component({
  selector: "xo-field-radio",
  templateUrl: "./xo-field-radio.component.html"
})
export class XOFieldRadio extends XOField {
  @Input() Type;

  getKeys() {
    return this.Type[this.fieldName + 'Keys'];
  }

  getLabel(key) {
    return this.Type["get" + upperFirstChar(this.fieldName) + "Values"](key);
  }

  setValue(key) {
    this.object[this.fieldName] = key;
    this.parent.validate(this.object, this.fieldName);
  }
}
