import { Component, Input, OnInit } from "@angular/core";
import { ObjectComponent, TabComponent } from "@xo/client-common";
import { Group } from "./Group";
import { ModalService } from "@xo/client-common";
import { GroupReference } from "./Group";
import { ViewChild } from "@angular/core";
import { PageService, FilterObject } from "@xo/client-common";
import { GroupTable } from "./group.table";

@Component({
  selector: "xodos-group",
  templateUrl: "./group.component.html",
  styleUrls: ["./group.component.scss"]
})
export class GroupComponent extends ObjectComponent implements OnInit {

  public Group = Group;
  @Input() public group: Group;

  public newParent: GroupReference;
  @ViewChild("groupsWithParent") groupsWithParent: GroupTable;
  groupParentFilter: FilterObject;

  constructor(public pageService: PageService, public modalService: ModalService, public tab: TabComponent) {
    super(tab);
  }

  ngOnInit() {
    this.groupParentFilter = { parent: this.group.id } as FilterObject;
  }

  async reloadTables() {
    await this.groupsWithParent.refreshList();
  }

  selectParent() {
    this.modalService.selectValue(this.tab.system, this.tab.deck, "Xodos/GroupSelect", this.group.parent, true)
      .then((newId: GroupReference) => {
        this.group.parent = newId;
        this.validate(this.group, "parent");
      });
  }

  openParentInNewTab(field: GroupReference) {
    this.openTab(field, "Xodos/GroupPage");
  }

  createClickHandleForGroupParent() {
    return this.pageService.createOpenObject(
      "Xodos/GroupPage",
      this.tab.system,
      this.tab["tab"]?.deck,
      (object) => `Group at ID:${object ? object.id : null}`,
      () => {
        return {
          parent: new GroupReference(this.group.id, this.group.name)
        };
      }
    );
  }

}
