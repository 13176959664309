import { Component, ViewContainerRef } from '@angular/core';
import { ModalDialogService } from '@preeco-privacy/ngx-modal-dialog';
import { ConfigService, CurrentUserContainer, XOApp, ComponentService, modalEmitter, userChanged, openTab } from '@xo/client-common';
import { moduleNames } from 'projects/xodos/src/xodos.service';
import { XODOS } from "./menu/menu.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends XOApp {
  constructor(public modalService: ModalDialogService,
    public viewRef: ViewContainerRef, public currentUserContainer: CurrentUserContainer,
    private xoConfigService: ConfigService, protected componentService: ComponentService) {
    super(modalService, componentService, viewRef);
    this.componentService.registerModule(XODOS, moduleNames);
  }

  subscribe() {
    modalEmitter.subscribe((obj) => {
      this.modalService.openDialog(this.viewRef, {
        title: '',
        childComponent: this.extractComponent2(obj),
        data: obj,
        settings: {
          overlayClass: 'modal-backdrop show',
          modalClass: 'modal ngx-modal',/*
          headerTitleClass: 'hidden',
          headerClass: 'hidden' */
        },
        placeOnTop: true,
      })
    });
    userChanged.subscribe((user) => {
      if (!user) {
        let currentUrl = window.location.href.split("#")[1];
        currentUrl = encodeURIComponent(currentUrl);
        let logoutUrl = this.xoConfigService.config["loginUrl"] + "?XO_PATH=" + currentUrl;
        window.open(logoutUrl, '_self', '');
      }
    });
  }

  private extractComponent2(object) {
    if (!!object.component) {
      return this.componentService.getComponent(object.component);
    }
    throw new Error("Cannot find component: " + JSON.stringify(object));
  }

}
