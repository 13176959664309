import { Component, EventEmitter } from "@angular/core";
import { ConfigService } from '../../services/config.service';

export let languageChanged = new EventEmitter<string>();
export let setLanguage = new EventEmitter<string>();

@Component({
    selector: "xo-language-selector",
    templateUrl: "./language-selector.component.html",
    styleUrls: ["./language-selector.component.scss"]
})
export class LanguageSelectorComponent {

    currentLanguage: string;

    constructor(public configService: ConfigService){
        this.currentLanguage = configService.config.currentLanguage;
        this.subscribe();
    }

    subscribe(){
        setLanguage.subscribe((language)=>{
            if(language != this.currentLanguage){
                this.configService.config.currentLanguage = language;
                languageChanged.emit(language);
            }
        });
        languageChanged.subscribe((language)=>{
            setTimeout(() => {
                (window.location as any) = "/" + language;
            }, 100);
        });
    }

    itemClicked(languageItem){
        setLanguage.emit(languageItem);
    }

}
