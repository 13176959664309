import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'xo-theme-future',
  templateUrl: 'future.theme.html',
  styleUrls: ["../future.scss"],
  encapsulation: ViewEncapsulation.None
})
export class XoFutureThemeComponent {
}
