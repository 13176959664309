import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'xo-theme-default',
  templateUrl: 'default.theme.html',
  styleUrls: ["../default.scss"],
  encapsulation: ViewEncapsulation.None
})
export class XoDefaultThemeComponent {
}
