import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, KeyValueDiffers } from '@angular/core';
import { AppComponent } from './app.component';
import { XodosModule } from 'projects/xodos/src/public_api';
import { XodosMenuService, XODOS } from './menu/menu.service';
import { MenuService, XoCommonModule, ConfigService, TokenStore } from '@xo/client-common';
import { ToastrModule } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { createXodosServiceFactory } from 'projects/xodos/src/xodos.service';
import { ModalDialogModule, ModalDialogService, ModalDialogInstanceService } from '@preeco-privacy/ngx-modal-dialog';

declare var config;

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    ToastrModule.forRoot(),
    XodosModule,
    XoCommonModule,
    ModalDialogModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService, http: HttpClient, differs: KeyValueDiffers) => async () => { await init(configService, http, differs); },
      deps: [ConfigService, HttpClient, KeyValueDiffers],
      multi: true
    },
    { provide: MenuService, useClass: XodosMenuService },
    { provide: XodosMenuService, useClass: XodosMenuService },
    ModalDialogService, ModalDialogInstanceService
  ],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule { }


//TODO move to common
export class ConstTokenStore implements TokenStore {

  constructor(private token: string) { }

  async set(token: string) {
    console.log("token store set")
  }

  async erase() {
    console.log("token store erase")
  }

  get(): Promise<string> {
    return Promise.resolve(this.token);
  }
}

async function init(configService: ConfigService, http: HttpClient, differs: KeyValueDiffers) {
  console.log("app init1")
  let tokenStore;

  if (!!config.loginToken) {
    tokenStore = new ConstTokenStore(config.loginToken);
  }
  await configService.load(tokenStore);
  configService.serviceFactories.set(XODOS, createXodosServiceFactory(configService, http, differs));
  console.log("app init2")
}


/*
function createXodosServiceFactory(configService: ConfigService, http: HttpClient, differs: KeyValueDiffers) {
  return function (systemName: string) {
    let taskElementTransformator = new TaskElementTransformator();
    let taskUrlService = new TaskUrlService(systemName, configService);
    let taskervice: CachingDataService<Task> = new CachingTaskService(http, taskUrlService, taskElementTransformator);


    let groupTransformator = new GroupTransformator(systemName);
    let groupUrlService = new GroupUrlService(systemName, configService);
    let groupService = new CachingGroupService(http, groupUrlService, groupTransformator);
    taskElementTransformator.groupService = groupService;


    let taskElementService = new TaskElementService(taskervice, differs, http, taskUrlService);

    taskElementService.groupService = this.groupService;
    taskElementService.treeState = this.treeState;

    return [
      taskervice,
      taskElementService,
      groupService
    ]
  }
}
 */
