import { TabComponent } from './../tab/tab.component';
import { Component, Input, Optional } from "@angular/core";
import { ModalService } from '../../window/modal-window/modal.service';
import { XOField } from './xo-field.component';
import { DeckTabComponent } from '../deck/deck-tab.component';

@Component({
    selector: "xo-field-link",
    templateUrl: "./xo-field-link.component.html"
})
export class XOFieldLink extends XOField {

    @Input() selectComponentName: string;
    @Input() linkComponentName: string
    @Input() dateLabel?: boolean = false;
    @Input() onSelect?: (ref: any) => boolean;
    @Input() map?: (label: any) => string = label => label;

    constructor(public modalService: ModalService, @Optional() private tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
        super();
        this.tab = this.tab || deckTab;
    }

    linkFunction(reference) {
        if (this.dateLabel) {
            this.parent.openTabWithDateLabel(reference, this.linkComponentName);
        } else {
            this.parent.openTab(reference, this.linkComponentName);
        }
    }

    selectFunction() {
        this.modalService.selectValue(this.tab.system, this.tab.deck, this.selectComponentName, this.object[this.fieldName], true, this.object)
            .then((newRef) => {
                if (!this.onSelect || this.onSelect(newRef)) {
                    this.object[this.fieldName] = newRef;
                    this.parent.validate(this.object, this.fieldName)
                }
            })
    }
}
