import { Component, Input } from "@angular/core";
import { upperFirstChar } from '../../utils';
import { XOFieldSelect } from './xo-field-select.component';

@Component({
    selector: "xo-field-select-date",
    templateUrl: "./xo-field-select-date.component.html"
})
export class XOFieldSelectDate extends XOFieldSelect {
}
