import { Component, Input, ElementRef, OnInit, Optional } from "@angular/core";
import { openTab } from '../tabs/tab.control';
import { TabComponent } from '../tab/tab.component';
import { ModalTabComponent } from '../tab/modal-tab.component';
import { DeckTabComponent } from '../deck/deck-tab.component';

@Component({
    selector: "[xo-table-data-link-array]",
    templateUrl: "./table-data-link-array.html"
})
export class TableDataLinkArray implements OnInit {
    @Input() object;
    @Input() fieldName: string;
    @Input() labelField = "label";
    @Input() componentName: string;

    constructor(private elRef: ElementRef, @Optional() private modalTab: ModalTabComponent, @Optional() private tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
        this.tab = this.tab || deckTab;
    }

    ngOnInit() {
        this.elRef.nativeElement.classList.add(this.fieldName);
    }

    openTab(event, element) {
        if (!!this.componentName) {
            if (!!element) {
                let tab = { label: element.label, component: this.componentName, system: !!this.tab ? this.tab.system : this.modalTab.system, deck: (this.tab as any)?.tab?.deck, data: element };
                openTab.emit(tab);
            }
        }
        event.stopPropagation();
    }
}
