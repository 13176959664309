import { Component, Optional } from "@angular/core";
import { TabComponent } from "../tab/tab.component";
import { DeckTabComponent } from '../deck/deck-tab.component';
import { XOField } from "./xo-field.component";

@Component({
  selector: "xo-field-file",
  templateUrl: "./xo-field-file.component.html",
})
export class XOFieldFile extends XOField {
  constructor(@Optional() private tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super();
    this.tab = this.tab || deckTab;
  }

  download() {
    this.tab.getService(this.object["XO_type"]).downloadFile(this.object.id, this.fieldName);
  }

  clear() {
    this.object[this.fieldName] = null;
  }
}
