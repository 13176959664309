import { Input } from "@angular/core";
import { ObjectComponent } from '../object-component/object-component';

export abstract class XOField {
  @Input() object: any;
  @Input() fieldName: string;
  @Input() title: string;
  @Input() parent: ObjectComponent;
  @Input() disabled?: boolean = false;

  validate(...args) {
    return () => {
      this.parent.validate(...args);
    };
  }
}
