import { Validator } from './Validator';
import { Error as XOError } from './Error';
import { Warning } from './Warning';
import { Info } from './Info';
import { transformDateToServer } from '@xo/services';

let i18n = {
    required: {
        en: "Field is mandatory",
        hu: "Kötelezően kitöltendő mező"
    },
    maxLength: {
        en: "Maximum character length cannot exceed ",
        hu: "A szöveg nem lehet hosszabb, mint "
    },
    maxValue: {
        en: "Maximum character should be ",
        hu: "A érték nem lehet nagyobb, mint "
    },
    minValue: {
        en: "Minimum value should be ",
        hu: "A érték nem lehet kisebb, mint "
    },
}

/**
 * Only one of the fields must be set
 * @param fieldNames
 * @param labels
 */
export function createMutuallyExclusive<TYPE>(fieldNames: string[], labels: string[]): Validator<TYPE> {
    if (fieldNames.length != labels.length) {
        throw new Error("Invalid validator definition: " + fieldNames.join() + " " + labels.join());
    }
    return <Validator<TYPE>>{
        fieldName: null,
        validate: (object) => {
            let filled = fieldNames.filter(fieldName => !!object[fieldName]);
            if (filled.length > 1) {
                let messages = fieldNames.map(fieldName => new Warning(fieldName, "Please only fill out one of these fields: " + labels.join(", ")));
                messages.unshift(new XOError(null, "Please only fill out one of these fields: " + labels.join(", ")));
                return messages;
            } else {
                return [];
            }
        }
    };
}

/**
 * Field should not have the values.
 * @param fieldName
 * @param excludeValues
 */
export function createExcludeValidator<TYPE>(fieldName: string, ...excludeValues: any[]): Validator<TYPE> {
    return <Validator<TYPE>>{
        fieldName: fieldName,
        validate: (object) => {
            if (excludeValues.indexOf(object[fieldName]) >= 0) {
                return [new Warning(fieldName, "Please don't use these values: " + excludeValues.join(", "))];
            } else {
                return [];
            }
        }
    };
}

/**
 * Field's value length should be less or equal
 * @param maxLength
 * @param fieldName
 */
export function createMaxLengthValidator<TYPE>(maxLength, fieldName: string, messageLanguage: string = "en"): Validator<TYPE> {
    return <Validator<TYPE>>{
        fieldName: fieldName,
        validate: (object) => {
            if (!!object[fieldName] && object[fieldName].length > maxLength) {
                return [new Warning(fieldName, `${i18n.maxLength[messageLanguage]} ${maxLength}`)];
            } else {
                return [];
            }
        }
    };
}

/**
 * Field's value should be less or equal
 * @param maxValue
 * @param fieldName
 */
 export function createMaxValueValidator<TYPE>(maxValue, fieldName: string, messageLanguage: string = "en"): Validator<TYPE> {
    return <Validator<TYPE>>{
        fieldName: fieldName,
        validate: (object) => {
            if (!!object[fieldName] && object[fieldName] > maxValue) {
                return [new XOError(fieldName, `${i18n.maxValue[messageLanguage]} ${maxValue}`)];
            } else {
                return [];
            }
        }
    };
}

/**
 * Field's value should be greater or equal
 * @param minValue
 * @param fieldName
 */
export function createMinValueValidator<TYPE>(minValue, fieldName: string, messageLanguage: string = "en"): Validator<TYPE> {
    return <Validator<TYPE>>{
        fieldName: fieldName,
        validate: (object) => {
            if (!!object[fieldName] && object[fieldName] < minValue) {
                return [new XOError(fieldName, `${i18n.minValue[messageLanguage]} ${minValue}`)];
            } else {
                return [];
            }
        }
    };
}


/**
 * All fields in the list are mandatory
 * @param fieldNames
 */
export function createRequiredValidators<TYPE>(fieldNames: string[], validationType: string = "error", messageLanguage: string = "en"): Validator<TYPE>[] {
    return fieldNames.map(fieldName => {
        return <Validator<TYPE>>{
            fieldName: fieldName,
            validate: (object) => {
                if (object[fieldName] === undefined || object[fieldName] === null || object[fieldName] === "") {
                    return validationType == "info" ?
                        [new Info(fieldName, i18n.required[messageLanguage])]
                        : [new XOError(fieldName, i18n.required[messageLanguage])];
                } else {
                    return [];
                }
            }
        }
    });
}

/**
 * Link field should not be among excluded values
 * @param fieldName
 * @param idField
 * @param excludedValues
 */
export function createExcludeLinkValidator<TYPE>(fieldName, idField, excludedValues): Validator<TYPE> {
    return <Validator<TYPE>>{
        fieldName: fieldName,
        validate: (object) => {
            if (!!object[fieldName] && excludedValues.indexOf(object[fieldName][idField]) >= 0) {
                return [new Warning(fieldName, "Please don't use these values: " + excludedValues.join(", "))];
            } else {
                return [];
            }
        }
    };
}


/**
 * Simple Regexp validator
 * @param fieldName
 * @param regex
 * @param message error message
 */
export function createRegexpValidator<TYPE>(fieldName: string, regex: RegExp, message: string): Validator<TYPE> {
    return {
        fieldName,
        validate: (object) => {
            if (!!object[fieldName]) {
                return regex.test(object[fieldName]) ? [] : [new XOError(fieldName, message)];
            } else {
                return [];
            }
        }
    };
}


/**
 * Simple Regexp Array validator
 * @param fieldName
 * @param regex
 * @param message error message
 */
export function createRegexpArrayValidator<TYPE>(fieldName: string, regex: RegExp, message: string): Validator<TYPE> {
    return {
        fieldName,
        validate: (object) => {
            let array = object[fieldName]
            if (!!array) {
                return array.reduce((result, value) => {
                    if (!regex.test(value)) {
                        result.push(new XOError(fieldName, message));
                    }
                    return result;
                }, []);
            } else {
                return [];
            }
        }
    };
}

/**
 * Compares first and second argument date fields (as dates), fails if operation result is fale
 * @param dateFieldName1
 * @param operator
 * @param dateFieldName2
 */
export function createDateCompareValidator<TYPE>(dateFieldName1: string, operator: string, dateFieldName2: string): Validator<TYPE> {
    function validate(date1: Date, date2: Date) {
        if (!date1 || !date2) {
            return false;
        }

        if (operator === ">=") {
            return date1.getTime() >= date2.getTime();
        }
        if (operator === "<=") {
            return date1.getTime() <= date2.getTime();
        }
        if (operator === ">") {
            return date1.getTime() > date2.getTime();
        }
        if (operator === "<") {
            return date1.getTime() < date2.getTime();
        }
        return date1.getTime() === date2.getTime();
    }

    function getLeftMessage(): string {
        if (operator === ">=") {
            return "Must be greater or equal than " + dateFieldName2;
        }
        if (operator === "<=") {
            return "Must be lower or equal than " + dateFieldName2;
        }
        if (operator === ">") {
            return "Must be greater than " + dateFieldName2;
        }
        if (operator === "<") {
            return "Must be lower than " + dateFieldName2;
        }
        return "Must be equal to " + dateFieldName2;
    }


    function getRightMessage(): string {
        if (operator === ">=") {
            return "Must be lower or equal than " + dateFieldName1;
        }
        if (operator === "<=") {
            return "Must be greater or equal than " + dateFieldName1;
        }
        if (operator === ">") {
            return "Must be lower than " + dateFieldName1;
        }
        if (operator === "<") {
            return "Must be greater than " + dateFieldName1;
        }
        return "Must be equal to " + dateFieldName1;
    }

    function validator(object: TYPE) {
        return validate(object[dateFieldName1], object[dateFieldName2])
            ? []
            : [
                new XOError(dateFieldName1, getLeftMessage()),
                new XOError(dateFieldName2, getRightMessage()),
            ];
    }

    return <Validator<TYPE>>{
        fieldName: null,
        validate: validator
    };
}

/**
 * Date field minimum value validator.
 * @param fieldName
 * @param minValue
 * @param error should throw error or only warning
 */
export function createMinDateValidator<TYPE>(fieldName, minValue: Date, error = true) {
    return <Validator<TYPE>>{
        fieldName: fieldName,
        validate: (object: TYPE) => {
            let currentValue = new Date(object[fieldName]);
            if (!currentValue) {
                return [];
            }

            if (currentValue.getTime() >= minValue.getTime()) {
                return [];
            }

            if (error) {
                return [new XOError(fieldName, "Must be after " + transformDateToServer(minValue))];
            } else {
                return [new Warning(fieldName, "Should be after " + transformDateToServer(minValue))];
            }
        }
    };
}


/**
 * Date field maxmin value validator.
 * @param fieldName
 * @param minValue
 * @param error should throw error or only warning
 */
export function createMaxDateValidator<TYPE>(fieldName, maxValue: Date, error = true) {
    return <Validator<TYPE>>{
        fieldName: fieldName,
        validate: (object: TYPE) => {
            let currentValue = new Date(object[fieldName]);
            if (!currentValue) {
                return [];
            }

            if (currentValue.getTime() <= maxValue.getTime()) {
                return [];
            }

            if (error) {
                return [new XOError(fieldName, "Must be before " + transformDateToServer(maxValue))];
            } else {
                return [new Warning(fieldName, "Should be before " + transformDateToServer(maxValue))];
            }
        }
    };
}
