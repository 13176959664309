import { ViewChild, Component, ComponentRef, ViewContainerRef } from '@angular/core';
import { IModalDialog, IModalDialogOptions } from '@preeco-privacy/ngx-modal-dialog';
import { ObjectReference, ChildType } from '@xo/services';
import { ComponentService } from '../../services/component.service';

@Component({
    selector: "xo-create-child",
    templateUrl: "./create-child.component.html",
    styleUrls: ["./create-child.component.scss"]

})
export class CreateChildComponent implements IModalDialog {

    childrenType: ChildType[];
    parent: ObjectReference;

    selectedType: string;

    data: any;

    @ViewChild("simpleComponent", { read: ViewContainerRef }) simpleComponent: ViewContainerRef;

    constructor(private componentService: ComponentService) {
    }

    dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
        this.data = options.data.data; //TODO options.data
        this.parent = this.data.parent;
        this.childrenType = this.data.childrenType;
        this.selectedType = this.childrenType[0].componentCode;
        this.switchComponent();
    }

    switchComponent() {
        this.simpleComponent.remove();
        this.componentService.createComponent(
            this.simpleComponent,
            this.selectedType,
            {
                parent: this.parent,
                resolve: this.data.resolve
            }
        );
    }
}
