import { TabComponent } from './../../../components/tab/tab.component';
import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuditLog, AuditLogType } from "./AuditLog";
import { AuditLogComponent } from "./auditLog.component";
import { AuditLogService } from "./auditLog.service";
import { createAuditLogValidators } from "./AuditLogValidation";
import { ObjectWindow } from './../../../window/objectWindow';

@Component({
  selector: "xo-audit-log-page",
  templateUrl: "./auditLog-page.component.html",
  styleUrls: ["./auditLog-page.component.scss"]
})
export class AuditLogPage extends ObjectWindow<AuditLog, AuditLogService> {

  @ViewChild("component", { static: false }) component: AuditLogComponent;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    public tab: TabComponent) {

    super(tab.getService(AuditLogType), toastrService, createAuditLogValidators(), changeDetectorRef);
  }

  setupNewObject(rawAuditLog) {
    let auditLog = Object.assign(new AuditLog(), rawAuditLog);
    return auditLog;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }
}
