import { languageChanged } from "./../language-selector/language-selector.component";
import { ConfigService } from "../../services/config.service";
import { hasPermission, PERMISSION_MENU } from "../../authentication/auth.util";
import { CurrentUserContainer } from "../../authentication/current-user-container.service";
import { System } from "../tabs/System";
import { AuditLogType } from '../../modules/common/auditlog/AuditLog';
import { COMMON } from '../../xo-common.module';
import { ExcelType } from '../../modules/common/excel/excel.component';
import { MenuItem } from "./MenuItem";

export abstract class MenuService {

  menu: any[];
  abstract getModules(): Promise<MenuItem[]>;

  constructor(protected configService: ConfigService, protected currentUserContainer: CurrentUserContainer) { }

  createMenu(label, ...items: MenuItem[]) {
    let menuItem = new MenuItem(label, null, items.filter(item => !!item));
    if (menuItem.items.length == 0) {
      return null;
    }
    return menuItem;
  }

  createMenuItem(system: System, permissionModule: string, permissionType: string, permissionOperation: string, label: string, action: any, data?: any, openByDefault = false): MenuItem {
    if (!hasPermission(system.name, permissionModule, permissionType, permissionOperation)) {
      return null;
    }
    let menuItem = new MenuItem(label);
    menuItem.system = system;
    if (typeof action === "string") {
      menuItem.component = action;
      menuItem.data = data;
    }
    if (typeof action === "function") {
      menuItem.action = action;
    }
    menuItem.openByDefault = openByDefault;
    return menuItem;
  }

  createProfileMenu() {
    let profileMenu = this.createMenu("Profile",
      new MenuItem("Profile", "Common/ProfileUser"),
      ...this.createLanguageMenuItems(),
      new MenuItem("Logout", null, null, null, this.currentUserContainer.logout.bind(this.currentUserContainer)),
    );
    profileMenu.cssClass = "profile";
    return profileMenu;
  }

  createLanguageMenuItems() {
    let languageMenuItems = [];
    if (!!this.configService.config.languages && this.configService.config.languages.length > 0) {
      this.configService.config.languages.forEach((language) => {
        languageMenuItems.push(new MenuItem(language, null, null, null, () => languageChanged.emit(language)));
      });
    }
    return languageMenuItems;
  }

  async createModuleMenu(moduleName: string, menuFactory: (multipleSystems: boolean, system: System) => Promise<MenuItem[]>): Promise<MenuItem[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let systemNames = this.configService.config.modules.filter(module => module.module == moduleName).map(module => module.system);
        let multipleSystems = systemNames.length > 1;
        let systems = systemNames.map(systemName => new System(systemName, this.configService.serviceFactories.get(moduleName)(systemName)))
        if (!!this.configService.onSystemInit) {
          await Promise.all((systems.map(this.configService.onSystemInit)));
        }
        let systemMenus = ((await Promise.all((systems.map(menuFactory.bind(null, multipleSystems))))) as any).flat() as MenuItem[];
        resolve(systemMenus);
      } catch (e) {
        reject(e);
      }
    });
  }
  public async createCommonMenu() {
    try {
      return this.createModuleMenu(COMMON, async (multipleSystems: boolean, system: System) => {

        return [
          this.createMenu(
            (multipleSystems ? system.name + " / " : "") + "Common",
            this.createMenuItem(system, COMMON, AuditLogType, PERMISSION_MENU, "Auditlog", "Common/AuditLogListWindowComponent"),
            this.createMenuItem(system, COMMON, ExcelType, PERMISSION_MENU, "Excel", "Common/ExcelComponent"),
          ),
        ];
      });
    } catch (e) {
      console.log(e)
    }
  }
}

