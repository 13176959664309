import { Component, OnInit, HostListener, ViewChild, AfterViewInit } from "@angular/core";
import { ToastrService, ToastContainerDirective } from "ngx-toastr";
import { tabsOpenEmmitter } from "../components/menu/menu.component";
import { ConfigService } from "../services/config.service";
import { ActivatedRoute } from '@angular/router';
import { openTab } from '../components/tabs/tab.control';

declare var $: any;

@Component({
  selector: "xo-main-page",
  templateUrl: "./main-page.component.html",
  styleUrls: ["./main-page.component.scss"],
})
export class MainPageComponent implements OnInit, AfterViewInit {

  tabsOpen = false;

  paramsAvailabe: Promise<any>;

  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;

  constructor(private route: ActivatedRoute, private toastrService: ToastrService, public configService: ConfigService) {
    tabsOpenEmmitter.subscribe(open => this.tabsOpen = open);
    this.subcribe();
  }


  async ngOnInit() {
    this.toastrService.overlayContainer = this.toastContainer;
    //TODO ApplicationStarted.emit();
    if (this.configService.onApplicationInit) {
      this.configService.onApplicationInit();
    }
  }

  async subcribe() {
    this.paramsAvailabe = new Promise((resolve) => {
      this.route.params.subscribe((params) => {
        if (!!params && !!params.componentType) {
          resolve(params);
        }
      });
    });
  }

  async ngAfterViewInit() {
    await this.initComponent();
  }

  async initComponent() {
    let params = await this.paramsAvailabe;
    if (!!params.componentModule && !!params.title && !!params.componentType) {
      openTab.emit({
        component: params.componentModule + "/" + params.componentType,
        system: {
          name: params.systemName,
          services: this.configService.serviceFactories.get(params.componentModule)(params.systemName)
        },
        label: params.title,
        data: params.parameters ? JSON.parse(params.parameters) : {}
      })
    }
  }
}
