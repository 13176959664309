import { Component, Input, HostListener } from "@angular/core";
import { keyFunctions, ctrlKeyFunction, preventDefaults, shiftKeyFunction } from '../Keyhandlers';
import { TreeState } from '@xo/services';

@Component({
  selector: "xo-tree",
  templateUrl: "./tree.component.html",
  styleUrls: ["./tree.component.scss"]
})
export class TreeComponent {

  @Input() public state: TreeState;
  @Input() public active: boolean;
  @Input() public extraKeyHandlers?

  constructor() {

  }

  @HostListener("window:keyup", ['$event'])
  keyUpEvent(event: KeyboardEvent) {
    if (!this.active) {
      return;
    }
    if (preventDefaults.indexOf(event.keyCode) >= 0) {
      event.preventDefault();
    }
    if (!!event.shiftKey && this.extraKeyHandlers["modifier_shift"][event.keyCode]) {
      this.extraKeyHandlers["modifier_shift"][event.keyCode](this.state);
      event.stopPropagation();
      return;
    }
    if (!!event.shiftKey) {
      shiftKeyFunction[event.keyCode](this.state);
      event.stopPropagation();
      return;
    }
    if (!!event.ctrlKey && this.extraKeyHandlers["modifier_ctrl"][event.keyCode]) {
      this.extraKeyHandlers["modifier_ctrl"][event.keyCode](this.state);
      event.stopPropagation();
      return;
    }
    if (!!event.ctrlKey) {
      ctrlKeyFunction[event.keyCode](this.state);
      event.stopPropagation();
      return;
    }
    if (this.extraKeyHandlers["modifier_none"][event.keyCode]) {
      this.extraKeyHandlers["modifier_none"][event.keyCode](this.state);
      event.stopPropagation();
      return;
    }
    if (keyFunctions[event.keyCode]) {
      keyFunctions[event.keyCode](this.state);
      event.stopPropagation();
      return;
    }
  }

  @HostListener("window:keypress", ['$event'])
  keyPressEvent(event: KeyboardEvent) {
    if (!this.active) {
      return;
    }
  }

  @HostListener("window:keydown", ['$event'])
  keyDownEvent(event: KeyboardEvent) {
    if (!this.active) {
      return;
    }
    if (preventDefaults.indexOf(event.keyCode) >= 0) {
      event.preventDefault();
    }
    if (this.extraKeyHandlers["modifier_none"]["_default_down"]) {
      this.extraKeyHandlers["modifier_none"]["_default_down"](this.state, event);
      event.stopPropagation();
    }
  }
}

