import { Component } from "@angular/core";
import { XOArrayField } from './xo-array-field.component';

@Component({
    selector: "xo-field-array-date",
    templateUrl: "./xo-field-array-date.component.html"
})
export class XOArrayFieldDate extends XOArrayField {
    convertStringToDate(convertable: any) {
        return new Date(convertable.value);
    }
}
