import { Component, Input, AfterViewInit } from "@angular/core";
import { TabService } from "./tabs.service";

@Component({
  selector: "xo-tabs",
  templateUrl: "./tabs.html",
  styleUrls: ["./tabs.scss"]
})
export class XoTabsComponent implements AfterViewInit {
  @Input() disabled: boolean;

  constructor(public tabService: TabService) { }

  selectTab(tab: any) {
    this.tabService.selectTab(tab);
  }

  isDisabled() {
    if (this.disabled) {
      return "block";
    } else {
      return "none";
    }
  }

  ngAfterViewInit() {
    this.tabService.autoSelectActiveTab();
  }
}
