import { Location } from '@angular/common';
import { Component, Input, ViewContainerRef, QueryList, ViewChildren, AfterViewInit, OnInit } from "@angular/core";
import { ComponentService } from "../../services/component.service";
import { ConfigService } from "../../services/config.service";
import { TabComponent } from "../tab/tab.component";
import { DeckService } from "./deck.service";

@Component({
  selector: "xo-deck-tab",
  templateUrl: "./deck-tab.component.html",
  styleUrls: ["./deck-tab.component.scss"]
})
export class DeckTabComponent extends TabComponent implements AfterViewInit, OnInit {

  @Input() tab;
  @Input() public deckService: DeckService;
  @Input() index;

  @ViewChildren('content', { read: ViewContainerRef }) tabContents: QueryList<ViewContainerRef>;

  component: any; //TODO TabComp a common-bol, ki kell meg ajanlani

  constructor(componentService: ComponentService, location: Location, configService: ConfigService) {
    super(componentService, location, configService);
  }

  ngOnInit(): void {
    this.tab.tabComponent = this;
    this.system = this.tab.system;
  }

  ngAfterViewInit() {
    this.addComponent();

    this.setTabWidth();

    this.tabContents.changes.subscribe(() => {
      this.addComponent();
    });

  }

  protected async addComponent() {
    let tabContent = this.tabContents.first;
    if (!tabContent) {
      return true;
    }

    this.component = await this.componentService.createComponent(tabContent, this.tab.component, this.tab.data);
    return false;
  }

  setTabWidth() {
    if (!isNaN(this.index) && this.index > 0) {
      let width = 90 - (2 * this.index);
      let element = document.getElementById(`tabtitleat${this.index}`);

      this.setElementWidth(element, width);
    }
  }

  setElementWidth(element: any, width: number) {
    if (!!element) {
      element.style.maxWidth = `${width}vw`;
    }
  }

  closeTab() {
    this.deckService.closeTab(this.tab);
  }
}
