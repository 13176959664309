import { Injectable } from "@angular/core";
import { Element } from "@xo/services";
import { ChartLine } from "./ChartLine";
import { ChartData } from "./ChartData";

@Injectable()
export class ChartLoader {

    constructor() {

    }

    async reload(lines: ChartLine<Element>[], filters: any, forceReload = true) {
        let chartData = new ChartData("", null);
        let visibleLines = lines.filter(line => line.visible);

        for (let i = 0; i < visibleLines.length; i++) {
            let line = visibleLines[i];
            await loadAndTransform(filters, line, forceReload);
        }
        return visibleLines.reduce(addSeries, chartData);
    }
}

async function loadAndTransform(filters: any, line: ChartLine<Element>, forceReload) {
    await line.reload(filters, forceReload);
}

function addSeries(chartData: ChartData, line: ChartLine<any>) {
    chartData.addSeries({
        name: line.label,
        dataPoints: line.data,
        lineColor: line.color,
        color: line.color,
        type: "line",
        markerColor: line.color,
        markerType: null
    });
    return chartData;
}
