import { Component, Input} from "@angular/core";
import { ValidationMessage } from '../../validation/model/ValidationMessage';

@Component({
  selector: "xo-validated-field",
  templateUrl: "./validated-field.component.html",
  styleUrls: ["./validated-field.component.scss"]
})
export class ValidatedFieldComponent {

  @Input() public fieldName: string;
  @Input() public label?: string;
  @Input() public validationMessages: ValidationMessage[];

}
