import { Component, Input, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { Node } from "@xo/services";
import { GraphState } from '../graph.state';
import { getDistance, MINUMUM_OPEN_DISTANCE } from '../drag.service';
import { DragNodeService } from '../drag.node.service';
import { DragCreateService } from '../drag.create.service';

declare var arbor;

function updateNodeSize(ctx: CanvasRenderingContext2D, target: Node, fontSize: number) {
    let measure = ctx.measureText(target.label);
    target.svgNode.height = fontSize * 3;
    target.svgNode.width = measure.width;
    return measure.width;
}

function normalizePath(point1, point2) {
    if (point1.x > point2.x) {
        return [point2.x, point2.y, point1.x, point1.y];
    }
    return [point1.x, point1.y, point2.x, point2.y];
}
export class NewRenderer {
    canvas: any;
    ctx: CanvasRenderingContext2D;
    particleSystem: any;
    dragNodeService: DragNodeService;
    dragCreateService: DragCreateService;

    constructor(private arborJS, private graphState: GraphState, canvas: HTMLCanvasElement) {
        this.canvas = canvas;
        this.ctx = this.canvas.getContext("2d");
    }

    init(system) {
        this.particleSystem = system;
        this.particleSystem.screenSize(this.canvas.width, this.canvas.height);
        this.particleSystem.screenPadding(80);

        this.dragNodeService = new DragNodeService(this.graphState.sys, this.canvas, this.arborJS, this.graphState);


        this.canvas.addEventListener("mousedown", this.dragNodeService.createClickListener());
        this.canvas.addEventListener("mousemove", this.dragNodeService.createDragListener());
        this.canvas.addEventListener("mouseup", this.dragNodeService.createDropListener());


        this.dragCreateService = new DragCreateService(this.graphState.sys, this.canvas, this.arborJS, this.graphState);
        this.canvas.addEventListener("mousedown", this.dragCreateService.createClickListener());
        this.canvas.addEventListener("mousemove", this.dragCreateService.createDragListener());
        this.canvas.addEventListener("mouseup", this.dragCreateService.createDropListener());

        this.canvas.addEventListener("mousemove", this.loadChildren.bind(this));
        this.canvas.addEventListener("mousemove", this.dragNodeService.activeNodeListener());
    }

    async loadChildren(e) {
        try {

            if (!e.shiftKey) {
                return;
            }
            let mouseP = this.getMousePositionOnArbor(e);
            let nearest = this.particleSystem.nearest(mouseP);
            let pixel = this.particleSystem.fromScreen(mouseP);
            if (!!nearest && !!nearest.node && getDistance(nearest.node.p, pixel) < MINUMUM_OPEN_DISTANCE) {
                let nodeElement = nearest.node.data.node as Node;
                await nodeElement.XO_service.updateChildren(nodeElement);
                await nodeElement.XO_service.getChildren(nodeElement).forEach(async (child: Node) => {
                    await this.graphState.addElement(child);
                    this.graphState.createLink(nodeElement, child);
                });
                await nodeElement.XO_service.getParents(nodeElement).forEach(async (child: Node) => {
                    await this.graphState.addElement(child);
                    this.graphState.createLink(child, nodeElement);
                });
            }
        } catch (e) {
            console.log("error in loadChildren", e);
        }
    }

    drawEdges(edge, point1, point2) {
        try {
            this.ctx.strokeStyle = "rgba(0,0,0, .333)";
            this.ctx.lineWidth = 1;
            this.ctx.beginPath();
            this.ctx.moveTo(point1.x, point1.y);
            this.ctx.lineTo(point2.x, point2.y);
            this.ctx.stroke();

            // half arrowhead
            let angle = 0.6;
            this.ctx.rotate(angle);
            this.ctx.beginPath();
            this.ctx.moveTo(point2.x, point2.y);
            this.ctx.lineTo(point2.x + 20, point2.y + 20);
            this.ctx.stroke();
            this.ctx.rotate(-angle);

            this.ctx.font = this.graphState.fontSize + "px Arial";
            this.ctx.textAlign = "center";
            this.ctx.textBaseline = "middle";
            this.ctx.strokeStyle = "black";
            this.ctx.fillStyle = "black";
            this.ctx.lineWidth = 0.75;
            (this.ctx as any).textPath(edge.data.linkText ? edge.data.linkText : "--#relationship not defined#--", normalizePath(point1, point2));
        } catch (e) {
            console.log("drawedges error", e);
        }
    }

    drawNodes(node, point) {
        try {
            let data = node.data.node;
            let width = updateNodeSize(this.ctx, data, this.graphState.fontSize);
            let height = this.graphState.fontSize;
            this.ctx.drawImage(data.svgImage, point.x - width * 2 / 3, point.y - 2 * height);
        } catch (e) {

            console.log("drawnodes error", e);
        }
    }

    redraw() {
        try {
            this.ctx.fillStyle = "white";
            this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

            this.particleSystem.eachEdge((edge, pt1, pt2) => {
                this.drawEdges(edge, pt1, pt2);
            });

            this.particleSystem.eachNode((node, point) => {
                this.drawNodes(node, point);
            });
            this.dragCreateService.draw(this.ctx);
        } catch (error) {
            console.error("redraw error", error);
        }
    }

    getMousePositionOnArbor(event) {
        return this.arborJS.Point(event.pageX - this.canvas.offsetLeft, event.pageY - this.canvas.offsetTop);
    }
}

@Component({
    selector: "xo-graph",
    templateUrl: "./graph.component.html"
})
export class GraphComponent implements AfterViewInit {

    @Input() graphState: GraphState;

    @ViewChild("viewport", { static: false }) canvasRef: ElementRef;
    ctx: CanvasRenderingContext2D;

    sys: any;

    fromTopPositon;
    fromLeftPosition;

    constructor() {

    }

    ngAfterViewInit() {
        this.setUpSystem();
    }

    setUpSystem() {
        this.ctx = this.canvasRef.nativeElement.getContext("2d");
        this.sys = arbor.ParticleSystem(100, 10, 0.5);
        this.sys.parameters({ gravity: true });
        let renderer = new NewRenderer(arbor, this.graphState, this.canvasRef.nativeElement);
        this.sys.renderer = renderer;
        this.graphState.setContext(this.ctx, this.sys);
        this.canvasRef.nativeElement.addEventListener("mousemove", this.logMousePosition.bind(this));
    }

    logMousePosition(e) {
        this.fromLeftPosition = e.x + 5;
        this.fromTopPositon = e.y + 5;
    }
}
