import { Component, AfterViewInit, Input } from "@angular/core";
import { Chart } from 'chart.js';


@Component({
  selector: "xo-spider",
  templateUrl: "./spider.component.html"
})
export class SpiderComponent implements AfterViewInit {

  @Input() data: any;
  @Input() options: any = {};
  @Input() width: number = 1000;
  @Input() height: number = 400;

  static id: number = 0;
  id: number;

  constructor() {
    SpiderComponent.id++;
    this.id = SpiderComponent.id;
  }

  ngAfterViewInit() {
    let canvas: HTMLCanvasElement = document.getElementById('myChart' + this.id) as HTMLCanvasElement;
    let ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    let options = Object.assign({}, {
      elements: {
        line: {
          tension: 0,
          borderWidth: 3
        }
      },
    }, this.options);
    new Chart(ctx, {
      type: 'radar',
      data: this.data,
      options
    })
  }
}
