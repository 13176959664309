import { Component, Input } from "@angular/core";
import { ValidationMessage } from '../model/ValidationMessage';

@Component({
    templateUrl: "./messages.component.html",
    styleUrls: ["./messages.component.scss"],
    selector: "xo-messages"
})
export class MessagesComponent {

    @Input() fieldName?;

    @Input() validationMessages: ValidationMessage[];
}
