import { DataService, CachingDataService, Transformator, ApiClient, transformTimestampToServer, transformDateFromServer } from "@xo/services";
import { AuditLog } from "./AuditLog";
import { AuditLogUrlService } from "./auditlog.url.service";

export class AuditLogTransformator extends Transformator<AuditLog> {

    async transformFromServer(rawAuditLog: any, localAuditLog?: AuditLog): Promise<AuditLog> {
        let result: AuditLog = localAuditLog ? localAuditLog : new AuditLog();
        result = Object.assign(result, rawAuditLog);
        result.timestamp = transformDateFromServer(rawAuditLog.timestamp);
        return result;
    }

    transformToServer(auditLog: AuditLog): any {
        let result: any = Object.assign({}, auditLog);
        result.timestamp = transformTimestampToServer(auditLog.timestamp);
        return result;
    }
}

export class AuditLogService extends DataService<AuditLog> {

    constructor(http: ApiClient, auditLogUrlService: AuditLogUrlService, auditLogTransformator: AuditLogTransformator) {
        super(http, auditLogUrlService, auditLogTransformator, "id");
    }
}

export class CachingAuditLogService extends CachingDataService<AuditLog> {

    constructor(http: ApiClient, auditLogUrlService: AuditLogUrlService, auditLogTransformator: AuditLogTransformator) {
        super(false, http, auditLogUrlService, auditLogTransformator, "id");
    }
}

