import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserUrlService } from "./user.url.service";
import { DataService, Transformator } from '@xo/services';
import { User } from "./User";
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from '../../../services/config.service';

@Injectable()
export class UserTransformator extends Transformator<User>{

    constructor() {
        super();
    }

    async transformFromServer(rawUser: any): Promise<User> {
        let result: any = Object.assign({}, rawUser);
        result.registration = new Date(rawUser.registration);
        return result;
    }

    transformToServer(user: User): any {
        let result: any = Object.assign({}, user);
        return result;
    }
}


@Injectable()
export class UserService extends DataService<User> {

    static passwordChangeEmailSent = "Jelszava megváltoztatásához szükséges információkat elküldtük emailben.";
    static passwordChangeEmailError = "Hiba történt jelszava megváltoztatása során, kérem próbálja később.";
    constructor(http: HttpClient, protected userUrlService: UserUrlService, userTransformator: UserTransformator, private toastr: ToastrService, private configService: ConfigService) {
        super(http, userUrlService, userTransformator, "id");
    }

    changePassword(username: any): any {
        this.http.post(this.userUrlService.getChangePassWordUrl(), { username: username }, { withCredentials: true }).toPromise().then((response) => {
            if (response == "OK") {
                this.toastr.info(UserService.passwordChangeEmailSent)
            } else {
                this.toastr.error(UserService.passwordChangeEmailError);
            }
        })
    }

    async save(user, exists?): Promise<User> {
        return this.http.put(this.configService.config.authenticationUrl + "/Config/User/" + user.id, { name: user.name }, { withCredentials: true }).toPromise() as any;
    }
}
