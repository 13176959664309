import { Component, Input } from "@angular/core";
import { XOField } from "./xo-field.component";

@Component({
  selector: "xo-field-boolean-radio",
  templateUrl: "./xo-field-boolean-radio.component.html"
})
export class XOFieldBooleanRadio extends XOField {
  @Input()
  trueLabel: string;
  @Input()
  falseLabel: string;
}
