import { ComponentService } from './../../services/component.service';
import { TabComponent } from './tab.component';
import { IModalDialog, IModalDialogOptions, IModalDialogButton } from "@preeco-privacy/ngx-modal-dialog";
import { ComponentRef, Component, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { ModalService } from '../../window/modal-window/modal.service';
import { ConfigService } from "../../services/config.service";

const ESCAPE = 27;

@Component({
  selector: "xo-modal-my-tab",
  templateUrl: "./tab.component.html",
  styleUrls: ["./tab.component.scss"]
})
export class ModalTabComponent extends TabComponent implements IModalDialog {

  protected dialogOptions: Partial<IModalDialogOptions<any>>;
  protected dialogReference: ComponentRef<IModalDialog>;

  actionButtons: IModalDialogButton[];

  constructor(private modalService: ModalService, componentService: ComponentService, location: Location, configService: ConfigService) {
    super(componentService, location, configService);
  }

  dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
    console.log("modaltab dialoginit", options)
    this.dialogOptions = options;
    this.dialogReference = reference;
    this.template = options.data.component;
    this.system = options.data.system;
    this.active = true;
    this.initActionButtons();
  }

  getService(typeName: string) {
    return this.system.services.get(typeName);
  }

  initActionButtons() {
    this.actionButtons = !!this.dialogOptions.data.data && !!this.dialogOptions.data.data.actionButtons ? this.dialogOptions.data.data.actionButtons : [];
    if (this.dialogOptions.data.allowEmpty) {
      this.actionButtons.push({ text: "Ürít", onAction: () => { this.dialogOptions.data.resolve(null); return true; } });
    }
    if (this.dialogOptions.data.allowIsNull) {
      this.actionButtons.push({ text: "Üres", onAction: () => { this.dialogOptions.data.resolve("_NULL_"); return true; } })
    }
    if (this.dialogOptions.data.allowNotNull) {
      this.actionButtons.push({ text: "Bármi", onAction: () => { this.dialogOptions.data.resolve("_NOT_NULL_"); return true; } })
    }
    if (!this.dialogOptions.data.disableCancel) {
      this.actionButtons.push({ text: "Mégsem", onAction: () => { this.dialogOptions.data.resolve(this.dialogOptions.data.oldId); return true; } });
    }
  }

  async addComponent() {
    if (await super.addComponent()) {
      return true;
    }

    if (this.dialogOptions) {
      (this.component as any as IModalDialog).dialogInit(this.dialogReference, this.dialogOptions);
      if (!!this.dialogOptions.data.data) {
        this.dialogOptions.data.data.componentInstance = this.component;
      }
    }
    return false;
  }

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === ESCAPE || event.key === "Escape" || event.code === "Escape") {
      if (!!this.dialogOptions["reject"]) {
        this.dialogOptions["reject"]();
      }
      this.modalService.closeAnyExistingModalDialog();
      event.stopPropagation();
      event.preventDefault();
    }
  }

}
