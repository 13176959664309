import { Input, Component } from '@angular/core';

@Component({
    selector: "[xo-table-filter-link]",
    templateUrl: "./table-filter-link.html",
    styleUrls: []
})
export class TableFilterLink {

    @Input() fieldName;
    @Input() parent;
    @Input() selectFunction;

}
