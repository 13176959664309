import { Component, Input } from "@angular/core";
import { upperFirstChar } from '../../utils';
import { XOField } from './xo-field.component';

@Component({
    selector: "xo-field-select",
    templateUrl: "./xo-field-select.component.html"
})
export class XOFieldSelect extends XOField {
    @Input() Type;

    getKeys() {
        return this.Type[this.fieldName + 'Keys'];
    }

    getLabel(key) {
        return this.Type["get" + upperFirstChar(this.fieldName) + "Values"](key);
    }
}
