import { SummaryTableComponent } from "./summary.table.component";
import { ColumnInfo } from '@xo/services';
import { Directive } from "@angular/core";
import { TabComponent } from '../../tab/tab.component';

@Directive()
export abstract class PagingTableComponent<TYPE> extends SummaryTableComponent<TYPE> {

    public pageIndex = 1;

    total: any;

    loading: boolean = true;

    allRecordsShown: boolean = false;

    constructor(typeName: string, columns: ColumnInfo[] = [], protected tab?: TabComponent) {
        super(typeName, columns, tab);
    }

    // tslint:disable-next-line: use-life-cycle-interface
    ngOnInit(): void {
        this.setPage();
        super.ngOnInit();
    }

    async refreshList(addPaging: boolean = true) {
        this.loading = true;
        if (addPaging) {
            this.reAddPaging();
        }
        let response = await this.dataService.list(this.getFilters(), this.id);
        this.objects = response["page"];
        this.total = response["total"];
        this.loading = false;
        this.updateSummaryInfo();
    }

    setPage() {
        this.filters["_page"] = this.pageIndex;
    }

    pageRight() {
        this.pageIndex++;
        this.setPage();
        this.refreshList(false);
    }

    pageLeft() {
        this.pageIndex--;
        this.setPage();
        this.refreshList(false);
    }

    allowLeftButton() {
        return this.pageIndex > 1;
    }

    allowRightButton() {
        return !!this.objects && this.objects.length === (this.filters["_page_records"] || 10);
    }

    showAllRecords() {
        if (this.total > 1000 && !confirm("Több mint 1000 rekordot készül megjelení­teni, biztos akarja?")) {
            return;
        }
        delete this.filters["_page"];
        this.refreshList(false);
        this.allRecordsShown = true;
    }

    reAddPaging() {
        this.pageIndex = 1;
        this.filters["_page"] = "1";
        this.allRecordsShown = false;
    }
}
