import { KeyValueDiffers, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { modalEmitter, LinkService, GraphState, errorHandler } from "@xo/client-common";
import { Task } from "./Task";
import { TaskUrlService } from "./task.url.service";
import {
    Statuses, STATUS_READY, STATUS_IN_PROGRESS, TaskSubTaskStatusMap,
    SUBTASK_STATUS_IN_PROGRESS, STATUS_CLOSED, STATUS_DONE, STATUS_ON_HOLD
} from '../common/model/Status';
import { types, PROJECT, TASK, BUG, COMPONENT, FEATURE, SUBTASK, Type, complexTypes } from '../common/model/Type';
import { ElementService, TreeState, CachingDataService, Node, CHILD_CREATION_BASIC, Transformator, ApiClient } from "@xo/services";
import { taskChanged } from '../overview/services/changes.service';
import { CachingGroupService } from '../overview/group/component/group.service';
import { Config } from '../config/Config';

export interface TaskChange {
    task: Task;
    fieldName: string;
    newValue: any;
    oldValue: any;
}

@Injectable()
export class TaskElementTransformator extends Transformator<Task> {

    public differs: KeyValueDiffers;
    public service: TaskElementService;
    public groupService: CachingGroupService;

    transformToServer(task: Task): any {
        let result: any = Object.assign({}, {
            code: task.code,
            brief: task.brief,
            description: task.description,
            childIndex: task.childIndex ? task.childIndex : 0,
            context: task.context,
            estimation: task.estimation,
            priority: task.priority,
            assignee: task.assignee,
            isTemplate: task.isTemplate
        });

        if (task.status) {
            result.status = task.status.id;
        }
        if (task.parentCode) {
            result.parent = {
                code: task.parentCode,
            };
        }
        if (task.type) {
            result.type = task.type.code;
        }
        if (!!task.deadline) {
            result.deadline = task.deadline;
        }

        return result;
    }

    async transformFromServer(rawTask: any, localTask: Task): Promise<Task> {
        let result: Task;
        if (!!localTask) {
            result = localTask;
        } else {
            result = new Task();
        }
        Object.assign(result, rawTask);

        if (rawTask.XO_DELETED) {
            result.XO_differ = this.differs.find(result).create();
            return result;
        }

        result.type = types[rawTask.type];
        result.status = Statuses[rawTask.status];
        result.parentCode = rawTask.parent ? rawTask.parent.code : null;
        result.parentLabel = rawTask.parent ? rawTask.parent.label : null;
        if (rawTask.deadline) {
            result.deadline = new Date(rawTask.deadline);
        }
        if (!!rawTask.assignee && !!rawTask.assignee.id) {
            result.assignee = await this.groupService.load(rawTask.assignee.id);
        } else {
            result.assignee = null;
        }
        result.created = new Date(rawTask.created);
        result.XO_service = this.service as any;
        result.XO_parent = rawTask.parent ? this.service.dataService.get(rawTask.parent.code) : null;
        result.XO_differ = this.differs.find(result).create();
        return result;
    }

}

export class CachingTaskService extends CachingDataService<Task> {

    constructor(http: HttpClient, protected taskUrlService: TaskUrlService, taskTransformator: TaskElementTransformator) {
        super(false, http, taskUrlService, taskTransformator, "code");
    }

    createAllTasks(payload) {
        return this.http.post(this.taskUrlService.getMassCreateUrl(payload.taskCode), payload, { withCredentials: true })
            .toPromise()
            .catch(errorHandler);
    }

    getFeedTasks(params) {
        return this.http.get(this.taskUrlService.createFeedUrl(params), { withCredentials: true })
            .toPromise()
            .catch(errorHandler);
    }

    export(taskCode: string, type: string) {
        (window as any).location = this.taskUrlService.getExportUrl(taskCode, type);
    }
}


export class TaskElementService extends ElementService<Task> {

    public childCreation: string = CHILD_CREATION_BASIC;
    public groupService: CachingGroupService;
    public linkService?: LinkService;

    public treeState?: TreeState;
    public graphState: GraphState;

    constructor(public dataService: CachingDataService<Task>, public differ: KeyValueDiffers,
        public http: HttpClient, private taskUrlService: TaskUrlService,) {
        super(http as any as ApiClient, taskUrlService, dataService.transformator, "code");
        (this.dataService.transformator as TaskElementTransformator).differs = this.differ;
        (this.dataService.transformator as TaskElementTransformator).service = this;
        this.subscribe();
    }

    create() {
        let task = new Task();
        task.XO_service = this as any;
        task.XO_differ = (this.dataService.transformator as TaskElementTransformator).differs.find(task).create();
        return task;
    }

    changed(task, changes) {
        changes.forEachChangedItem(change => {
            taskChanged.emit(this.createTaskChange(change, task));
        })
    };

    createTaskChange(change: any, task: Task): any {
        let taskChange: TaskChange = {
            task: task,
            fieldName: change.key,
            newValue: change.currentValue,
            oldValue: change.previousValue
        };
        return taskChange;
    }

    getClasses(task: Task) {
        let classes = {
            type: task.type.code,
            status: task.status.class,
            priority: task.priority,
        };
        return classes;
    }

    applyTemplateToTask(task: Task, templateCode: string) {
        return this.http.post(this.taskUrlService.getTemplateUrl(task.code, templateCode), {}, { withCredentials: true }).toPromise();
    }

    async swapChildren(parentTask: Task, childIndex1: number, childIndex2: number) {
        await this.dataService.save({ code: parentTask.subtasks[childIndex1].code, childIndex: childIndex2 });
        await this.dataService.save({ code: parentTask.subtasks[childIndex2].code, childIndex: childIndex1 });
        return parentTask.XO_service.updateChildren(parentTask);
    }

    skippedFields: string[] = [
        "activeChildIndex",
        "subtasks",
        "worklogs",
        "statusChanges",
        "comments",
        "showSubTasks",
        "matched",
        "hidden",
        "active",
        "moving"
    ];

    private skipFieldCheck(fieldName: string) {
        return this.skippedFields.indexOf(fieldName) >= 0;
    }

    private getFieldValue(fieldName: string, value: any) {
        if (fieldName === "assignee") {
            return {
                id: value.id
            };
        }
        return value;
    }


    private previousSave: Promise<any>;
    private subscribe() {
        taskChanged.subscribe(async (change: TaskChange) => {
            if (this.skipFieldCheck(change.fieldName)) {
                return;
            }
            let payLoad = {
                code: change.task.code
            };
            if (change.fieldName === "_type") {
                payLoad['type'] = change.newValue;
            } else if (change.fieldName === "deadline") {
                payLoad['deadline'] = change.newValue;
            } else {
                payLoad[change.fieldName] = this.getFieldValue(change.fieldName, change.newValue);
            }
            if (!!this.previousSave) {
                await this.previousSave;
            }
            if (change.fieldName == "XO_parent") {
                payLoad["parentCode"] = change.newValue.code;
                payLoad["parentLabel"] = change.newValue.brief;
            }
            this.previousSave = this.dataService.save(payLoad)
                .then((data: any) => {
                    if (change.fieldName == "status") {
                        this.http.post(this.taskUrlService.URL + "/Xodos/StatusChange",
                            {
                                oldStatus: change.oldValue.id, newStatus: change.newValue.id,
                                timestamp: new Date().toISOString().substr(0, 16),
                                task: { code: change.task.code }
                            }, { withCredentials: true }).toPromise().then(r => {
                                console.log(r);
                            })
                        if (this.treeState.config.worklogScreenOnStatusChange) {
                            modalEmitter.emit({
                                component: "Xodos/WorklogComponent",
                                data: { task: this.dataService.get(change.task.code), comment: `Tasks status changed from ${data.oldStatus} to ${data.newStatus}. ` }
                            });
                        }
                    }
                    this.previousSave = null;
                })
                .catch((error) => {
                    console.log("Error", error);
                    this.previousSave = null;
                });
        });
    }

    getRootElements() {
        return this.dataService.list({ params: { parent: null, isTemplate: false } }, "getRootElements", true).then((response) => {
            return response.page;
        });
    }

    async updateChildren(parentTask: Task) {
        parentTask.subtasks = (await this.dataService.list({ params: { parent: parentTask.code }, }, "getChildren" + parentTask.code, true)).page.sort(this.sortByChildIndex);
        if (!!this.linkService) {
            parentTask.subtasks.forEach((task) => {
                this.linkService.createLink(parentTask.XO_type, parentTask.code, task.XO_type, task.code, "subtask");
            });
        }
    }

    sortByChildIndex(task1, task2) {
        if (task1.childIndex < task2.childIndex) {
            return -1;
        }
        if (task1.childIndex > task2.childIndex) {
            return 1;
        }
        return 0;
    }

    getChildren(parentTask: Task) {
        return parentTask.subtasks;
    }

    async createChild(parentTask: Task, type: any = "task", typeReduced = false) {
        let newChild = new Task();
        newChild.parentCode = parentTask.code;
        newChild.brief = "";
        newChild.childIndex = parentTask.subtasks.length;
        if (typeReduced) {
            newChild.type = this.typeReducer(parentTask.type);
        }
        if (parentTask.status === Statuses[STATUS_READY]) {
            parentTask.status = Statuses[STATUS_IN_PROGRESS];
        }
        if (parentTask.status === TaskSubTaskStatusMap[STATUS_READY]) {
            parentTask.status = Statuses[SUBTASK_STATUS_IN_PROGRESS];
        }
        if (parentTask.assignee) {
            newChild.assignee = parentTask.assignee;
        }
        let child = await this.dataService.save(newChild);
        return child;
    }

    typeReducer(type: Type) {
        if (type.code == PROJECT) {
            return types[COMPONENT];
        }
        if (type.code == COMPONENT) {
            return types[FEATURE];
        }
        if (type.code == FEATURE) {
            return types[TASK];
        }
        if (type.code == TASK) {
            return types[SUBTASK];
        }
        return type;
    }


    async setParent(child, parent, childIndex) {
        await this.getChildren(parent)
            .filter(child => child.childIndex >= childIndex)
            .map(child => {
                return this.dataService.save({ code: child.code, childIndex: child.childIndex + 1 });
            });

        await this.dataService.save({ code: child.code, parentCode: parent.code, childIndex: childIndex });
    }

    async setNextStatus(state: TreeState) {
        let task = state.activeElement as Task;
        let nextStatus = task.type.nextStatus(task.status);
        if (task.type.nextStatus(nextStatus) == nextStatus) {
            if (!confirm("Biztosan lezárt státuszba helyezeti ezt a task-ot?")) {
                return;
            }
        }

        task.status = nextStatus;
        await this.dataService.save(task);
        if (!!task.XO_parent) {
            await task.XO_parent.XO_service.childChanged(state, task, "status");
        }
    }

    escalateReadyStatus(task: Task) {
        let parent = task.XO_parent as Task;
        if (task.type.code == PROJECT || !parent || !this.isAllReady(parent)) {
            return;
        }
        if ([types[PROJECT].code, types[TASK].code, types[BUG].code].indexOf(parent.type.code) >= 0) {
            parent.status = Statuses[STATUS_READY];
        } else {
            parent.status = TaskSubTaskStatusMap[STATUS_READY];
        }
        return this.dataService.save(parent);
    }

    escalateDoneStatus(task: Task) {
        let parent = task.XO_parent as Task;
        if (task.type.code == PROJECT || !parent || !this.isAllDone(parent)) {
            return;
        }
        if ([types[PROJECT].code, types[TASK].code, types[BUG].code].indexOf(parent.type.code) >= 0) {
            parent.status = Statuses[STATUS_DONE];
        } else {
            parent.status = TaskSubTaskStatusMap[STATUS_DONE];
        }
        return this.dataService.save(parent);
    }

    escalateClosedStatus(task: Task) {
        let parent = task.XO_parent as Task;
        if (task.type.code == PROJECT || !parent || !this.isAllClosed(parent)) {
            return;
        }
        if ([types[PROJECT].code, types[TASK].code, types[BUG].code].indexOf(parent.type.code) >= 0) {
            parent.status = Statuses[STATUS_CLOSED];
        } else {
            parent.status = TaskSubTaskStatusMap[STATUS_CLOSED];
        }
        return this.dataService.save(parent);
    }

    escaleOnHoldStatus(task: Task) {
        let parent = task.XO_parent as Task;
        if (!parent || task.type.code == PROJECT || (task.status != Statuses[STATUS_ON_HOLD] && task.status != TaskSubTaskStatusMap[STATUS_ON_HOLD])) {
            return;
        }
        if ([types[PROJECT].code, types[TASK].code, types[BUG].code].indexOf(parent.type.code) >= 0) {
            parent.status = Statuses[STATUS_ON_HOLD];
        } else {
            parent.status = TaskSubTaskStatusMap[STATUS_ON_HOLD];
        }
        return this.dataService.save(parent);
    }

    childChanged(state: TreeState, task: Task, fieldName: string) {
        if (fieldName == "status") {
            if ((state.config as Config).escalateReadyStatus) {
                this.escalateReadyStatus(task);
            }
            if ((state.config as Config).escalateDoneStatus) {
                this.escalateDoneStatus(task);
            }
            if ((state.config as Config).escalateClosedStatus) {
                this.escalateClosedStatus(task);
            }
            if ((state.config as Config).escalateOnHoldStatus) {
                this.escaleOnHoldStatus(task);
            }
        }
    }

    async createParent(element) {
        let oldParent = element.XO_parent;
        if (!oldParent) {
            return;
        }
        let newParent = await oldParent.service.createChild(oldParent);
        element.parentCode = newParent.code;
        setTimeout(() => {
            oldParent.service.updateChildren(oldParent);
            newParent.service.updateChildren(newParent);
        }, 0);
        return newParent;
    }

    async setPreviousStatus(state: TreeState) {
        let task = state.activeElement as Task;
        task.status = task.type.prevStatus(task.status);
        await this.dataService.save(task);
        if (!!task.XO_parent) {
            await task.XO_parent.XO_service.childChanged(state, task, "status");
        }
    }

    async setStatusToOnHold(state: TreeState) {
        let task = state.activeElement as Task;
        task.status = complexTypes.indexOf(task.type.code) >= 0 ? Statuses[STATUS_ON_HOLD] : TaskSubTaskStatusMap[STATUS_ON_HOLD];
        await this.dataService.save(task);
        if (!!task.XO_parent) {
            await task.XO_parent.XO_service.childChanged(state, task, "status");
        }
    }

    private isAllDone(parentTask: Task): boolean {
        return this.isAllChildrenInStatus(parentTask, STATUS_DONE);
    }

    private isAllReady(parentTask: Task): boolean {
        return this.isAllChildrenInStatus(parentTask, STATUS_READY);
    }

    private isAllClosed(parentTask: Task): boolean {
        return this.isAllChildrenInStatus(parentTask, STATUS_CLOSED);
    }

    private isAllChildrenInStatus(task: Task, status: string) {
        let subtasks = task.subtasks;
        for (let i = 0; i < subtasks.length; i++) {
            if (subtasks[i].status !== Statuses[status] && subtasks[i].status !== TaskSubTaskStatusMap[status]) {
                return false;
            }
        }
        return true;
    }

    getChildComponent(element): string {
        return "Xodos/CreateTaskNodeComponent";
    };
    getAltChildComponent(element): string {
        return "Xodos/CreateTaskNodeComponent";
    };

    getChildType() {
        return "task";
    }

    getAltChildType() {
        return "task";
    }

     /*  async */ getParents(element: Node) {
        return [];
        // return element["parents"];
        // element.XO_service["list"]({params: {parent: element.id}}, "getparents" + element.id)
    }
    //GRAPH STUFF

    async addChild(parent: Task, child: Task, relationshipName: string) {
        if (child.parentCode) {
            let oldParent = await this.dataService.load(child.parentCode) as Node;
            this.graphState.removeLink(oldParent, child);
        }
        child.parentCode = parent.code;
        child.parentLabel = parent.brief;
        if (!!this.linkService) {
            this.linkService.createLink(parent.XO_type, parent.code, child.XO_type, child.code, relationshipName);
        }
        child = await this.dataService.save(child);
        return Promise.resolve(child);
    }

    getChildrenType(): any[] {
        return [
            { module: "Xodos", type: "Task", label: "Api", parentField: "module", componentCode: "CreateSimpleApiComponent" },
            { module: "Xodos", type: "Component", label: "Tí­pus", parentField: "module", componentCode: "CreateSimpleTypeComponent" },
        ]
    }

}
