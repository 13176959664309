import { Injectable, ComponentFactoryResolver, ViewContainerRef } from "@angular/core";
import { TabComp } from "../components/tab/TabComp";

@Injectable()
export class ComponentService {

    public menuItems = new Map<string, any>();

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    }

    async createComponent(parentComponent: ViewContainerRef, componentCode: string, dataContext): Promise<TabComp> {
        let componentClass = this.getComponent(componentCode);
        console.log("creating", componentCode);
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
        let component = parentComponent.createComponent(componentFactory).instance as TabComp;
        await component.init(dataContext);

        return component;
    }


    registerModule(moduleName, modules: any) {
        Object.keys(modules).map(moduleItem => {
            this.menuItems.set(moduleName + "/" + moduleItem, modules[moduleItem]);
        });
    }

    getComponent(componentString: string) {
        let result = this.menuItems.get(componentString);
        return result;
    }
}
