import { Component, Input, ElementRef, OnInit, Optional } from "@angular/core";
import { openTab } from '../tabs/tab.control';
import { TabComponent } from '../tab/tab.component';
import { ModalTabComponent } from '../tab/modal-tab.component';
import { DeckTabComponent } from '../deck/deck-tab.component';

@Component({
    selector: "[xo-table-data-link]",
    templateUrl: "./table-data-link.html"
})
export class TableDataLink implements OnInit {
    @Input() object;
    @Input() fieldName: string;
    @Input() labelField = "label";
    @Input() componentName: string;
    @Input() disabled: boolean = false;
    @Input() map?: (label: any) => string = label => label;

    constructor(private elRef: ElementRef, @Optional() private modalTab: ModalTabComponent, @Optional() private tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
        this.tab = this.tab || deckTab;
    }

    ngOnInit() {
        this.elRef.nativeElement.classList.add(this.fieldName);
    }

    openTab(event) {
        if (this.disabled) {
            return;
        }
        if (!!this.componentName) {
            let field = this.object[this.fieldName];
            if (!!field) {
                let tab = { label: field.label, component: this.componentName, system: !!this.tab ? this.tab.system : this.modalTab.system, deck: (this.tab as any)?.tab?.deck, data: field };
                openTab.emit(tab);
            }
        }
        event.stopPropagation();
    }
}
