import { Component, Input } from "@angular/core";
import { TabService } from "../xo-tab/tabs.service";

@Component({
    selector: "xo-tile",
    templateUrl: "./tile.component.html",
    styleUrls: ["./tile.component.scss"]
})
export class XoTileComponent {

    @Input() title?: string;
    @Input() closable?: boolean = true;
    @Input() closed? = false;

    constructor(public tabService: TabService) {
    }

    toggleTile() {
        if (!this.closable) {
            return;
        }
        this.closed = !this.closed;
    }
}
