import { Component, Input, ViewContainerRef, QueryList, ViewChildren, AfterViewInit } from "@angular/core";
import { ComponentService } from "../../services/component.service";
import { Location } from '@angular/common';
import { ConfigService } from "../../services/config.service";
import { System } from '../tabs/System';
import { TabComp } from "./TabComp";


@Component({
    selector: "xo-my-tab",
    templateUrl: "./tab.component.html",
    styleUrls: ["./tab.component.scss"]
})

export class TabComponent implements AfterViewInit {

    @Input() title: string;
    @Input() active = false;
    @Input() isCloseable = false;
    @Input() contentProvided = false;
    @Input() template: string;
    @Input() dataContext;
    component: TabComp;

    system: System;
    deck: any; //TODO Deck
    saveObject: (any) => any;

    @ViewChildren('content', { read: ViewContainerRef }) tabContents: QueryList<ViewContainerRef>;

    constructor(public componentService: ComponentService, public location: Location, public configService: ConfigService) { }

    ngAfterViewInit() {
        if (!this.contentProvided) {
            if (this.tabContents.length > 0) {
                this.addComponent();
            };

            this.tabContents.changes.subscribe(() => {
                this.addComponent();
            });
        }
    }

    protected async addComponent() {
        let tabContent = this.tabContents.first;
        if (!tabContent) {
            return true;
        }
        console.log("addComponent", this.template)
        this.component = await this.componentService.createComponent(tabContent, this.template, this.dataContext);
        if (!this.configService.config.disableParameterHashing && !!this.component.parameters) {
            this.component.parameters.subscribe(parameters => {
                let serializedParameters = JSON.stringify(parameters);
                let newUrl = `/main/${this.system.name}/${this.template}/${encodeURIComponent(this.title)}` + (parameters ? `/${encodeURIComponent(serializedParameters)}` : "");
                this.location.go(newUrl);
            });
        }
        return false;
    }

    getService(typeName: string) {
        return this.system.services.get(typeName);
    }
}

