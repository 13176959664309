import { Component, Input, OnInit } from "@angular/core";
import { Deck } from "./deck";
import { DeckService } from "./deck.service";
import { DeckContainerService } from "./deck-container.service";

@Component({
  selector: "xo-deck",
  templateUrl: "./deck.component.html",
  styleUrls: ["./deck.component.scss"],
})
export class DeckComponent implements OnInit {

  @Input()
  deck: Deck;

  @Input()
  public deckContainerService: DeckContainerService;

  public deckService: DeckService;

  constructor() {
  }

  ngOnInit(): void {
    this.deckService = this.deck.deckService;
  }

  getClasses() {
    return this.deckService.deckState == "deck" ? "deck-container" : "spread-container";
  }

  setActiveTab(tab: any) {
    this.deckService.setActiveTab(tab);
  }
}
