import { Input, Component } from '@angular/core';

@Component({
    selector: "[xo-table-filter-select]",
    templateUrl: "./table-filter-select.html",
    styleUrls: []
})
export class TableFilterSelect {

    @Input() fieldName;
    @Input() parent;
    @Input() selectValues;

    @Input() defaultKey? = "id";
    @Input() defaultLabel? = "label";

}