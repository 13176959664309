import { ObjectReference, Node, ColumnInfo } from "@xo/services";
import { UserReference } from '../user/User';

export class AuditLogReference extends ObjectReference {
    constructor(id?: number, label?: string) {
        super(id, label, "id");
    }
}

export let AuditLogType = "AuditLog";

export class AuditLog extends Node {

    constructor() {
        super(AuditLogType, "Common/SimpleAuditLogComponent");
    }

    get label() {
        return !!this.timestamp ? this.timestamp.toISOString().substr(0, 16) : "";
    }

    set label(label) {
        this.timestamp = new Date(label);
    }

    get classes() { return "" }

    public static columnInfo = [
        new ColumnInfo("TimeStamp", "timestamp", "Date", "None"),
        new ColumnInfo("Action", "action", "string", "None"),
        new ColumnInfo("User", "user", "stringLink", "None"),
        new ColumnInfo("Type", "type", "string", "None"),
        new ColumnInfo("Identifier", "idValue", "string", "None"),
        new ColumnInfo("Field", "field", "string", "None"),
        new ColumnInfo("Old Value", "oldValue", "string", "None"),
        new ColumnInfo("New Value", "newValue", "string", "None"),
        new ColumnInfo("Succesful", "succes", "boolean", "None"),
    ];

    public id: number;
    public timestamp: Date;
    public action: string;
    public user: UserReference;
    public type: string;
    public idValue: string;
    public field: string;
    public oldValue: string;
    public newValue: string;
    public succes: boolean;
}
