import { Component, OnInit, EventEmitter } from "@angular/core";
import { MenuService } from "./menu.service";
import { openTab, closeTab, tabChange } from "../tabs/tab.control";
import { MenuItem } from "./MenuItem";

declare var $: any;

export let tabsOpenEmmitter = new EventEmitter<boolean>();
export let tabsChangedEmmitter = new EventEmitter<void>();

@Component({
  selector: "xo-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"]
})
export class MenuComponent implements OnInit {

  modules: MenuItem[] = [];

  tabsVisible = false;
  menuVisible = false;
  activeMenuTitle = "";

  constructor(private menuService: MenuService) {
  }

  async ngOnInit() {
    openTab.subscribe((menuItem) => {
      this.menuVisible = false;
      this.activeMenuTitle = menuItem.label;
    });
    tabChange.subscribe(tab => {
      this.menuVisible = false;
      this.activeMenuTitle = tab.title;
    });
    this.modules = await this.menuService.getModules();
    this.openByDefault(this.modules);
  }

  itemClicked(menuItem: MenuItem) {
    if (!!menuItem.action) {
      menuItem.action.call(null);
    } else {
      if (!!menuItem.unique) {
        closeTab.emit({ allOf: menuItem.component });
      }
      openTab.emit(menuItem);
    }
  }

  openByDefault(menuItems: MenuItem[]) {
    menuItems.forEach(menuItem => {
      if (menuItem.openByDefault) {
        openTab.emit(menuItem);
        return;
      }
      if (!!menuItem.items) {
        this.openByDefault(menuItem.items);
        return;
      }
    });

  }

  getClasses(moduleItem: MenuItem) {
    let classes = {
      "dropdown": true,
    };
    if (!!moduleItem.items) {
      classes["hasSubmenu"] = true;
    }
    if (!!moduleItem.cssClass) {
      classes[moduleItem.cssClass] = true;
    }
    return classes;
  }

  getMenuItemClasses(menuItem: MenuItem) {
    let classes = {};
    if (!!menuItem.cssClass) {
      classes[menuItem.cssClass] = true;
    }
    return classes;
  }

  toggleTabs() {
    this.tabsVisible = !this.tabsVisible;
    this.menuVisible = false;
    tabsOpenEmmitter.emit(this.tabsVisible);
    tabsChangedEmmitter.emit();
  }

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
    this.tabsVisible = false;
    tabsOpenEmmitter.emit(this.tabsVisible);
  }
}
