import { Injectable } from "@angular/core";
import { UrlService } from "@xo/services";
import { ConfigService } from "../../../services/config.service";

@Injectable()
export class UserUrlService extends UrlService {
    constructor(private configService: ConfigService) {
        super("User", "Common", configService.config.baseUrl);
    }

    getChangePassWordUrl() {
        return this.configService.config.authenticationUrl + "/user/recover";
    }
}
