import { XOArrayFieldString } from './components/fields/xo-field-array-string.component';
import { XOArrayFieldSelect } from './components/fields/xo-field-array-select.component';
import { XOArrayFieldRadio } from './components/fields/xo-field-array-radio.component';
import { AutofocusDirective } from './components/autofocus/AutoFocus';
import { ModalTabComponent } from './components/tab/modal-tab.component';
import { NgModule, ErrorHandler } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ConfigService } from "./services/config.service";
import { ModalDialogModule } from "@preeco-privacy/ngx-modal-dialog";
import { ComponentService } from "./services/component.service";
import { GoogleDistanceService } from "./services/google-distance.service";
import { PageService } from "./services/page.service";
import { CurrentUserContainer } from "./authentication/current-user-container.service";
import { FlowScreenComponent } from './flow-screen/flow-screen.component';
import { XoCommonComponent } from './xo-common-component';
import { XoDefaultThemeComponent } from './themes/default.theme';
import { XoFutureThemeComponent } from './themes/future.theme';
import { MainPageComponent } from './main-page/main-page.component';
import { TreeComponent } from './hierarchy/tree/tree.component';
import { GraphComponent } from './hierarchy/graph/graph.component';
import { ActiveElementComponent } from './hierarchy/element/active-element.component';
import { LinkingComponent } from './hierarchy/link/linking.component';
import { BranchComponent } from './hierarchy/branch/branch.component';
import { MessagesComponent } from './validation/messages/messages.component';
import { CreateChildComponent } from './hierarchy/create-child/create-child.component';
import { BrowserComponent } from './hierarchy/browser/browser.component';
import { RouterModule } from '@angular/router';
import { OwlNativeDateTimeModule, OwlDateTimeModule } from "ng-pick-datetime";
import { HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './components/footer/footer.component';
import { ValidatedArrayFieldComponent } from './components/validated-array-field/validated-array-field.component';
import { ValidatedFieldComponent } from './components/validated-field/validated-field.component';
import { TableSummaryComponent } from './components/table/table-component/table-summary.component';
import { TableSummarySelectorComponent } from './components/table/table-component/table-summary-selector.component';
import { MenuComponent } from './components/menu/menu.component';
import { ComponentDisplayer } from './components/component-displayer/component-displayer.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tab/tab.component';
import { ProfileUser } from './modules/common/user/profile-user.component';
import { ChangePasswordComponent } from './modules/common/user/change-password.component';
import { IncludeComponent } from './components/include/include.component';
import { XoTileComponent } from './components/tile/tile.component';
import { XoTilesComponent } from './components/tile/tiles.component';
import { XoTabComponent } from './components/xo-tab/tab';
import { XoTabsComponent } from './components/xo-tab/tabs';
import { TabService } from './components/xo-tab/tabs.service';
import { ExcelComponent } from './modules/common/excel/excel.component';
import { MessageComponent } from './modules/common/dev-message/dev-message.component';
import { Safe, NodeComponent } from './hierarchy/node/node.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { TableRowComponent } from './components/table/table-row/table-row.component';
import { TableFilterComponent } from './components/table/table-filter/table-filter.component';
import { TableHeaderComponent } from './components/table/table-header/table.header.component';
import { ModalService } from './window/modal-window/modal.service';
import { UserService, UserTransformator } from './modules/common/user/user.service';
import { UserUrlService } from './modules/common/user/user.url.service';
import { ExcelService, ExcelTransformator } from './modules/common/excel/excel.service';
import { ExcelUrlService } from './modules/common/excel/excelUrl.service';
import { MessageService, MessageTransformator } from './modules/common/dev-message/dev-message.service';
import { MessageUrlService } from './modules/common/dev-message/dev-message.url.service';
import { TableFilterInput } from './components/table/table-filter-input';
import { TableFilterLink } from './components/table/table-filter-link';
import { TableFilterSelect } from './components/table/table-filter-select';
import { TableData } from './components/table/table-data';
import { XOFieldLink } from './components/fields/xo-field-link.component';
import { XOFieldNumber } from './components/fields/xo-field-number.component';
import { XOFieldFraction } from './components/fields/xo-field-fraction.component';
import { XOFieldDate } from './components/fields/xo-field-date.component';
import { XOFieldBoolean } from './components/fields/xo-field-boolean.component';
import { XOFieldBooleanRadio } from './components/fields/xo-field-boolean-radio.component';
import { XOFieldSelect } from './components/fields/xo-field-select.component';
import { XOFieldRadio } from './components/fields/xo-field-radio.component';
import { XOFieldSelectDate } from './components/fields/xo-field-select-date.component';
import { XOFieldString } from './components/fields/xo-field-string.component';
import { XOFieldLongString } from './components/fields/xo-field-longstring.component';
import { TableDataLink } from './components/table/table-data-link';
import { TableDataCustom } from './components/table/table-data-custom';
import { TableDataString } from './components/table/table-data-string';
import { TableDataDate } from './components/table/table-data-date';
import { XOFieldTimestamp } from './components/fields/xo-field-timestamp.component';
import { TableDataTimestamp } from './components/table/table-data-timestamp';
import { SpiderComponent } from './hierarchy/spider/spider.component';
import { ChartLoader } from './hierarchy/chart/chart-loader';
import { ChartComponent } from './hierarchy/chart/chart.component';
import { RollbarErrorHandler, RollbarService } from './services/rollbar.service';
import { AuditLogListWindowComponent } from './modules/common/auditlog/auditLog.list';
import { AuditLogPage } from './modules/common/auditlog/auditLog-page.component';
import { AuditLogSelect } from './modules/common/auditlog/auditLog.select';
import { AuditLogTable } from './modules/common/auditlog/auditLog.table';
import { AuditLogComponent } from './modules/common/auditlog/auditLog.component';
import { XOArrayFieldLongString } from './components/fields/xo-field-array-longstring.component';
import { XOArrayFieldNumber } from './components/fields/xo-field-array-number.component';
import { XOArrayFieldFraction } from './components/fields/xo-field-array-fraction.component';
import { XOArrayFieldBoolean } from './components/fields/xo-field-array-boolean.component';
import { XOArrayFieldDate } from './components/fields/xo-field-array-date.component';
import { XOArrayFieldTime } from './components/fields/xo-field-array-time.component';
import { XOArrayFieldTimestamp } from './components/fields/xo-field-array-timestamp.component';
import { XOArrayFieldLink } from './components/fields/xo-field-array-link.component';
import { XOFileInput } from './components/fields/xo-file-input.component';
import { XOFieldFile } from './components/fields/xo-field-file.component';
import { FileInputValueAccessor } from './components/fields/fileobserver';
import { XOFieldTime } from './components/fields/xo-field-time.component';
import { TableDataLinkArray } from './components/table/table-data-link-array';
import { DeckContainer } from './components/deck/deck-container.component';
import { DeckComponent } from './components/deck/deck.component';
import { DeckTabComponent } from './components/deck/deck-tab.component';
import { DeckControlBar } from './components/deck/deck-controlbar.component';
import { XOFieldAILongString } from './components/fields/xo-field-ai-longstring';

export let COMMON = "Common";

export let moduleNames = {
  AuditLogListWindowComponent,
  AuditLogPage,
  AuditLogSelect,
  ExcelComponent
};


@NgModule({
  declarations: [
    FooterComponent, XoCommonComponent, XoDefaultThemeComponent, XoFutureThemeComponent,
    ValidatedArrayFieldComponent, ValidatedFieldComponent,
    TableSummaryComponent, TableSummarySelectorComponent, MainPageComponent, MenuComponent, ComponentDisplayer,
    TabsComponent, TabComponent, ModalTabComponent, FlowScreenComponent,
    ProfileUser, ChangePasswordComponent,
    AuditLogListWindowComponent, AuditLogPage, AuditLogSelect,
    AuditLogTable, AuditLogComponent,
    IncludeComponent, XoTileComponent, XoTilesComponent,
    XoTabComponent, XoTabsComponent,
    ExcelComponent,
    MessageComponent,
    AutofocusDirective,
    Safe,
    NodeComponent,
    TreeComponent,
    GraphComponent,
    ActiveElementComponent,
    LinkingComponent,
    BranchComponent,
    MessagesComponent,
    CreateChildComponent,
    BrowserComponent,
    LanguageSelectorComponent,
    TableRowComponent, TableFilterComponent, TableHeaderComponent,
    TableFilterInput, TableFilterLink, TableFilterSelect, TableData, TableDataLink, TableDataLinkArray, TableDataCustom, TableDataDate, TableDataString, TableDataTimestamp,
    XOFieldLink, XOFieldNumber, XOFieldFraction, XOFieldDate, XOFieldBoolean, XOFieldBooleanRadio, XOFieldSelect, XOFieldRadio, XOFieldSelectDate, XOFieldString, XOFieldTime, XOFieldLongString, XOFieldTimestamp, XOFieldAILongString,
    XOArrayFieldSelect, XOArrayFieldRadio, XOArrayFieldString, XOArrayFieldLongString, XOArrayFieldNumber, XOArrayFieldFraction,
    XOArrayFieldBoolean, XOArrayFieldDate, XOArrayFieldTime, XOArrayFieldTimestamp, XOArrayFieldLink,
    SpiderComponent, ChartComponent,
    XOFieldFile, XOFileInput, FileInputValueAccessor,
    DeckContainer, DeckComponent, DeckTabComponent, DeckControlBar,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ModalDialogModule.forRoot(),
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
  ],
  providers: [
    ConfigService,
    ModalService,
    ComponentService,
    GoogleDistanceService,
    PageService,
    CurrentUserContainer,
    UserService,
    UserUrlService,
    ExcelService,
    ExcelUrlService,
    MessageService,
    MessageUrlService,
    ExcelTransformator,
    MessageTransformator,
    UserTransformator,
    ChartLoader,
    RollbarService,
    TabService,
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
  ],
  exports: [
    FooterComponent, MessageComponent,
    ValidatedArrayFieldComponent, ValidatedFieldComponent,
    TableSummaryComponent, TableSummarySelectorComponent, XoCommonComponent, XoDefaultThemeComponent, XoFutureThemeComponent,
    TabsComponent, TabComponent, FlowScreenComponent, MainPageComponent, MenuComponent, ComponentDisplayer,
    AuditLogListWindowComponent, AuditLogPage, AuditLogSelect,
    AuditLogTable, AuditLogComponent,
    ProfileUser, ChangePasswordComponent,
    IncludeComponent, XoTileComponent, XoTilesComponent,
    XoTabComponent, XoTabsComponent,
    ExcelComponent,
    AutofocusDirective,
    Safe,
    NodeComponent,
    TreeComponent,
    GraphComponent,
    ActiveElementComponent,
    LinkingComponent,
    BranchComponent,
    MessagesComponent,
    CreateChildComponent,
    BrowserComponent,
    LanguageSelectorComponent,
    TableRowComponent, TableFilterComponent, TableHeaderComponent,
    TableFilterInput, TableFilterLink, TableFilterSelect, TableData, TableDataLink, TableDataLinkArray, TableDataCustom, TableDataDate, TableDataString, TableDataTimestamp,
    XOFieldLink, XOFieldNumber, XOFieldFraction, XOFieldDate, XOFieldBoolean, XOFieldBooleanRadio, XOFieldSelect, XOFieldRadio, XOFieldSelectDate, XOFieldString, XOFieldTime, XOFieldLongString, XOFieldTimestamp, XOFieldAILongString,
    XOArrayFieldSelect, XOArrayFieldRadio, XOArrayFieldString, XOArrayFieldLongString, XOArrayFieldNumber, XOArrayFieldFraction,
    XOArrayFieldBoolean, XOArrayFieldDate, XOArrayFieldTime, XOArrayFieldTimestamp, XOArrayFieldLink,
    SpiderComponent, ChartComponent,
    XOFieldFile, XOFileInput, FileInputValueAccessor,
    DeckContainer, DeckComponent, DeckTabComponent, DeckControlBar,
  ],
  entryComponents: [MessageComponent,
    TabComponent, FlowScreenComponent, XoCommonComponent, MainPageComponent, MenuComponent, ComponentDisplayer,
    AuditLogListWindowComponent, AuditLogPage, AuditLogSelect,
    AuditLogTable, AuditLogComponent,
    ProfileUser, ChangePasswordComponent,
    IncludeComponent, ExcelComponent,
    NodeComponent,
    TreeComponent,
    GraphComponent,
    ActiveElementComponent,
    LinkingComponent,
    MessagesComponent,
    CreateChildComponent,
    BrowserComponent,
    LanguageSelectorComponent,
    ChartComponent,
    ModalTabComponent,
  ]
})
export class XoCommonModule { }
