import { Component } from "@angular/core";

@Component({
  selector: "xo-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent {

  public FooterComponent = FooterComponent;

  static userguide = "Felhasználói kézikönyv";
  static terms = "Felhasználási feltételek";
  static company = "XO-Extreme Organisation Kft.";
  static copyright = "All rights reserved 2018-2023";
  static copyrightLink = "http://xo-core.com";
  static gdpr = "Adatkezelési Tájékoztató";
  static impressum = "Impresszum";
}
