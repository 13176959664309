import { Injectable } from "@angular/core";
import { ConfigService } from "../../../services/config.service";
import { UrlService } from "@xo/services";

@Injectable()
export class ExcelUrlService extends UrlService {
    constructor(configService: ConfigService) {
        super("", "", configService.config.baseUrl);
    }

    setModuleName(module: string) {
        this.moduleName = module;
    }

    setTypeName(type: string) {
        this.typeName = type;
    }

    getExcelUrl() {
        if (!!this.moduleName && !!this.typeName) {
            return `${this.URL}/${this.moduleName}/${this.typeName}.xlsx`;
        }
        if (!!this.moduleName && !this.typeName) {
            return `${this.URL}/${this.moduleName}.xlsx`;
        }
        return `${this.URL}/xo.xlsx`;
    }
}
