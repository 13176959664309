import { OnInit, Input, Directive } from "@angular/core";
import { DataService, ColumnInfo } from "@xo/services";
import { TabComponent } from '../../tab/tab.component';

export class FilterObject extends Object {
}



@Directive()
export abstract class TableComponent<TYPE> implements OnInit {

    protected dataService: DataService<TYPE>;

    constructor(typeName: string, columns: ColumnInfo[], protected tab?: TabComponent) {
        this.dataService = tab.getService(typeName);
        this.updateColumns(columns);
    }

    static counter: number = 0;

    @Input() clickHandler: (x?: any) => any;
    @Input() filters?: FilterObject = {};
    @Input() allowCreate?: boolean = false;

    objects: TYPE[] = [];
    originalFilters: any;

    id: string = "TableComponent" + TableComponent.counter++;

    public columns: any; // fieldName : ColumnInfo
    updateColumns(columns) {
        this.columns = columns.reduce((result, column) => {
            result[column.fieldName] = column;
            return result;
        }, {});
    }

    initialOperationValue: any;

    ngOnInit(): void {
        this.initFilters();
        this.reload();
    }

    initFilters() {
        this.originalFilters = Object.assign({}, this.filters);
        if (!this.filters) {
            this.filters = new FilterObject();
        }
    }

    reload() {
        this.refreshList();
    }

    async refreshList() {
        let result = await this.dataService.list(this.getFilters(), this.id);
        this.objects = result.page;
    }

    exportExcel() {
        this.dataService.exportExcel(this.getFilters().params);
    }

    getFilters() {
        let filters = {
            params: {}
        };
        if (!!this.filters) {
            for (let key in this.filters) {
                let value = this.filters[key];
                if (value === null || value === undefined || value === "") {
                    continue;
                }
                filters.params[key] = value.id || value;
            }
        }
        return filters;
    }

    isDisabled(fieldName) {
        return !!this.originalFilters && !!this.originalFilters[fieldName];
    }

    operationChange(event, filterFieldName) {
        let temp = this.filters[filterFieldName + this.initialOperationValue];
        delete this.filters[filterFieldName + this.initialOperationValue];
        this.filters[filterFieldName + event.target.value] = temp;
        this.refreshList();
        event.target.blur();
    }

    operationInit(event) {
        this.initialOperationValue = event.target.value;
    }

    createReferenceMap(keys, func) {
        return keys.map((key) => {
            return {
                id: key,
                label: func(key)
            }
        });
    }

    formatDateArray(value) {
        if (value instanceof Array) {
            return value.map(v => v.toISOString().substring(0, 10)).join(", ");
        }
        return "";
    }

    formatTimeStampArray(timestampArrayField) {
        if (!timestampArrayField) {
            return "";
        }
        return timestampArrayField.map(v => v ? v.toISOString().substring(0, 16).replace("T", " ") : "").join(", ");
    }
}
