import { Injectable } from "@angular/core";

@Injectable()
export class DeckService {

  tabs = [];
  title: string;
  activeTab: any;
  deckState: "folded" | "deck" | "spread" = "spread";
  openTabsInDeck: number;
  deckLabel: string = "";
  activeTabTitle: string = "";
  isDeckLabelSet: boolean = false;

  fold() {
    this.deckState = "folded";
  }

  deck() {
    this.deckState = "deck";
    this.setAllTabWith();
  }

  spread() {
    this.deckState = "spread";
  }

  setActiveTab(tab: any) {
    if (this.activeTab === tab) {
      return;
    }
    this.activeTab = tab;
    this.openTabsInDeck = this.tabs.length - 1;
    this.setTabLabel(tab.label);
    this.setAllTabWith();
  }

  setTabLabel(label: string) {
    if (this.isDeckLabelSet) {
      this.activeTabTitle = label;
    } else {
      this.deckLabel = label;
    }
  }

  setLabel(label: string) {
    if (this.isDeckLabelSet) {
      this.deckLabel = label;
    } else {
      this.activeTabTitle = this.deckLabel;
      this.deckLabel = label;
      this.isDeckLabelSet = true;
    }
  }

  closeTab(tab: any) {
    const index = this.tabs.indexOf(tab, 0);
    if (index > -1) {
      this.tabs.splice(index, 1);
    }
    this.setAllTabWith();
  }

  setAllTabWith() {
    setTimeout(() => {
      this.tabs.map(tab => { !!tab.tabComponent ? tab.tabComponent.setTabWidth() : null });
    }, 0)
  }
}

