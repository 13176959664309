import { Component, EventEmitter, Input, Output } from "@angular/core";
import { XOField } from "./xo-field.component";

@Component({
  selector: "xo-file-input",
  templateUrl: "./xo-file-input.component.html"
})
export class XOFileInput extends XOField {
  @Input()
  file: File;

  @Output()
  fileChange = new EventEmitter<File>();

  onModelChange(value) {
    this.fileChange.emit(value);
  }
}
