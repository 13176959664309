import { Component, Input } from "@angular/core";
import { upperFirstChar } from '../../utils';
import { XOArrayField } from './xo-array-field.component';

@Component({
  selector: "xo-field-array-radio",
  templateUrl: "./xo-field-array-radio.component.html"
})
export class XOArrayFieldRadio extends XOArrayField {
  @Input() Type;

  getKeys() {
    return this.Type[this.fieldName + 'Keys'];
  }

  getLabel(key) {
    return this.Type["get" + upperFirstChar(this.fieldName) + "Values"](key);
  }

  checked(key) {
    return this.object[this.fieldName].indexOf(key) > -1;
  }

  setValue(key) {
    const index = this.object[this.fieldName].indexOf(key);
    if (index > -1) {
      this.object[this.fieldName].splice(index, index + 1);
    } else {
      this.object[this.fieldName].push(key);
    }
    this.parent.validate(this.object, this.fieldName);
  }
}
