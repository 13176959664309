import { Input, Component } from "@angular/core";
import { ColumnInfo } from '@xo/services';

@Component({
    selector: "[xo-table-header-row]",
    templateUrl: "./table.header.component.html",
})
export class TableHeaderComponent {

    @Input() columnInfo: ColumnInfo[];

}
