import { Input, Component } from "@angular/core";

@Component({
// tslint:disable-next-line: component-selector
    selector: "[xo-table-summary-selector]",
    templateUrl: "./table-summary-selector.component.html",
    styleUrls: ["./table-summary-selector.component.scss"]
})
export class TableSummarySelectorComponent {

    @Input() summary: any;
    @Input() summaryInfo: any;
    @Input() onUpdate: () => any;

    constructor() {

    }
}
