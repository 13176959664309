import { TokenStore } from './TokenStore';

export class TokenHandler {
    constructor(private tokenStore?: TokenStore) { }

    async aquireLoginToken() {
        let token;

        token = this.aquireLoginTokenFromUrl() || (await this.aquireLoginTokenFromStore());

        await this.storeLoginToken(token);
        return token;
    }

    async storeLoginToken(token: string) {
        if (!this.tokenStore) {
            return;
        }
        return this.tokenStore.set(token);
    }

    async eraseLoginToken(token: string) {
        if (!this.tokenStore) {
            return;
        }
        return this.tokenStore.erase();
    }

    private aquireLoginTokenFromUrl() {
        let urlString = window.location.href;
        let url = new URL(urlString);
        let token = url.searchParams.get("token");
        return token;
    }

    private aquireLoginTokenFromStore() {
        if (!this.tokenStore) {
            return null;
        }
        return this.tokenStore.get();
    }
}
