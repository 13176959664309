import { GraphState } from './graph.state';
import { DragService } from './drag.service';

export class DragNodeService extends DragService {

    constructor(particleSystem: any, canvas: any, arbor: any, graphState: GraphState) {
        super(particleSystem, canvas, arbor, graphState);
    }

    onDrag(event, pixel: any, mouseP: any) {
        if (!event.ctrlKey && !!this.graphState.dragged) {
            this.graphState.dragged.node.p = pixel;
            return true;
        }
        return false;
    }

    onDrop(event, mouseP, pixel, nearest) {
        if (!event.ctrlKey && !!this.graphState.dragged) {
            this.graphState.dragged.node.p = pixel;
            this.graphState.dragged = null;
        }
    }
}
