import { Component, Input } from "@angular/core";
import { XOArrayField } from './xo-array-field.component';
import { upperFirstChar } from '../../utils';

@Component({
    selector: "xo-field-array-select",
    templateUrl: "./xo-field-array-select.component.html"
})
export class XOArrayFieldSelect extends XOArrayField {
    @Input() Type;
    @Input() parseInput;

    getKeys() {
        return this.Type[this.fieldName + 'Keys'];
    }

    getLabel(key) {
        return this.Type["get" + upperFirstChar(this.fieldName) + "Values"](key);
    }
}
