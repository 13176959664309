import { WorklogUrlService } from './worklog/worklog.url.service';
import { KeyValueDiffers } from '@angular/core';
import { TaskType } from './task/Task';
import { CachingTaskService } from 'projects/xodos/src/task/task.service';
import { TaskUrlService } from './task/task.url.service';
import { TaskElementTransformator, TaskElementService } from './task/task.service';
import { CommentUrlService } from './overview/task/comment/comment.url.service';
import { CommentTransformator, CommentService } from './overview/task/comment/comment.service';
import { GroupType } from './../../application-e2e/src/testVariables';
import { ConfigComponent } from './config/config.component';
import { FilterComponent } from './overview/filter/filter.component';
import { EstimationComponent } from './estimation/estimation.component';
import { FilterGroupComponent } from './overview/filter-group/filter-group.component';
import { SetGroupComponent } from './overview/set-group/set-group.component';
import { TaskDetailsComponent } from './overview/task/task-details/task-details.component';
import { HistoryItemComponent } from './overview/task/history-item/history-item.component';
import { StatusChangeListComponent } from './overview/task/status-change/status-change-list/status-change-list.component';
import { StatusChangeItemComponent } from './overview/task/status-change/status-change-item/status-change-item.component';
import { CommentItemComponent } from './overview/task/comment/comment-item/comment-item.component';
import { CommentListComponent } from './overview/task/comment/comment-list/comment-list.component';
import { HistoryListComponent } from './overview/task/history-list/history-list.component';
import { TemplateConfigComponent } from './overview/task/template/template-config.component';
import { ApplyTemplateComponent } from './overview/task/template/apply-template.component';
import { TemplateCreatorComponent } from './overview/task/template/template-creator.component';
import { FeedGroupComponent } from './feed/feedGroup.component';
import { XodosComponent } from './xodos.component';
import { TaskOverviewComponent } from './task/task-overview';
import { LegendComponent } from "./legend/legend.component";
import { SimpleTask } from './task/simple/simple-task';
//import { TaskGraphComponent } from './task/task-graph/task-graph';
import { CreateTaskNodeComponent } from './task/create-task-node/create-task-node.component';
import { MassCreateComponent } from './overview/task/mass-create/mass-create.component';
import { FeedComponent } from './feed/feed.component';
import { WorklogItemComponent } from './worklog/worklog-item/worklog-item.component';
import { WorklogListComponent } from './worklog/worklog-list/worklog-list.component';
import { WorklogComponent } from './worklog/worklog.component';
import { GroupListWindowComponent } from './overview/group/component/group.list';
import { GroupPage } from './overview/group/component/group-page.component';
import { GroupSelect } from './overview/group/component/group.select';
import { GroupTable } from './overview/group/component/group.table';
import { GroupComponent } from './overview/group/component/group.component';
import { DataService } from '@xo/services';
import { ConfigService as XO_ConfigService } from '@xo/client-common';
import { HttpClient } from '@angular/common/http';
import { ConfigUrlService } from './config/config.url.service';
import { ConfigTransformator, ConfigService } from './config/config.service';
import { GroupTransformator, CachingGroupService } from './overview/group/component/group.service';
import { GroupUrlService } from './overview/group/component/group.url.service';
import { StatusChangeUrlService } from './overview/task/status-change/status-change.url.service';
import { StatusChangeTransformator, StatusChangeService } from './overview/task/status-change/status-change.service';
import { WorklogTransformator, WorklogService } from './worklog/worklog.service';

export let moduleNames = {
    XodosComponent,
    ConfigComponent,
    WorklogComponent,
    FilterComponent,
    EstimationComponent,
    FilterGroupComponent,
    SetGroupComponent,
    TaskDetailsComponent,
    HistoryItemComponent,
    WorklogItemComponent,
    WorklogListComponent,
    StatusChangeListComponent,
    StatusChangeItemComponent,
    CommentItemComponent,
    CommentListComponent,
    HistoryListComponent,
    TemplateConfigComponent,
    ApplyTemplateComponent,
    TemplateCreatorComponent,
    FeedComponent,
    FeedGroupComponent,


    TaskOverviewComponent,
    LegendComponent,
    SimpleTask,
    //TaskGraphComponent,
    CreateTaskNodeComponent,
    MassCreateComponent,

    GroupListWindowComponent,
    GroupPage,
    GroupSelect,
}


export let modules = [
    XodosComponent,
    ConfigComponent,
    WorklogComponent,
    FilterComponent,
    EstimationComponent,
    FilterGroupComponent,
    SetGroupComponent,
    TaskDetailsComponent,
    HistoryItemComponent,
    WorklogItemComponent,
    WorklogListComponent,
    StatusChangeListComponent,
    StatusChangeItemComponent,
    CommentItemComponent,
    CommentListComponent,
    HistoryListComponent,
    TemplateConfigComponent,
    ApplyTemplateComponent,
    TemplateCreatorComponent,
    FeedComponent,
    FeedGroupComponent,


    TaskOverviewComponent,
    LegendComponent,
    SimpleTask,
    //TaskGraphComponent,
    CreateTaskNodeComponent,
    MassCreateComponent,

    GroupListWindowComponent, GroupTable, GroupPage, GroupComponent, GroupSelect
]


let systemServices = new Map<string, Map<string, DataService<any>>>();

export function createXodosServiceFactory(xo_configService: XO_ConfigService, http: HttpClient, differ: KeyValueDiffers) {
    return function (systemName: string): Map<string, DataService<any>> {
        let serviceMap = systemServices.get(systemName);
        if (!!serviceMap) {
            return serviceMap;
        }

        serviceMap = new Map<string, DataService<any>>();

        let commentTransformator = new CommentTransformator();
        let commentUrlService = new CommentUrlService(systemName, xo_configService);
        let commentService = new CommentService(http, commentUrlService, commentTransformator);
        serviceMap.set("Comment", commentService);

        let configTransformator = new ConfigTransformator();
        let configUrlService = new ConfigUrlService(systemName, xo_configService);
        let configService = new ConfigService(http, configUrlService, configTransformator);
        serviceMap.set("Config", configService);

        let groupTransformator = new GroupTransformator(systemName);
        let groupUrlService = new GroupUrlService(systemName, xo_configService);
        let groupService = new CachingGroupService(http, groupUrlService, groupTransformator);
        serviceMap.set(GroupType, groupService);

        let statusChangeTransformator = new StatusChangeTransformator();
        let statusChangeUrlService = new StatusChangeUrlService(systemName, xo_configService);
        let statusChangeService = new StatusChangeService(http, statusChangeUrlService, statusChangeTransformator);
        serviceMap.set("StatusChange", statusChangeService);

        let taskTransformator = new TaskElementTransformator();
        taskTransformator.groupService = groupService;
        let taskUrlService = new TaskUrlService(systemName, xo_configService);
        let cachingTaskService = new CachingTaskService(http, taskUrlService, taskTransformator);
        serviceMap.set(TaskType, cachingTaskService);

        let taskElementService = new TaskElementService(cachingTaskService, differ, http, taskUrlService);
        serviceMap.set("TaskElement", taskElementService);

        let worklogTransformator = new WorklogTransformator();
        let worklogUrlService = new WorklogUrlService(systemName, xo_configService);
        let worklogService = new WorklogService(http, worklogUrlService, worklogTransformator);
        serviceMap.set("Worklog", worklogService);

        systemServices.set(systemName, serviceMap);
        return serviceMap;
    };
}
