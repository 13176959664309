export const PERMISSION_LIST = "LIST";
export const PERMISSION_LOAD = "LOAD";
export const PERMISSION_CREATE = "CREATE";
export const PERMISSION_MODIFY = "MODIFY";
export const PERMISSION_DELETE = "DELETE";
export const PERMISSION_SPECIAL = "SPECIAL";
export const PERMISSION_MENU = "MENU";

let config = null;

export function setConfig(c) {
    config = c;
}

export function hasPermission(systemName: string, moduleName: string, typeName: string, operation: string) {
    let result = false;

    let permissions = getPermissions(systemName, moduleName, typeName);
    result = permissions.indexOf(operation) >= 0;
    return result;
}

export function getDefaultSystemName(moduleName: string) {
    return config.modules.find(module => module.module == moduleName).system;
}

function getPermissions(systemName, moduleName, typeName): string[] {
    if (!config.permissions[systemName]
        || !config.permissions[systemName][moduleName]
        || !config.permissions[systemName][moduleName].module.permissions
        || !config.permissions[systemName][moduleName].module.permissions[typeName]) {
        return [];
    }

    return config.permissions[systemName][moduleName].module.permissions[typeName];
}
