import { Component, Input } from '@angular/core';
import { ExcelService } from './excel.service';
import { ToastrService } from 'ngx-toastr';
import { TabComp } from '../../../components/tab/TabComp';

export let ExcelType = "Excel";

@Component({
    selector: "xo-excel",
    templateUrl: "./excel.component.html",
    styleUrls: []
})
export class ExcelComponent implements TabComp {
    @Input() title: string;

    @Input() disableButtons?: boolean = false;

    modules;

    public file: File;

    selectedType;

    selectedModule;

    moduleKeys = [];

    constructor(private dataService: ExcelService, private toastr: ToastrService) {
        this.modules = this.dataService.modules;
        for (let field in this.modules) {
            this.moduleKeys.push(field);
        }
    }

    init() {
    }

    reload() {
    }

    public filesChanged(files) {
        this.file = files[0];
    }

    upload() {
        this.disableButtons = true;
        this.dataService.importExcel(this.file).then((response) => {
            if (response == "OK") {
                this.toastr.info("Import Sikeres!");
                this.disableButtons = false;
            }
        }).catch((e) => {
            this.toastr.error("HIBA AZ IMPORT SORÁN!");
            this.disableButtons = false;
            console.log("error.", e);
        });
    }

    update() {
        this.disableButtons = true;
        this.dataService.updateExcel(this.file).then((response) => {
            if (response == "OK") {
                this.toastr.info("Update Sikeres!");
                this.disableButtons = false;
            }
        }).catch((e) => {
            this.toastr.error("HIBA AZ UPDATE SORÁN!");
            this.disableButtons = false;
            console.log("error.", e);
        });;
    }

    export() {
        this.disableButtons = true;
        this.dataService.exportExcel();
        this.disableButtons = false;
    }

    setUrlServiceModule() {
        this.dataService.excelUrlService.setModuleName(this.selectedModule);
    }

    setUrlServiceType() {
        this.dataService.excelUrlService.setTypeName(this.selectedType);
    }
}
