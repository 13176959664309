import { Component, ViewChild, ComponentFactoryResolver, ViewContainerRef, HostListener } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TabComponent } from "../tab/tab.component";
import { openTab, closeTab, reloadActiveTab, tabChange } from "./tab.control";
import { ConfigService } from "../../services/config.service";
import { System } from "./System";


@Component({
  selector: "xo-my-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
})
export class TabsComponent {

  dynamicTabs: TabComponent[] = [];

  tabHistory: any[] = [];

  @ViewChild("container", { read: ViewContainerRef, static: true }) dynamicTabPlaceholder;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private titleService: Title, public configService: ConfigService) {
    this.subscribe();
  }

  subscribe() {
    openTab.subscribe((tab) => {
      if (this.configService.config.decks) {
        return;
      }
      if (!!this.configService.config.singleTab) {
        this.dynamicTabs.forEach((tab) => {
          this.doCloseTab(tab);
        });
      }
      this.openTab(tab.label, tab.component, tab.system, tab.data, tab.saveObject, tab.isCloseable);
      this.tabHistory.push(tab);
    });
    closeTab.subscribe((data) => {
      if (!!data && !!data.allOf) {
        this.closeTabs(data.allOf);
        return;
      }
      if (!!data && !!data.tab) {
        this.doCloseTab(data.tab);
        return;
      }
      this.closeActiveTab();
      this.tabHistory.pop();
      if (this.configService.config.singleTab && (this.tabHistory.length > 0)) {
        openTab.emit(this.tabHistory[this.tabHistory.length - 1]);
      }
    });
    reloadActiveTab.subscribe(() => {
      this.reloadActiveTab();
    })
  }

  private openTab(title: string, template: string, system: System, data: any, saveObject: (any) => any, isCloseable = true) {
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(TabComponent);
    let viewContainerRef = this.dynamicTabPlaceholder;
    let componentRef = viewContainerRef.createComponent(componentFactory);
    let instance: TabComponent = componentRef.instance as TabComponent;
    instance.title = title;
    instance.template = template;
    instance.dataContext = data;
    instance.isCloseable = isCloseable;
    instance.system = system;
    instance.saveObject = saveObject;
    this.dynamicTabs.push(instance);
    this.selectTab(instance, false);
  }


  @HostListener("window:keyup", ['$event'])
  keyPressEvent(event: KeyboardEvent) {
    if (event.keyCode == 27) { // ESC
      console.log("closing active tab");
      this.closeActiveTab();
    }
    if (event.ctrlKey && event.keyCode == 13) { // ENTER
      let activeTab = this.getActiveTab();
      if (!!activeTab && !!activeTab["submitForm"]) {
        console.log("saving");
        activeTab["submitForm"]();
      }
    }
    if (event.ctrlKey && event.keyCode == 40) { // DOWN_ARROW
      console.log("next tab");
      let activeTab = this.getActiveTab();
      let currentTabIndex = this.dynamicTabs.indexOf(activeTab);
      if (currentTabIndex < (this.dynamicTabs.length - 1)) {
        this.deactivateAllTabs();
        this.dynamicTabs[currentTabIndex + 1].active = true;
      }
    }
    if (event.ctrlKey && event.keyCode == 38) { // UP_ARROW
      console.log("previous tab");
      let activeTab = this.getActiveTab();
      let currentTabIndex = this.dynamicTabs.indexOf(activeTab);
      if (currentTabIndex > 0) {
        this.deactivateAllTabs();
        this.dynamicTabs[currentTabIndex - 1].active = true;
      }
    }
  }

  deactivateAllTabs() {
    this.dynamicTabs.forEach(dtab => dtab.active = false);
  }

  getActiveTab() {
    return this.dynamicTabs.find(t => t.active == true);
  }

  selectTab(tab: TabComponent, reloadTab: boolean = true) {
    if (!tab) {
      return;
    }
    let activeTab = this.getActiveTab();
    if (!!activeTab) {
      console.log("saving position", window.scrollY, activeTab["scrollPostion"]);
      activeTab["scrollPosition"] = window.scrollY;
    }
    this.deactivateAllTabs();

    tab.active = true;
    if (!!tab["scrollPosition"]) {
      window.scrollBy(0, tab["scrollPosition"]);
      console.log("scrolling to position", tab["scrollPosition"]);
    }

    if (reloadTab) {
      setTimeout(() => {
        if (tab.component) {
          tab.component.reload();
        }
      }, 0);
    }
    this.titleService.setTitle(tab.title);
    tabChange.emit(tab);
  }

  closeTab(tab: TabComponent) {
    closeTab.emit({ tab });
  }

  doCloseTab(tab: TabComponent) {
    for (let i = 0; i < this.dynamicTabs.length; i++) {
      if (this.dynamicTabs[i] === tab) {
        this.dynamicTabs.splice(i, 1);
        this.dynamicTabPlaceholder.remove(i);
        if (tab.active) {
          this.selectTab(this.dynamicTabs[i - 1 >= 0 ? i - 1 : 0]);
        }
        break;
      }
    }
  }

  closeTabs(tabCompType: any) {
    this.dynamicTabs
      .filter(tab => tab.template === tabCompType)
      .map(tab => this.doCloseTab(tab));
  }

  closeActiveTab() {
    if (!!this.configService.config.singleTab) {
      return;
    }
    let activeTabs = this.dynamicTabs.filter((tab) => tab.active);
    if (activeTabs.length > 0) {
      if (activeTabs[0].isCloseable) {
        this.doCloseTab(activeTabs[0]);
      }
    }
  }

  reloadActiveTab() {
    let tab = this.getActiveTab();
    if (tab.component) {
      tab.component.reload();
    }
  }
}
