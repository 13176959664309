import { Injectable } from "@angular/core";

@Injectable()
export class TabService {
  tabs: any[] = [];

  public canSwitch: boolean;
  public isTabView = false;

  addTab(tab: any) {
    this.tabs.push(tab);
  }

  selectTab(tabToSelect: any) {
    if (!tabToSelect) {
      return;
    }
    this.tabs.forEach(tab => {
      tab.active = false;
    });
    tabToSelect.active = true;
  }

  getActiveTab() {
    return this.tabs.find(tab => tab.active) ?? this.tabs[0];
  }

  clear() {
    this.tabs = [];
  }

  autoSelectActiveTab() {
    this.selectTab(this.getActiveTab());
  }

  changeView() {
    this.isTabView = !this.isTabView;
    if (this.isTabView) {
      setTimeout(() => {
        this.autoSelectActiveTab();
      }, 0);
    } else {
      this.clear();
    }
  }
}
