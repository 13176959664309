import { Injectable } from "@angular/core";
import { Deck } from "./deck";

@Injectable()
export class DeckContainerService {
  public decks: Deck[] = [];

  discard(deck: Deck) {
    this.decks = this.decks.filter(currentDeck => currentDeck != deck);
  }
}
