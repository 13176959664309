import { Component } from "@angular/core";
import { XOArrayField } from './xo-array-field.component';

@Component({
    selector: "xo-field-array-number",
    templateUrl: "./xo-field-array-number.component.html"
})
export class XOArrayFieldNumber extends XOArrayField {
    convertNumber(convertable: any) {
        return parseInt(convertable.value, 10);
    }
}
