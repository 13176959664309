import { Component, Input, ElementRef, OnInit } from "@angular/core";

@Component({
    selector: "[xo-table-data-string]",
    templateUrl: "./table-data-string.html"
})
export class TableDataString implements OnInit {
    @Input() object;
    @Input() fieldName: string;

    constructor(private elRef: ElementRef){}
    
    ngOnInit(){
        this.elRef.nativeElement.classList.add(this.fieldName);
    }
}