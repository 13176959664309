import { userChanged } from './authentication/user.changed';
import { EventEmitter } from '@angular/core';
import { apiError } from '@xo/services';

export let applicationError = new EventEmitter<Error>();

export function errorHandler(error: any) {
  console.log("Error", error, error.status);
  if ((error.status === 401) || (error.status === 403) || (error.status >= 500)) {
    console.log("Error logout");
    userChanged.emit(null);
  }
  applicationError.emit(error);
}

apiError.on(errorHandler);
