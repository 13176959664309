import { Component, Input, ElementRef, OnInit } from "@angular/core";

@Component({
  selector: "[xo-table-data-timestamp]",
  templateUrl: "./table-data-timestamp.html"
})
export class TableDataTimestamp implements OnInit {
  @Input() object;
  @Input() fieldName: string;

  constructor(private elRef: ElementRef) { }

  ngOnInit() {
    this.elRef.nativeElement.classList.add(this.fieldName);
  }
}
