import { Component, ChangeDetectorRef, Input } from "@angular/core";
import { User } from "./User";
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from '../../../services/config.service';
import { UserService } from './user.service';
import { closeTab } from '../../../components/tabs/tab.control';
import { CurrentUserContainer } from '../../../authentication/current-user-container.service';
import { ObjectWindow } from '../../../window/objectWindow';
import { createUserValidators } from './UserValidation';

@Component({
    selector: "xo-user-profile",
    templateUrl: "./profile-user.component.html",
    styleUrls: ["./profile-user.component.scss"]
})
// tslint:disable-next-line: component-class-suffix
export class ProfileUser extends ObjectWindow<User, UserService> {

    @Input() user?: User;

    public ProfileUser = ProfileUser;
    static changePassword = "Jelszó megáltoztatása";
    static registrationDate = "Regisztráció időpontja";

    validationMessages = [];
    constructor(userService: UserService, public toastr: ToastrService, public configService: ConfigService,
        private currentUserContainer: CurrentUserContainer, changeDetector: ChangeDetectorRef) {
        super(userService, toastr, createUserValidators(), changeDetector);
    }

    async init(data?) {
        super.init(data);
        if (!this.user) {
            this.user = this.currentUserContainer.user;
        }
        console.log("profile user", this.user);
    }

    setupNewObject() {

    }

    changePassword() {
        return this.dataService.changePassword(this.user.username);
    }
}
