import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, InjectionToken } from '@angular/core';
import { ExcelUrlService } from './excelUrl.service';
import { DataService, Transformator } from '@xo/services';

export const TOKEN_EXPORT_TYPES = new InjectionToken<any>('TOKEN_EXPORT_TYPES');

@Injectable()
export class ExcelTransformator extends Transformator<any>{

    constructor() {
        super();
    }

    async transformFromServer(serverObj: any) {
        //    throw new Error("Method not implemented.");
    }
    transformToServer(typeObj: any) {
        //  throw new Error("Method not implemented.");
    }
}

@Injectable()
export class ExcelService extends DataService<any>{



    public modules;

    constructor(http: HttpClient, public excelUrlService: ExcelUrlService, private injector: Injector, excelTransformator: ExcelTransformator, private toastr: ToastrService) {
        super(http, excelUrlService, excelTransformator, 'id');
        this.modules = this.injector.get(TOKEN_EXPORT_TYPES);
    }

    updateExcel(file: File) {
        if (!!file) {
            this.toastr.error("Please choose a file to upload!");
            return;
        }
        let formData = new FormData();
        formData.append("file", file, file.name);
        let httpRequest = this.http.put(this.urlService.getExcelUrl(), formData, { withCredentials: true });

        return httpRequest
            .toPromise()
            .catch((e => { console.log(e) })) as Promise<any>;
    }

    importExcel(file: File) {
        if (!file) {
            this.toastr.error("Please choose a file to import!");
            return Promise.resolve();
        }
        let formData = new FormData();
        formData.append("file", file, file.name);
        let httpRequest = this.http.post(this.urlService.getExcelUrl(), formData, { withCredentials: true });

        return httpRequest
            .toPromise()
            .catch((e => { console.log(e) })) as Promise<any>;
    }

    exportExcel() {
        (window as any).location = this.urlService.getExcelUrl();
    }
}
