import { Component, Input } from '@angular/core';
import { ColumnInfo } from '@xo/services';

@Component({
    selector: "[xo-table-row]",
    templateUrl: "./table-row.component.html"
})
export class TableRowComponent {

    @Input() object;
    @Input() columnInfo: ColumnInfo[];

}
