import { Component, Input } from "@angular/core";

import { TabService } from "./tabs.service";

@Component({
  selector: "xo-tab",
  templateUrl: "./tab.html",
  styleUrls: ["./tab.scss"],
  host: {
    "[class.hidden]": "!active"
  }
})
export class XoTabComponent {
  @Input() active: boolean;
  @Input() tabTitle: string;

  constructor(tabService: TabService) {
    tabService.addTab(this);
  }
}
