import { ConfigService } from './../../../services/config.service';
import { AuditLog, AuditLogType } from "./AuditLog";
import { Component, OnInit, Optional } from "@angular/core";
import { PagingTableComponent } from './../../../components/table/table-component/paging.table.component';
import { ModalTabComponent } from './../../../components/tab/modal-tab.component';
import { TabComponent } from './../../../components/tab/tab.component';

@Component({
    selector: "xo-audit-log-table",
    styleUrls: ["./auditLog.table.scss"],
    templateUrl: "./auditLog.table.html"
})
export class AuditLogTable extends PagingTableComponent<AuditLog> implements OnInit {

    AuditLog = AuditLog;
    types;

    constructor(
        configService: ConfigService,
        @Optional() modalTab: ModalTabComponent, @Optional() tab: TabComponent) {
        super(AuditLogType, AuditLog.columnInfo, modalTab || tab);

        this.types = configService.config.auditlog ? configService.config.auditlog.types : null;
    }

    async ngOnInit() {
        this.refreshList();
    }

    getClasses(auditLog) {
        let classes: any = { objects: true };
        return classes;
    }
}
