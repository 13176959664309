import { Window } from './Window';
import { PagingTableComponent } from '../components/table/table-component/paging.table.component';
import { ChangeDetectorRef } from '@angular/core';

export abstract class ListWindow extends Window {

    table: PagingTableComponent<any>;

    constructor(protected changeDetector: ChangeDetectorRef) {
        super();
    }

    init(filters: any, table?: PagingTableComponent<any>, addPaging?: boolean) {
        super.init(filters);
        if (!!table && !!filters) {
            this.table = table;
            this.table.filters = filters;
            this.table.refreshList(addPaging);
        }
        this.changeDetector.detectChanges();
    }

    reload(filters?, addPaging?: boolean) {
        super.reload(filters);
        this.table.refreshList(addPaging);
    };
}
