import { LocalStorageTokenStore } from './LocalStorageTokenStore';
import { v4 as createUuid } from "uuid";
import { Injectable } from "@angular/core";
import { User } from '../modules/common/user/User';
import { MenuItem } from '../components/menu/MenuItem';
import { HttpClient } from '@angular/common/http';
import { DataService } from '@xo/services';
import { setConfig } from '../authentication/auth.util';
import { TokenHandler } from './login.token';
import { ConfigTokenStore } from './ConfigTokenStore';
import { System } from '../components/tabs/System';
import { TokenStore } from './TokenStore';
import { userChanged } from '../authentication/user.changed';

declare var config: Config;

class Apisession {
    module: string;
    system: string;
    url: string;
    token;
}

export interface Config {
    baseUrl: any;
    user: User;
    menu: MenuItem[];
    modules: Apisession[];
    loginToken?: string;
    permissions: {};
    authenticationUrl?;
    singleTab?: boolean;
    decks?: boolean;
    autoRegister?: { system: string, module: string };
    languages?: string[];
    currentLanguage?: string;
    auditlog: any;
    rollbar?: Object;
    theme: null | "future",
    disableParameterHashing: boolean; //disables location bar update on parameter changes
    isAdmin?: () => boolean;
    isSystem?: () => boolean;
}

@Injectable()
export class ConfigService {

    static config: Config = {} as any;
    config: Config;

    serviceFactories = new Map<string, (string) => Map<string, DataService<any>>>();

    public tokenHandler: TokenHandler

    onApplicationInit: () => any;

    onSystemInit: (system: System) => any;

    constructor(private http: HttpClient) {
        this.config = config;
        ConfigService.config = config;
    }

    async register(token) {
        let uuid = createUuid();
        console.log("token not found, creating user", uuid);
        let rawuser = {
            username: uuid,
            password: uuid,
            system: this.config.autoRegister.system,
            module: this.config.autoRegister.module,
            source: "AUTOREGISTER",
            sourceId: uuid
        };
        console.log("token not found, creating user", uuid, rawuser);
        await this.http.post(this.config.authenticationUrl + "/user/register", rawuser).toPromise();
        console.log("user created")

        let loginsession = (await this.http.post(this.config.authenticationUrl + "/session", rawuser).toPromise()) as any;
        console.log("logged in", loginsession);
        let logintoken = loginsession.login.token;
        let session = (await this.http.post(this.config.authenticationUrl + "/session/" + logintoken, { userroles: loginsession.userrole }).toPromise()) as any;
        console.log("session", session);
        token = session[0].token;
        this.tokenHandler.storeLoginToken(token);
        return token;
    }

    async loadConfig(token) {
        let loadedConfig = (await this.http.post(this.config.authenticationUrl + "/session/client/" + token, {}).toPromise()) as Config;
        this.config = Object.assign(this.config, loadedConfig);
        this.config.baseUrl = {};
        for (let i = 0; i < this.config.modules.length; i++) {
            let apisession = this.config.modules[i];
            this.config.baseUrl[apisession.module] = apisession.url;
            this.config.baseUrl[""] = apisession.url;
            await this.loginToApi(apisession.url, apisession.token);
        }
    }

    async load(tokenStore?: TokenStore) {
        if (!tokenStore && !!config.loginToken) {
            tokenStore = new ConfigTokenStore(config.loginToken);
        }
        if (!tokenStore) {
            tokenStore = new LocalStorageTokenStore();
        }
        console.log("Authenticating with token store", tokenStore);
        this.tokenHandler = new TokenHandler(tokenStore);

        let token = await this.tokenHandler.aquireLoginToken();
        console.log("Authenticating with token", token, this.config.autoRegister);

        if ((!token || token == "null") && !!this.config.autoRegister) {
            token = await this.register(token);
        }

        if (!token) {
            userChanged.emit(null);
            return;
        }

        await this.loadConfig(token);

        setConfig(this.config);

        userChanged.emit(this.config.user);
    }

    loginToApi(url, token) {
        let finalUrl = url + "/User/login?token=" + token;
        console.log("Login to Api:", finalUrl);
        return this.http.post(finalUrl, {}, { withCredentials: true }).toPromise();
    }
}
