import { Key } from 'ts-keycode-enum';
import { CHILD_CREATION_BASIC, ChildType, TreeState, getPrimaryChildType, getSecondaryChildType, Element } from '@xo/services';

export let preventDefaults = [
  Key.Space,
  Key.RightArrow,
  Key.LeftArrow,
  Key.UpArrow,
  Key.DownArrow,
  Key.End
];

export let nonInsertableKeys = [
  Key.End,
  Key.Home,
  Key.Enter
]

export let keyFunctions: any = {
  [Key.Space]: showHideSubelements,
  [Key.RightArrow]: activateFirstChild,
  [Key.LeftArrow]: activateParent,
  [Key.UpArrow]: activatePreviousSibling,
  [Key.DownArrow]: activateNextSibling,
  [Key.Delete]: deleteElement,
  [Key.Enter]: createSiblingElement,
  [Key.Insert]: createChildElement,
}

export let ctrlKeyFunction = {
  [Key.UpArrow]: moveSiblingToPrevious,
  [Key.DownArrow]: moveSiblingToNext,
  [Key.LeftArrow]: moveToParent,
  [Key.RightArrow]: moveToSiblingAsChild,
  [Key.V]: pasteIntoTree,
  [Key.X]: cutFromTree
}

export let shiftKeyFunction = {
  [Key.Insert]: createParent
}

function moveToSiblingAsChild(state: TreeState) {
  let parent = state.activeElement.XO_parent;
  let siblings = parent.XO_service.getChildren(parent);
  let myIndex = siblings.indexOf(state.activeElement);
  let previousSibling = siblings[myIndex - 1];
  if (!previousSibling) {
    return;
  }
  let previousSiblingChildren = previousSibling.XO_service.getChildren(previousSibling);
  state.activeElement.XO_service.setParent(state.activeElement, previousSibling, previousSiblingChildren.length + 1).then(async () => {
    await parent.XO_service.updateChildren(parent);
    await previousSibling.XO_service.updateChildren(previousSibling);
    state.activeElement = previousSibling.XO_service.getChildren(previousSibling)[previousSiblingChildren.length];
  })
}

function moveToParent(state: TreeState) {
  let parent = state.activeElement.XO_parent;
  let grandParent = parent.XO_parent;
  if (!grandParent) {
    return;
  }
  let parentalIndex = grandParent.XO_service.getChildren(grandParent).indexOf(parent);
  state.activeElement.XO_service
    .setParent(state.activeElement, grandParent, parentalIndex + 1)
    .then(async () => {
      await parent.XO_service.updateChildren(parent);
      await grandParent.XO_service.updateChildren(grandParent);
      state.activeElement = grandParent.XO_service.getChildren(grandParent)[parentalIndex + 1];
    });
}

function moveSiblingToNext(state: TreeState) {
  let parent = state.activeElement.XO_parent;
  let initialIndex = parent.XO_service.getChildren(parent).indexOf(state.activeElement);
  if (initialIndex == parent.XO_service.getChildren(parent).length - 1) {
    state.activeElement.XO_service.swapChildren(parent, initialIndex, 0).then(() => {
      state.activeElement = state.activeElement.XO_parent.XO_service.getChildren(state.activeElement.XO_parent)[0];
    });
  } else {
    state.activeElement.XO_service.swapChildren(parent, initialIndex, initialIndex + 1).then(() => {
      state.activeElement = state.activeElement.XO_parent.XO_service.getChildren(state.activeElement.XO_parent)[initialIndex + 1];
    });
  }
}

function moveSiblingToPrevious(state: TreeState) {
  let parent = state.activeElement.XO_parent;
  let initialIndex = parent.XO_service.getChildren(parent).indexOf(state.activeElement);
  if (initialIndex == 0) {
    state.activeElement.XO_service.swapChildren(parent, initialIndex, state.activeElement.XO_parent.XO_service.getChildren(state.activeElement.XO_parent).length - 1).then(() => {
      state.activeElement = state.activeElement.XO_parent.XO_service.getChildren(state.activeElement.XO_parent)[state.activeElement.XO_parent.XO_service.getChildren(state.activeElement.XO_parent).length - 1];
    });
  } else {
    state.activeElement.XO_service.swapChildren(parent, initialIndex, initialIndex - 1).then(() => {
      state.activeElement = state.activeElement.XO_parent.XO_service.getChildren(state.activeElement.XO_parent)[initialIndex - 1];
    });
  }
}

export function editElementLabel(state: TreeState, event) {
  state.activeElementEditing = true;
  setTimeout(() => {

    if (state.config.typeAppends == true) {
      state.editingLabel += (nonInsertableKeys.indexOf(event.keyCode) < 0) ? event.key : "";
    } else {
      state.editingLabel = (nonInsertableKeys.indexOf(event.keyCode) < 0) ? event.key : "";
    }
    let activeLabelInput = document.getElementById("activeLabelInput" + state.id);
    activeLabelInput.focus();
  }, 0);
}

function showHideSubelements(state: TreeState) {
  state.activeElement.XO_ShowChildren = !state.activeElement.XO_ShowChildren;
  if (state.activeElement.XO_ShowChildren) {
    state.activeElement.XO_service
      .getChildren(state.activeElement)
      .forEach((element) => element.XO_service.updateChildren(element));
  }
}

function activateFirstChild(state: TreeState) {
  state.activeElement.XO_ShowChildren = true;
  state.activeElement.XO_service
    .getChildren(state.activeElement)
    .forEach((element) => element.XO_service.updateChildren(element));
  let selectedChild = state.activeElement.XO_service.getChildren(state.activeElement as any)[0];
  if (!selectedChild) {
    return;
  }
  state.activeElement = selectedChild;
}

function activateParent(state: TreeState) {
  if (!state.activeElement.XO_parent) {
    return;
  }
  state.activeElement = state.activeElement.XO_parent;
}

function activateNextSibling(state: TreeState) {
  let parentElement = state.activeElement.XO_parent;
  if (!parentElement) {
    return;
  }
  let siblings = parentElement.XO_service.getChildren(parentElement);
  let index = siblings.indexOf(state.activeElement);
  if (index == (siblings.length - 1)) {
    state.activeElement = siblings[0];
  } else {
    state.activeElement = siblings[index + 1];
  }
}

function activatePreviousSibling(state: TreeState) {
  let parentElement = state.activeElement.XO_parent;
  if (!parentElement) {
    return;
  }
  let siblings = parentElement.XO_service.getChildren(parentElement);
  let index = siblings.indexOf(state.activeElement);
  if (index == 0) {
    state.activeElement = siblings[siblings.length - 1];
  } else {
    state.activeElement = siblings[index - 1];
  }
}

function deleteElement(state: TreeState) {
  let parent = state.activeElement.XO_parent;
  if (!parent) {
    return;
  }
  let formerIndex = parent.XO_service.getChildren(parent).indexOf(state.activeElement);
  if (confirm("Biztosan törli az aktív elemet?")) {
    state.activeElement.XO_service.delete(state.activeElement).then(() => {
      parent.XO_service.updateChildren(parent).then(() => {
        let siblings = parent.XO_service.getChildren(parent);
        if (siblings.length == 0) {
          state.activeElement = parent;
        } else {
          if (formerIndex < siblings.length) {
            state.activeElement = siblings[formerIndex];
          } else {
            state.activeElement = siblings[siblings.length - 1];
          }
        }
      });
    });
  } else {
    return;
  }
}

function createSiblingElement(state: TreeState) {
  let parent = state.activeElement.XO_parent;
  if (!parent) {
    return;
  }
  let primaryChildType = getPrimaryChildType(state.activeElement);
  if (!primaryChildType) {
    return;
  }
  createChild(state, parent);
}

function createChildElement(state: TreeState) {
  state.activeElement.XO_ShowChildren = true;
  createChild(state, state.activeElement);
}

function createChild(state: TreeState, element: Element) {
  if (element.XO_service.childCreation == CHILD_CREATION_BASIC) {
    let primaryChildType = getPrimaryChildType(element);
    if (!primaryChildType) {
      return;
    }
    createChildWithType(state, element, primaryChildType);
  } else {
    createChildWithType(state, element, null);
  }
}

export function createSecondaryChild(state: TreeState, element: Element) {
  if (element.XO_service.childCreation == CHILD_CREATION_BASIC) {
    let primaryChildType = getSecondaryChildType(element);
    if (!primaryChildType) {
      return;
    }
    createChildWithType(state, element, primaryChildType);
  } else {
    createChildWithType(state, element, null);
  }
}

export function createChildWithType(state: TreeState, parent: Element, type: ChildType) {
  parent.XO_service.createChild(parent, type).then((newChild) => {
    parent.XO_service
      .updateChildren(parent)
      .then(() => {
        let children = parent.XO_service.getChildren(parent);
        state.activeElement = children.find(sibling => sibling.id == newChild.id);
      });
  });
}

function cutFromTree(state: TreeState) {
  let parent = state.activeElement.XO_parent;
  if (!parent) {
    return;
  }
  state.movingElement = state.activeElement;
}

function pasteIntoTree(state: TreeState) {
  if (!state.movingElement) {
    return;
  }
  let oldParent = state.movingElement.XO_parent;
  let newParent = state.activeElement;
  state.movingElement.XO_parent = newParent;
  setTimeout(() => {
    newParent.XO_service.updateChildren(newParent);
    oldParent.XO_service.updateChildren(oldParent);
  }, 300);
  state.movingElement = null;
}

async function createParent(state: TreeState) {
  let parent = await state.activeElement.XO_service.createParent(state.activeElement);
  state.activeElement = parent;
}
