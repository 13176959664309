import { Injectable } from "@angular/core";
import { User } from "../modules/common/user/User";
import { ConfigService } from '../services/config.service';
import { hasPermission, getDefaultSystemName } from './auth.util';
import { HttpClient } from '@angular/common/http';
import { userChanged } from "./user.changed";

@Injectable()
export class CurrentUserContainer {

  public user: User;
  public extensions = {};

  constructor(private configService: ConfigService, private http: HttpClient) {
    this.user = this.configService.config.user;
    this.subscribe();
  }

  subscribe() {
    userChanged.subscribe((user) => {
      this.user = user;
      if (!user) {
        let currentUrl = window.location.href.split("#")[1];
        currentUrl = encodeURIComponent(currentUrl);
        let logoutUrl = this.configService.config["loginUrl"] + "?XO_PATH=" + currentUrl;
        window.open(logoutUrl, '_self', '');
      }
    })
  }

  hasPermission(moduleName: string, typeName: string, operation: string) {
    return hasPermission(getDefaultSystemName(moduleName), moduleName, typeName, operation);
  }

  isAdmin() {
    return this.configService.config.isAdmin();
  }

  isSystem() {
    return this.configService.config.isSystem()
  }

  async logout() {
    console.log("logout");
    let tokenHandler = this.configService.tokenHandler;
    let token = await tokenHandler.aquireLoginToken();
    this.http.delete(this.configService.config.authenticationUrl + "/session/client/" + token, { withCredentials: true }).toPromise()
    .then(tokenHandler.eraseLoginToken.bind(tokenHandler))
    .then(() => {
      userChanged.emit(null);
    }).catch(console.log);
  }
}
