import { Injectable } from '@angular/core';
import { UrlService } from '@xo/services';
import { ConfigService } from '../../../services/config.service';

@Injectable()
export class MessageUrlService extends UrlService {
    constructor(configService: ConfigService) {
        super('Message', 'Common', configService);
    }
}
