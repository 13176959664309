import { IModalDialog, IModalDialogOptions } from "@preeco-privacy/ngx-modal-dialog";
import { ComponentRef, Component, ViewContainerRef, ViewChild } from "@angular/core";
import { FlowDataService } from "./flowData.service";
import { Location } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { ComponentService } from '../services/component.service';
import { ConfigService } from '../services/config.service';
import { FlowPage } from './flow-page';
import { checkValidationMessages } from '../window/objectWindow';
import { ToastrService } from 'ngx-toastr';
import { ModalTabComponent } from '../components/tab/modal-tab.component';
import { ModalService } from '../window/modal-window/modal.service';

@Component({
    selector: "xo-flow-screen",
    templateUrl: "./flow-screen.component.html",
    styleUrls: ["./flow-screen.component.scss"]
})
export class FlowScreenComponent extends ModalTabComponent {

    flowDataService: FlowDataService;
    buttons: any[];
    state: any;

    @ViewChild('page', { read: ViewContainerRef, static: true }) pageContainer: ViewContainerRef;

    constructor(private http: HttpClient, private toastr: ToastrService, modalService: ModalService,
        componentService: ComponentService, configService: ConfigService, location: Location) {
        super(modalService, componentService, location, configService);
    }

    async dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
        super.dialogInit(reference, options)
        let url = options.data.data.url;
        if (!this.state) {
            this.state = {};
        }
        if (options.data.data.language) {
            this.state["language"] = options.data.data.language;
        }
        if (options.data.data.selectedGroup) {
            this.state["selectedGroup"] = options.data.data.selectedGroup;
        }
        this.flowDataService = new FlowDataService(this.http, url, this.configService);
        this.startAction("start", null);
    }

    currentComponent: FlowPage<any>;

    async startAction(action, next) {
        if (!!this.currentComponent) {
            let validationMessages = await this.currentComponent.validate(action);
            if (!checkValidationMessages(validationMessages, this.toastr)) {
                return;
            }
        }
        let response = await this.flowDataService.sendAction(action, next, this.getState(), this.currentComponent ? this.currentComponent.transformDataToServer() : null, this.currentComponent) as any;
        if (!response.component) {
            this.dialogOptions.closeDialogSubject.next();
            return;
        }
        this.pageContainer.remove();
        this.state = response.state || { language: this.dialogOptions.data.data.languages };
        let formData = response.formData || {};
        this.initButtons(response.buttons);

        this.currentComponent = await this.componentService.createComponent(this.pageContainer, response.component,
            { state: this.state, formData: formData, flowScreen: this }) as FlowPage<any>;
    }

    getState() {
        return this.state;
    }

    init(flowscreenData) {
    }

    initButtons(buttons) {
        this.buttons = buttons.map((button) => {
            let clickHandler = button.url
                ? () => this.startAction(button.action, button.next)
                : () => this.dialogOptions.closeDialogSubject.next();

            return {
                label: button.label,
                clickHandler
            };
        });
    }
}
