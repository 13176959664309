import { GroupType } from './../../../../../application-e2e/src/testVariables';
import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import { ObjectWindow, ConfigService, TabComponent } from "@xo/client-common";
import { Group } from "./Group";
import { CachingGroupService, GroupTransformator } from "./group.service";
import { GroupComponent } from "./group.component";
import { createGroupValidators } from './GroupValidation';
import { GroupUrlService } from './group.url.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: "xodos-group-page",
  templateUrl: "./group-page.component.html",
  styleUrls: ["./group-page.component.scss"]
})
export class GroupPage extends ObjectWindow<Group, CachingGroupService> {

  @ViewChild("component") component: GroupComponent;

  public Group;

  constructor(private tab: TabComponent, changeDetectorRef: ChangeDetectorRef, toastrService: ToastrService) {
    super(tab.getService(GroupType) as CachingGroupService, toastrService, createGroupValidators(), changeDetectorRef);
    this.Group = Group;
  }

  async init(rawGroup: any) {/*
    if (!rawGroup || !rawGroup.XO_SYSTEM) {
      throw new Error("Please provide XO_SYSTEM to GroupPage component in menu");
    }

    let groupTransformator = new GroupTransformator(rawGroup.XO_SYSTEM);
    let groupUrlService = new GroupUrlService(rawGroup.XO_SYSTEM, this.configService);
    this.dataService = new CachingGroupService(this.http, groupUrlService, groupTransformator); */
    super.init(rawGroup);
  }

  setupNewObject(rawGroup) {
    let group = new Group();
    if (rawGroup) {
      group.parent = rawGroup.parent;
    }
    return group;
  }

  async reload() {
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }
}
