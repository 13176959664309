import { Component, Input, OnInit } from "@angular/core";
import { TabService } from "../xo-tab/tabs.service";


@Component({
  selector: "xo-tiles",
  templateUrl: "./tiles.component.html",
  styleUrls: ["./tiles.component.scss"],
  providers: [TabService]
})
export class XoTilesComponent implements OnInit {
  @Input() defaultView: "tabs" | "tiles" = "tabs";
  @Input() canSwitch = true;

  constructor(private tabService: TabService) { }

  ngOnInit(): void {
    this.tabService.canSwitch = this.canSwitch;
    this.tabService.isTabView = this.defaultView === "tabs";
  }
}
