import { Component, Input, ElementRef, OnInit } from "@angular/core";

@Component({
    selector: "[xo-table-data-custom]",
    templateUrl: "./table-data-custom.html"
})
export class TableDataCustom implements OnInit {
    @Input() object;
    @Input() fieldName: string;
    @Input() format: Function;

    constructor(private elRef: ElementRef){}
    
    ngOnInit(){
        this.elRef.nativeElement.classList.add(this.fieldName);
    }
}