
export const SEVERITY_ERROR = "error";
export const SEVERITY_WARNING = "warning";
export const SEVERITY_INFO = "info";

export let messageSeverityMap = new Map<string, string>();
messageSeverityMap.set(SEVERITY_INFO, "Információ³");
messageSeverityMap.set(SEVERITY_WARNING, "Figyelmeztetés");
messageSeverityMap.set(SEVERITY_ERROR, "Hiba");

export let validationMessages = {
    en: {
        VALIDATION_ERROR_FOUND: "Validation has found errors.",
        VALIDATION_WARNING_FOUND: "Validation has found warnings, do you still like to save?"
    },
    hu: {
        VALIDATION_ERROR_FOUND: "A validáció hibákat talált",
        VALIDATION_WARNING_FOUND: "A validáció figyelmeztetéseket talált, így is akar menteni?"
    },
    EN: {},
    HU: {},
}
validationMessages.EN = validationMessages.en;
validationMessages.HU = validationMessages.hu;


export class ValidationMessage {
    constructor(public severity: string, public message: string, public fieldName?: string) { }
}
