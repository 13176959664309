import { Component, Input, ContentChild, TemplateRef, ElementRef, ViewChild } from "@angular/core";
import { ValidationMessage } from '../../validation/model/ValidationMessage';

@Component({
  selector: "xo-validated-array-field",
  templateUrl: "./validated-array-field.component.html",
  styleUrls: ["./validated-array-field.component.scss"]
})
export class ValidatedArrayFieldComponent {

  @Input() public fieldName: string;
  @Input() public array: any[];
  @Input() public validationMessages: ValidationMessage[];
  @Input() label?: string;
  @ContentChild(TemplateRef) template: TemplateRef<any>;
  @Input() parseInput?: any;
  @Input() displayAddButton = true;
  @Input() minLength?;
  @Input() maxLength?;

  @Input() validate?;
  @Input() object?;
  @Input() disabled?: boolean;


  @ViewChild("add") inputDiv: ElementRef;

  constructor(public elRef: ElementRef) { }

  public trackByFn(index: number, item: string): number {
    return index;
  }

  deleteArrayItem(index: number): void {
    if (index >= 0) {
      this.array.splice(index, 1);
      this.validate(this.object, this.fieldName);
    }
  }

  addToArray(): void {
    let newArrayFieldInput = this.inputDiv.nativeElement.children[0];
    if (this.parseInput) {
      this.array.push(this.parseInput(newArrayFieldInput));
    } else {
      this.array.push(newArrayFieldInput.value);
    }
    this.validate(this.object, this.fieldName);
    newArrayFieldInput.value = null;
  }

  canAddNewMember() {
    return !this.maxLength || this.array.length < this.maxLength;
  }

  canDeleteMember() {
    return !this.minLength || this.minLength < this.array.length;
  }

}
