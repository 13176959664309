import { Component } from "@angular/core";
import { XOArrayField } from './xo-array-field.component';

@Component({
    selector: "xo-field-array-fraction",
    templateUrl: "./xo-field-array-fraction.component.html"
})
export class XOArrayFieldFraction extends XOArrayField {
    convertFraction(convertable: any) {
        return parseFloat(convertable.value);
    }
}
