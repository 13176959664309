export const ONE_HOUR = 60 * 60 * 1000;
export const ONE_DAY = 24 * 60 * 60 * 1000;
export const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;

export function getAMonthFromNow() {
    var d = new Date();
    d.setMonth(d.getMonth() - 1);
    return d;
}

export function prettyPrint(value, postFix) {
    if (typeof value == 'number') {
        return formatMoney(value, postFix);
    }
    return value;
}

export function formatMoney(amount, postFix = "", decimalCount = 0, decimal = ",", thousands = " ") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '')
            + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands)
            + (decimalCount ? decimal + Math.abs(amount - parseInt(i)).toFixed(decimalCount).slice(2) : "")
            + postFix;
    } catch (e) {
        console.log(e)
    }
}

const onceMap: any = {};
export async function once(id: string, func: Function, ...args) {
    if (!!onceMap[id]) {
        console.log("skipping execution of", id);
        throw new Error("Duplicate run prevented: " + id);
    }

    onceMap[id] = id;
    let result = await func(...args);
    onceMap[id] = null;
    return result;
}


export function upperFirstChar(value) {
    return value.substring(0, 1).toUpperCase() + value.substring(1);
}

export function createValueListResolver(valueMap) {
    return (key) => [...valueMap.keys()].find(current => current.getTime() === key.getTime());
}
