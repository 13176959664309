import { Component, Input } from "@angular/core";

@Component({
    selector: "[xo-table-data]",
    templateUrl: "./table-data.html"
})
export class TableData {
    @Input() object;
    @Input() fieldName: string;
    @Input() type? = "string"
    @Input() labelField? = "label";
}