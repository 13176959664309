import { Component, Input } from "@angular/core";
import { DeckService } from "./deck.service";
import { DeckContainerService } from "./deck-container.service";
import { Deck } from "./deck";

@Component({
  selector: "xo-deck-control",
  templateUrl: "./deck-controlbar.component.html",
  styleUrls: ["./deck-controlbar.component.scss"]
})
export class DeckControlBar {

  @Input()
  public deckService: DeckService;

  @Input()
  public deckContainerService: DeckContainerService;

  @Input()
  public deck: Deck;

  temporaryLabel: string;

  saveLabel(event) {
    this.temporaryLabel = event.target.outerText;
  }

  save() {
    this.deckService.setLabel(this.temporaryLabel);
  }

  discard() {
    if (confirm("Do you want to discard the Deck?")) {
      this.deckContainerService.discard(this.deck)
    }
  }
}
